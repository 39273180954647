import { GiCharacter, GiChart, GiChecklist, GiRapidshareArrow } from "react-icons/gi";

function Analyze() {
  return (
    <div id="why-choose-us" className="container mx-auto2xl:py-10 xl:py-10 lg:py-10 md:py-10 px-12 py-8">
        <div className="flex flex-col justify-center items-center rounded-lg">      
          <div>
          <h3 className="text-textColor font-semibold text-center 2xl:text-4xl xl:text-3xl lg:text-2xl md:text-2xl text-2xl pb-6">
          Why Do Builders Love Us? 
          </h3>
          <p className="text-paragraphColor mb-5">
          Builders love us because we focus on targeted, exclusive and trusted new shoppers and match those shoppers with your new communities and homes. Our exclusive New Home Buyers focus, reach and matching with right new communities and homes makes a huge difference when it comes to reaching new home shoppers. 
          </p>
        </div>
        <div className="grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 grid-cols-1  gap-14">
          {/* item */}
          <div className="w-full flex flex-col">
          <div className="pb-8">
             <GiRapidshareArrow className="text-gray-700 w-8 h-8" />
            </div>
            <h4 className="text-textColor font-semibold pb-4">
            Reach New Home Buyers Quickly
            </h4>
            <p className="text-paragraphColor pb-4">
            Get quality new home shopper leads for your sales teams across locations and states. You can solve your current problems quickly by reaching new home shoppers who are shopping nearby locations and keen interest in your communities and plans. 
            </p>
          </div>
          {/* item */}
          <div className="w-full flex flex-col">
          <div className="pb-8">
             <GiChecklist className="text-gray-700 w-8 h-8" />
            </div>
            <h4 className="text-textColor font-semibold pb-4">
            Launch communities & Listings
            </h4>
            <p className="text-paragraphColor pb-4">
            List and Launch your new communities and homes for new home shoppers. We are a leading destination for new home shoppers, you can trust us to spotlight your communities in front of new home shoppers.
             </p>
          </div>
          {/* item */}
          <div className="w-full flex flex-col">
          <div className="pb-8">
             <GiChart className="text-gray-700 w-8 h-8" />
            </div>
            <h4 className="text-textColor font-semibold pb-4">
            Drive Traffic
            </h4>
            <p className="text-paragraphColor pb-4">
            Drive New Home Shoppers to your communities faster than ever. Contact us for more information on how we work with you to drive New Home Buyer tours and Agents.  
            List and Launch your new communities and homes for new home shoppers. We are a leading destination for new home shoppers, you can trust us to spotlight your communities in front of new home shoppers.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Analyze;
