import React from 'react';
import { Container, Box, Heading, Text, Button, Stack, Flex } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const HowItWorks = () => {
  return (
    <div className="page-content">
      <Flex align="center" justify="center" minHeight="100vh">
        <Box borderWidth="1px" borderRadius="lg" p={6} textAlign="center" maxW="container.md">
          <Heading as="h1" mb={6}>How It Works</Heading>
          <Stack spacing={8}>
            <Box>
              <Heading as="h2" size="md">1. Search</Heading>
              <Text mt={2}>Use our search feature to find properties that match your criteria.</Text>
            </Box>
            <Box>
              <Heading as="h2" size="md">2. Compare</Heading>
              <Text mt={2}>Compare different properties based on your preferences.</Text>
            </Box>
            <Box>
              <Heading as="h2" size="md">3. Register & Login</Heading>
              <Text mt={2}>Register and login to save your searches and preferences.</Text>
              <Button as={Link} to="/sign-up" mt={2}>Register</Button>
              <Button as={Link} to="/login" mt={2} ml={2}>Login</Button>
            </Box>
          </Stack>
        </Box>
      </Flex>
    </div>
  );
};

export default HowItWorks;
