

function LetsTalk() {
  return (
    <div className="container 2xl:py-10 xl:py-10 lg:py-10 md:py-10 py-8">
        <div className="bg-primary px-12 py-12 flex flex-col justify-center items-center rounded-lg ">
            <h3 className="2xl:text-4xl xl:text-3xl lg:text-2xl md:text-2xl text-2xl font-semibold text-textColor pb-5">Let's talk!</h3>
            <p className="text-paragraphColor pb-8 text-justify text-center w-full">
            KnockTheDoor can help you find your next new construction home and help you find your next remote rental home (passive income home) for better outcomes via our Groups.  You can simply send us a <a className="pr-2 underline text-[#000] font-normal" href="https://support.knockthedoor.com/" target="_blank">care request</a> for an email support or to take a chat appointment to go over all your options and make confident decisions. You might want to know  how our Group Model works for you to get better options and discounts. Do not hesitate to contact us for any questions you may have.  
            </p>
            <div>
            <a href="https://support.knockthedoor.com/" target="_blank" className="text-[#060605] hover:bg-btnbg bg-white transition-all duration-300 btnfontpage text-base px-6 py-3 rounded-lg border-borderColor border ">
            Contact Now
              </a>
            </div>

        </div>
    </div>
  )
}

export default LetsTalk