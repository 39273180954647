import { BiBath, BiGitCompare, BiSolidCarGarage } from "react-icons/bi";
import { CgMoreO } from "react-icons/cg";
import { FaRegMap } from "react-icons/fa";
import { IoIosImages } from "react-icons/io";
import { IoBookmarkOutline, IoWifiOutline } from "react-icons/io5";
import { LuBedSingle, LuPhone } from "react-icons/lu";
import { MdBalcony, MdElectricMeter, MdEmojiTransportation, MdOutlineAmpStories, MdOutlinePets, MdPersonAddAlt } from "react-icons/md";
import { PiIntersectSquareThin, PiTreeFill } from "react-icons/pi";
import { TbParkingCircle } from "react-icons/tb";
import React, { forwardRef } from "react";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { FaPersonSwimming } from "react-icons/fa6";
import { BsShieldShaded } from "react-icons/bs";
import { GiCctvCamera } from "react-icons/gi";
import { SiCcleaner } from "react-icons/si";

const CommonButton = forwardRef(({ icon, value, name, isLink, color, onClick, ...props }, ref) => {
  let colorText = "";

  if (color === "green") {
    colorText = "text-[#16A34A] bg-[#BBF7D0]";
  } else if (color === "blue") {
    colorText = "text-[#2563EB] bg-[#BFDBFE]";
  } else if (color === "purple") {
    colorText = "text-[#9333EA] bg-[#E9D5FF]";
  } else if (color === "pink") {
    colorText = "text-[#DB2777] bg-[#FBCFE8]";
  } else if (color === "gray") {
    colorText = "text-[#576269] bg-[#E6EBEF]";
  }else if (color === "orange") {
    colorText = "text-[#EA580C] bg-[#FED7AA]";
  }else if (color === "yellow") {
    colorText = "text-[#CA8A04] bg-[#FEF08A]";
  }else if (color === "red") {
    colorText = "text-[#DC2626] bg-[#FECACA]";
  }else if (color === "brown") {
    colorText = "text-[#774936] bg-[#edc4b3]";
  }else if (color === "tale") {
    colorText = "text-[#08415c] bg-[#7aaeb9]";
  }else if (color === "deepgreen") {
    colorText = "text-[#0e3e32] bg-[#7a9e95]";
  }else if (color === "darkgreen") {  
    colorText = "text-[#081c15] bg-[#d8f3dc]";
  }else if (color === "lightpink") {
    colorText = "text-[#D45155] bg-[#F4C0B4]";
  }else if (color === "deepred") {
    colorText = "text-[#581a27] bg-[#FFC1CB]";
  }else if (color === "lightgray") {
    colorText = "text-[#676e77] bg-[#c2d5ed]";
  }else if (color === "lightpurple") {
    colorText = "text-[#A944F5] bg-[#CDC1FF]";
  }else if (color === "lightyellow") {
    colorText = "text-[#E85C0D] bg-[#F8D76E]";
  }else if (color === "deepgray") {
    colorText = "text-[#373A40] bg-[#B3C8CF]";
  }

  return ( 
    <div ref={ref}>
      {isLink ? (
        <Link to={'/'}>
          <button
            className={`flex gap-2 ${colorText} px-2.5  text-sm items-center py-2 rounded-md`}
            onClick={onClick}
            {...props}
          >
            {icon === "bed" ? (
              <LuBedSingle size={18} />
            ) : icon === "bath" ? (
              <BiBath size={18} />
            ) : icon === "length" ? (
              <PiIntersectSquareThin size={18} />
            ) : icon === "property" ? (
              <FaRegMap size={18} />
            ) : icon === 'compare'? (
                <BiGitCompare size={18} />
            ) : icon === 'save' ? (
                <IoBookmarkOutline size={18}/>
            ) : icon === 'photo' ? (
                <IoIosImages size={18} />
            ) : icon === 'add'? (
                <MdPersonAddAlt size={18} />
            ) : icon === 'map' ? (
                <FaRegMap size={18} />
            ) : icon === 'contact' ? (
                <LuPhone size={18} />
            ) : icon === 'more' ? (
                <CgMoreO  size={18} />
            ) : icon === 'garage' ? (
              <BiSolidCarGarage size={18} />
            ) : icon === 'parking' ? (
              <TbParkingCircle size={18} />
            ) : icon === 'stories' ? (
              <MdOutlineAmpStories size={18} />
            ) :icon === 'garden' ? (
             <PiTreeFill size={18} />
            ) : icon === 'swimming' ? (
              <FaPersonSwimming size={18} />
            ) : icon === 'wifi' ? (
              <IoWifiOutline size={18} />
            ) : icon === 'pet-allowed' ? (
              <MdOutlinePets size={18} />
            ) : icon === 'terrace' ? (
              <MdBalcony size={18} />
            ) : icon === 'electric-backup' ? (
              <MdElectricMeter size={18} />
            ) : icon === 'security' ? (
              <BsShieldShaded size={18} />
            ) : icon === 'cctv' ? (
              <GiCctvCamera size={18} />
            ) : icon === 'transport' ? (
              <MdEmojiTransportation />
            ) : icon === 'cleanup' ? (
              <SiCcleaner />
            ) : ''
          }
            <span>{value}</span>
            <h4>{name}</h4>
          </button>
        </Link>
      ) : (
        <button
          className={`flex gap-2 ${colorText} px-2.5 text-sm iems-center py-1.5 rounded-md`}
          onClick={onClick}
          {...props}
        > {icon === "bed" ? (
          <LuBedSingle size={18} />
        ) : icon === "bath" ? (
          <BiBath size={18} />
        ) : icon === "length" ? (
          <PiIntersectSquareThin size={18} />
        ) : icon === "property" ? (
          <FaRegMap size={18} />
        ) : icon === 'compare'? (
            <BiGitCompare size={18} />
        ) : icon === 'save' ? (
            <IoBookmarkOutline size={18}/>
        ) : icon === 'photo' ? (
            <IoIosImages size={18} />
        ) : icon === 'add'? (
            <MdPersonAddAlt size={18} />
        ) : icon === 'map' ? (
            <FaRegMap size={18} />
        ) : icon === 'contact' ? (
            <LuPhone size={18} />
        ) : icon === 'more' ? (
            <CgMoreO  size={18} />
        ) : icon === 'garage' ? (
          <BiSolidCarGarage size={18} />
        ) : icon === 'parking' ? (
          <TbParkingCircle size={18} />
        ) : icon === 'stories' ? (
          <MdOutlineAmpStories size={18} />
        ) :icon === 'garden' ? (
         <PiTreeFill size={18} />
        ) : icon === 'swimming' ? (
          <FaPersonSwimming size={18} />
        ) : icon === 'wifi' ? (
          <IoWifiOutline size={18} />
        ) : icon === 'pet-allowed' ? (
          <MdOutlinePets size={18} />
        ) : icon === 'terrace' ? (
          <MdBalcony size={18} />
        ) : icon === 'electric-backup' ? (
          <MdElectricMeter size={18} />
        ) : icon === 'security' ? (
          <BsShieldShaded size={18} />
        ) : icon === 'cctv' ? (
          <GiCctvCamera size={18} />
        ) : icon === 'transport' ? (
          <MdEmojiTransportation />
        ) : icon === 'cleanup' ? (
          <SiCcleaner />
        ) : ''
      }
          <span>{value}</span>
          <h4>{name}</h4>
        </button>
      )}
    </div>
  );
});

CommonButton.propTypes = { icon: PropTypes.any, value: PropTypes.any, name: PropTypes.any, isLink: PropTypes.any ,color: PropTypes.any,  onClick: PropTypes.func }

export default CommonButton;


