import React from 'react';
import { InstantSearch, SearchBox, Hits, Highlight, createConnector, DynamicWidgets, RefinementList, ToggleRefinement, Panel, Pagination, Stats, connectSearchBox, NumericMenu, RangeInput, CurrentRefinements, QueryRuleCustomData, Snippet, SortBy, Configure, HierarchicalMenu } from 'react-instantsearch-dom';
import Client from '@searchkit/instantsearch-client';
import Searchkit from "searchkit";
import '../search/styles.css';
import ConnectedAutocomplete from './ConnectedAutocomplete';
const MySearchApp = () => {
  // Define the Elasticsearch host URL
 // const host = 'http://localhost:3001/api/elasticsearch';

  
const searchkitClient = new Searchkit({
    connection: {
      host: 'http://localhost:3001/api/elasticsearch',
    },
    search_settings: {
      highlight_attributes: ['data.propertyInfo.address.label'],
      search_attributes: ['data.propertyInfo.address.label'],
      result_attributes: ['data.mlsListingPrice', 'data.propertyInfo.address.label'],
      facet_attributes: [
        { field: 'price', attribute: 'data.mlsListingPrice', type: 'numeric' },
        { field: 'address', attribute: 'data.propertyInfo.address.label', type: 'string'},
      ],
      geo_attribute: 'location',
    },
    transformRequest: (request) => {
      // Transform the request to include the combined location field
      if (request.query && request.query.propertyInfo && request.query.propertyInfo.latitude && request.query.propertyInfo.longitude) {
        request.query.location = {
          lat: request.query.propertyInfo.latitude,
          lon: request.query.propertyInfo.longitude
        };
        delete request.query.propertyInfo.latitude;
        delete request.query.propertyInfo.longitude;
      }
      return request;
    }
  })
  
  
  const searchClient = Client(searchkitClient);


  const hitView = (props) => {
    console.log(props);
    return (
      <div>
        <h2><Highlight hit={props.hit} attribute="data.propertyInfo.address.label" /></h2>
        <h3>{props.hit.data.propertyInfo.address.label}</h3>
        <p>{props.hit.data.mlsListingPrice}</p>
        <br />
      </div>
    )
  }

  return (
    <InstantSearch
      indexName="properties-source-dev2"
      searchClient={searchClient}
    >
      <div>
        {/* Search box component */}
        <ConnectedAutocomplete />
        <SearchBox autofocus={true} searchOnChange={true} />
        
        {/* Facet filter components */}
        <RefinementList attribute="price" title="Price" />
        <RefinementList attribute="address" title="Address" />
        
        {/* Hits component to display search results */}
        <Hits hitComponent={hitView}/>
        <Pagination />
      </div>
    </InstantSearch>
  );
}

export default MySearchApp;
