import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";

import "./testimonialslider.css";

import { EffectCoverflow, Navigation } from "swiper/modules";
import { MdArrowForwardIos } from "react-icons/md";

function TestimonialSlider() {
  return (
    <div className=" testimonial-page">
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 80,
          modifier: 2.5,
        }}
        pagination={{ el: ".swiper-pagination", clickable: true }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          clickable: true,
        }}
        modules={[EffectCoverflow, Navigation]}
        className="swiper_container"
      >
        <SwiperSlide>
          <div className="px-8 py-8 bg-white flex content-between 2xl:h-[500px] xl:h-[450px] lg:h-[420px] h-[400px] flex-col justify-between border-borderColor border rounded-lg">
            <div className="flex flex-col">
              <div>
              <h4 className="text-textColor font-xl  font-semibold">Group model brings better benefits </h4>
              </div>
              <p className="text-paragraphColor pt-6">
              We present vetted properties with better price and incentives to like minded groups for best passive monthly income.  Based on each group’s interests and purchasing criteria, our vetting process focuses on high growth areas, better pricing, better passive income and easier property management with local property managers who have expertise in local markets.
             </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="px-8 py-8 bg-white flex content-between 2xl:h-[500px] xl:h-[450px] lg:h-[420px] h-[400px] flex-col justify-between border-borderColor border rounded-lg">
            <div className="flex flex-col">
              <div>
              <h4 className="text-textColor font-xl  font-semibold">Resolve to earn real Passive Income              </h4>
              </div>
              <p className="text-paragraphColor pt-6">
              Passive income via rental property can be a powerful tool for financial growth and stability. Passive income allows you to create additional revenue streams beyond your regular salary, offering more financial flexibility. Paving passive income creates a financial buffer.
             </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="px-8 py-8 bg-white flex content-between 2xl:h-[500px] xl:h-[450px] lg:h-[420px] h-[400px] flex-col justify-between border-borderColor border rounded-lg">
            <div className="flex flex-col">
            <div>
              <h4 className="text-textColor font-xl  font-semibold">Fixed Assets</h4>
              </div>
              <div className="text-paragraphColor pt-6 space-y-4">
              <p>Tangible: Fixed assets like real estate are physical, giving investors a sense of ownership and security.</p>
              <p>Control: You have direct control over how the asset is managed or maintained, which can increase its value.</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="px-8 py-8 bg-white flex content-between 2xl:h-[500px] xl:h-[450px] lg:h-[420px] h-[400px] flex-col justify-between border-borderColor border rounded-lg">
            <div className="flex flex-col">
              <div>
              <h4 className="text-textColor font-xl  font-semibold">Wealth Building</h4>
              </div>
              <p className="text-paragraphColor pt-6">
              Passive income sources, like real estate properties, appreciate in value, potentially generating even more income in the long run. You have an opportunity to scale and continuously acquire more properties and increase monthly passive income. 
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="px-8 py-8 bg-white flex content-between 2xl:h-[500px] xl:h-[450px] lg:h-[420px] h-[400px] flex-col justify-between border-borderColor border rounded-lg">
            <div className="flex flex-col">
              <div>
              <h4 className="text-textColor font-xl  font-semibold">Cash Flow Generation </h4>
              </div>
              <div className="text-paragraphColor pt-6 space-y-4">
              <p>Consistent Cash Flow: Real estate (rental property) or leasing out can generate steady and predictable monthly cash flow.</p>
              <p>Inflation Hedge: Real estate, in particular, often acts as a hedge against inflation, as property values and rental income tend to rise with inflation.</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="px-8 py-8 bg-white flex content-between 2xl:h-[500px] xl:h-[450px] lg:h-[420px] h-[400px] flex-col justify-between border-borderColor border rounded-lg">
            <div className="flex flex-col">
              <div>
              <h4 className="text-textColor font-xl  font-semibold">Diversification</h4>
              </div>
              <div className="text-paragraphColor pt-6 space-y-4">
              <p>Fixed assets offer an additional layer of diversification as they don’t move in tandem with other markets. Owning both can spread risk across different asset classes.</p>
              <p>Less Volatility: Fixed assets tend to have lower price volatility compared to assets, which can fluctuate rapidly.</p>
              <p>Portfolio Stability: Fixed assets like real estate add stability to a portfolio, balancing out other riskier assets.</p>
            </div>
            </div>
          </div>
        </SwiperSlide>

        <div className="slider-controler">
          <div className="swiper-button-prev  slider-arrow">
            <MdArrowForwardIos
              className="rotate-180"
              size={8}
              color="#222224"
            />
          </div>
          <div className="swiper-button-next slider-arrow">
            <MdArrowForwardIos size={8} color="#222224" />
          </div>
        </div>
      </Swiper>
    </div>
  );
}

export default TestimonialSlider;
