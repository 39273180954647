import React, { useState } from 'react';
import { Image, Box } from "@chakra-ui/react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const ImageScroll = ({ images, maxThumbnails = 5, onThumbnailClick }) => {
  const [selectedItem, setSelectedItem] = useState(0);
  const displayedThumbnails = images.slice(0, maxThumbnails);

  const handleThumbnailClick = (index) => {
    if (onThumbnailClick) {
      onThumbnailClick(index);
    }
    setSelectedItem(index);
  };

  return (
    <div className="custom-carousel-container">
      <Carousel
        showThumbs={true}
        infiniteLoop={true}
        selectedItem={selectedItem}
        showIndicators={false}
        showStatus={false}
        showArrows={true} // Disable arrows to have only one slide visible
        emulateTouch={true} // Allow touch interactions for swiping
        thumbWidth={150} // Adjust the thumbnail width as needed
        dynamicHeight={false} // Ensure consistent height
      >
        {images.map((img, index) => (
          <div key={index} style={{ width: '100%', borderRadius: '30px', overflow: 'hidden' }}>
            <img
              src={img.image}
              alt={`Image ${index + 1}`}
              className="carousel-image"
            />
          </div>
        ))}
      </Carousel>

    </div>
  );
};

export default ImageScroll;
