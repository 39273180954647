import Hero from "../../components/home/Builder/Hero";
import Analyze from "../../components/home/Builder/Analyze";
import ReadyToBuy from "../../components/home/Builder/ReadyToBuy";
import LetsTalk from "../../components/home/LetsTalk"
import Whatweoffer from "../../components/home/Builder/Whatweoffer";


function BuilderHomePage() {
  return (
    <div className="font-linksans">
        <Hero></Hero>
        <div className=" ">
          <ReadyToBuy></ReadyToBuy>
          <Whatweoffer></Whatweoffer>
          <Analyze></Analyze>
          <LetsTalk></LetsTalk>
        </div>
    </div>
  )
}

export default BuilderHomePage;
