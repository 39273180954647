import React, { useState, useEffect, useRef } from 'react';
import { Input, List, ListItem } from '@chakra-ui/react';

const AutocompleteCellEditor = (props) => {
  const [inputValue, setInputValue] = useState(props.value || '');
  const [filteredOptions, setFilteredOptions] = useState(props.values || []);
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    setFilteredOptions(props.values || []);
  }, [props.values]);

  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      props.stopEditing();
    } else if (event.key === 'Escape') {
      props.stopEditing(true);
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    const filtered = props.values.filter(option =>
      option.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredOptions(filtered);
  };

  const handleOptionClick = (option) => {
    setInputValue(option);
    props.stopEditing();
  };

  return (
    <div>
      <Input
        ref={inputRef}
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={onKeyDown}
        variant="filled"
        placeholder="Type to search..."
      />
      {filteredOptions.length > 0 && (
        <List style={{ maxHeight: '150px', overflowY: 'auto', marginTop: '4px' }}>
          {filteredOptions.map(option => (
            <ListItem
              key={option}
              onClick={() => handleOptionClick(option)}
              cursor="pointer"
              _hover={{ backgroundColor: 'gray.100' }}
              padding="2"
            >
              {option}
            </ListItem>
          ))}
        </List>
      )}
    </div>
  );
};

export default AutocompleteCellEditor;
