import React from 'react';

const Dashboard = () => {
  return (
    <div className="bg-primary font-linksans">
      <div className="container px-10 py-24">
        <div className="flex-col items-center flex justify-center">
          <h3 className="2xl:text-4xl xl:text-4xl lg:text-3xl md:text-2xl text-2xl font-bold text-textColor pb-10">
            Coming soon ..
          </h3>
          <div className="w-full flex justify-center">
            {/* Add any additional content here */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
