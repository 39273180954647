import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button } from '@chakra-ui/react';

const ModalComponent = ({ isOpen, onClose, modalHeader, modalBody, modalFooter }) => {
  return (
    <Modal closeOnOverlayClick={false} size='xl' isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        {/*<ModalHeader>{modalHeader}</ModalHeader>*/}
        <ModalBody  p={0}>
        <ModalCloseButton
        style={{
        position: 'absolute',
        right: '-15px',
        top: '-15px',
        background: '#ffffff',
        color: 'red',
        borderRadius: '50%', // Corrected property name to camelCase
        fontWeight: '900',
        opacity: '1',

        transition: 'box-shadow 0.3s ease', // Add transition for box-shadow
        }}
        _hover={{
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
        opacity: '1', // Shadow effect on hover
        }}
        />
          {modalBody}
        </ModalBody>

        {/* <ModalFooter>
          {modalFooter}
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter> */}
      </ModalContent>
    </Modal>
  );
}

export default ModalComponent;