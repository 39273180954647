const mapping = {
    highlight_attributes: [],
    search_attributes: [],
    result_attributes: [
        { field: 'main.publicId', name: 'id' },
        { field: 'data.mlsListingPrice', name: 'price' },
        { field: 'data.propertyInfo.address.label', name: 'address' },
        { field: 'data.mlsHistory.type', name: 'history_type' },
        { field: 'data.propertyInfo.bedrooms', name: 'bedrooms' },
        { field: 'data.propertyInfo.bathrooms', name: 'bathrooms' },
        { field: 'data.propertyInfo.buildingSquareFeet', name: 'sqft' },
        { field: 'data.propertyInfo.latitude', name: 'latitude' },
        { field: 'data.propertyInfo.longitude', name: 'longitude' },
        { field: 'data.lotInfo.lotSquareFeet', name: 'lot_area' },
        { field: 'data.propertyType', name: 'property_type' },
        { field: 'data.propertyInfo.yearBuilt', name: 'buit_year' },
        { field: 'data.mlsStatus', name: 'status' },
        { field: 'data.propertyInfo.address.county', name: 'country' },
        { field: 'data.propertyInfo.address.city', name: 'city' },
        { field: 'data.propertyInfo.address.state', name: 'state' },
        { field: 'data.lastSale.sellerNames', name: 'commumity' },
        { field: 'data.propertyInfo.buildername', name: 'builder' },
        { field: 'data.mlsActive', name: 'active' },
        { field: 'data.mlsCancelled', name: 'closed' },
        { field: 'data.mlsFailed', name: 'inactive' }
    ],
    facet_attributes: [
        { field: 'propertytype', attribute: 'propertytype', type: 'string'},
        { field: 'price', attribute: 'price', type: 'numeric' },
        { field: 'bedrooms', attribute: 'bedrooms', type: 'numeric', qualitative: true }, // Add bedrooms attribute
        { field: 'bathrooms', attribute: 'bathrooms', type: 'numeric' }, 
        { field: 'county.keyword', attribute: 'county', type: 'string'},
        { field: 'city.keyword', attribute: 'city', type: 'string'},
        { field: 'community_name', attribute: 'community_name', type: 'string'},
        { field: 'builder', attribute: 'builder', type: 'string'},
        { field: 'active', attribute: 'active', type: 'string'},
        { field: 'closed', attribute: 'closed', type: 'string'},
        { field: 'inactive', attribute: 'inactive', type: 'string'}
        
      ],
      sort_options: [
        { id: 'price_asc', label: 'Price (Low to High)', field: 'price', order: 'asc' },
        { id: 'price_desc', label: 'Price (High to Low)', field: 'price', order: 'desc' }
      ]
  };
  
  export default mapping;
  