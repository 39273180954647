import { useEffect, useState } from "react";
import heroImg2560x550 from "../../../assets/images/group-buyers-home-1a-2560x550.jpg";

function Hero() {

  return (
    <div>
       <div className="w-full xl:h-[480px] lg:h-[400px] 2xl:h-[550px] md:h-[450px] h-[400px] font-linksans">
        <div
          style={{
            backgroundImage: `url(${heroImg2560x550})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover', // Ensures the image covers the entire area
            backgroundPosition: 'center', // Centers the image
          }}
          className=" w-full h-full flex justify-center items-center"
        >
          <div className="w-[100%] 2xl:w-[50%] xl:w-[55%] lg:w-[60%] md:w-[80%] flex flex-col justify-center items-center">
            <div className="w-full flex justify-center items-center">
              <h1 className="font-semibold justify-centerx` text-center text-white 2xl:text-6xl xl:text-5xl lg:text-4xl md:text-4xl text-3xl  pb-10">
              Buy a best rental home with our group benefits
              </h1>
            </div>
            {/* search input */}
             {<div className="grid grid-cols-1 gap-6 pt-3 px-12">
              <a href="/groupbuyers/get-started/"
               className="py-4 px-4 rounded-lg text-sm text-[#000 ]  justify-center bg-transparent border border-blue-500 hover:bg-[rgb(40,116,191)] hover:border-blue-700 hover:text-white transition-colors duration-300"
               //className="w-full px-8 py-4 rounded-lg text-sm text-[#FAFBFC] flex items-center justify-center bg-gradient-to-b from-black/70 to-white/20 hover:bg-btnbg hover:text-textColor transition-colors duration-300"
               >
                <span >Join now and be on a waitlist</span>
              </a>
            </div>}
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
