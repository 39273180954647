

function DetailsSidebar() {
  return (
    <div className="p-6 bg-primary flex flex-col justify-center w-full rounded-xl ">
        <h3 className="text-xl font-bold  pb-6 text-textColor xl:text-center 2xl:text-left lg:text-center md:text-center">Select a preferred time slot</h3>

        {/* date selector */}
        <div className="flex 2xl:justify-between xl:justify-around lg:justify-around md:justify-around justify-around  w-full ">
            <div className=" w-fit bg-white rounded-xl flex ">
                <input className="hidden  peer" type="radio" name="date" id="date1" />
                <label className="cursor-pointer flex p-3 rounded-xl transition-transform border-white border peer-checked:border peer-checked:border-gray-800 flex-col gap-5 duration-200 items-center" htmlFor="date1">
                    <h4 className="text-sm font-medium text-paragraphColor">AUG </h4>
                    <h3 className="2xl:text-4xl xl:text-4xl lg:text-3xl md:Text-2xl text-2xl font-bold text-paragraphColor">27</h3>
                    <h4 className="text-paragraphColor text-sm">Tuesday</h4>
                </label>
            </div>
            <div className=" w-fit bg-white rounded-xl flex ">
                <input className="hidden  peer" type="radio" name="date" id="date2" />
                <label className="cursor-pointer flex p-3 transition-transform duration-200 rounded-xl border-white border peer-checked:border peer-checked:border-gray-800 flex-col gap-5  items-center" htmlFor="date2">
                    <h4 className="text-sm font-medium text-paragraphColor">AUG </h4>
                    <h3 className="2xl:text-4xl xl:text-4xl lg:text-3xl md:Text-2xl text-2xl font-bold text-paragraphColor">28</h3>
                    <h4 className="text-paragraphColor text-sm">Tuesday</h4>
                </label>
            </div>
            <div className=" w-fit bg-white rounded-xl flex ">
                <input className="hidden  peer" type="radio" name="date" id="date3" />
                <label className="cursor-pointer flex transition-transform duration-200 p-3 rounded-xl border-white border peer-checked:border peer-checked:border-gray-800 flex-col gap-5  items-center" htmlFor="date3">
                    <h4 className="text-sm font-medium text-paragraphColor">AUG </h4>
                    <h3 className="2xl:text-4xl xl:text-4xl lg:text-3xl md:Text-2xl text-2xl font-bold text-paragraphColor">29</h3>
                    <h4 className="text-paragraphColor text-sm">Tuesday</h4>
                </label>
            </div>
            
        </div>

        {/* time selector */}
        <div className="pt-6 w-full flex 2xl:justify-between xl:justify-around lg:justify-around md:justify-around justify-around">
            <div className="w-fit bg-white rounded-xl flex">
                <input className="hidden peer" type="radio" name="time" id="time1" />
                <label className="cursor-pointer px-2.5 w-full peer-checked:border peer-checked:border-gray-800  border py-3 rounded-lg text-sm text-paragraphColor" htmlFor="time1">
                    <h4>09:00 AM</h4>
                </label>
            </div>
            <div className="w-fit bg-white rounded-xl flex">
                <input className="hidden peer" type="radio" name="time" id="time2" />
                <label className="cursor-pointer px-2.5 w-full peer-checked:border peer-checked:border-gray-800  border py-3 rounded-lg text-sm text-paragraphColor" htmlFor="time2">
                    <h4>10:00 AM</h4>
                </label>
            </div>
            <div className="w-fit bg-white rounded-xl flex">
                <input className="hidden peer" type="radio" name="time" id="time3" />
                <label className="cursor-pointer px-2.5 w-full peer-checked:border peer-checked:border-gray-800  border py-3 rounded-lg text-sm text-paragraphColor" htmlFor="time3">
                    <h4>11:00 PM</h4>
                </label>
            </div>
        </div>
        <div className="py-6 border-b border-dashed border-gray-300 w-full flex 2xl:justify-between xl:justify-around lg:justify-around md:justify-around justify-around">
            <div className="w-fit bg-white rounded-xl flex">
                <input className="hidden peer" type="radio" name="time" id="time4" />
                <label className="cursor-pointer px-2.5 w-full peer-checked:border peer-checked:border-gray-800  border py-3 rounded-lg text-sm text-paragraphColor" htmlFor="time4">
                    <h4>12:00 AM</h4>
                </label>
            </div>
            <div className="w-fit bg-white rounded-xl flex">
                <input className="hidden peer" type="radio" name="time" id="time5" />
                <label className="cursor-pointer px-2.5 w-full peer-checked:border peer-checked:border-gray-800  border py-3 rounded-lg text-sm text-paragraphColor" htmlFor="time5">
                    <h4>05:00 PM</h4>
                </label>
            </div>
            <div className="w-fit bg-white rounded-xl flex">
                <input className="hidden peer" type="radio" name="time" id="time6" />
                <label className="cursor-pointer px-2.5 w-full peer-checked:border peer-checked:border-gray-800  border py-3 rounded-lg text-sm text-paragraphColor" htmlFor="time6">
                    <h4>09:00 PM</h4>
                </label>
            </div>
        </div>

        {/* title */}
        <h3 className="text-xl py-6 font-bold  text-textColor">More about this property</h3>

        <form action="" className="space-y-4">
            <div className="flex flex-col gap-2">
                <label  className="text-textColor font-medium text-sm" htmlFor="name">Name</label>
                <input className="px-3 py-3 bg-white rounded-lg text-gray-500 text-sm" placeholder="Name" type="text" name="name" id="name" />
            </div>
            <div className="flex flex-col gap-2">
                <label  className="text-textColor font-medium text-sm" htmlFor="email">Email</label>
                <input className="px-3 py-3 bg-white rounded-lg text-gray-500 text-sm" placeholder="Email" type="email" name="email" id="email" />
            </div>
            <div className="flex flex-col gap-2">
                <label  className="text-textColor font-medium text-sm" htmlFor="number">Phone</label>
                <input className="px-3 py-3 bg-white rounded-lg text-gray-500 text-sm" placeholder="Phone" type="number" name="number" id="number" />
            </div>
            <div className="flex flex-col gap-2">
                <label  className="text-textColor font-medium text-sm" htmlFor="zip">ZIP</label>
                <input className="px-3 py-3 bg-white rounded-lg text-gray-500 text-sm" placeholder="Zip" type="text" name="zip" id="zip" />
            </div>
            <div className="flex flex-col gap-2">
                <label className="text-textColor font-medium text-sm" htmlFor="description">Description</label>
                <textarea className="px-3 h-40 resize-none py-3 bg-white rounded-lg text-gray-500 text-sm" placeholder="Enter a description..." name="description" id="description"></textarea>
            </div>

            <div className="">
                <button className="text-base bg-[#14884C] w-full text-center py-4 rounded-lg font-semibold text-white">Submit </button>
            </div>
        </form>

    </div>
  )
}

export default DetailsSidebar