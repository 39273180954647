import React from 'react';
import { useLocation } from 'react-router-dom';
import Autocomplete from './Autocomplete';

const AutocompleteContainer = ({ onAutocompleteData }) => {
  const location = useLocation();
  const { pathname } = location;

  // Function to determine whether to show the Autocomplete component
  const shouldShowAutocomplete = () => {
    // Define the routes where you want to show the Autocomplete component
    const allowedRoutes = ['/shortlisted/at-address/', '/search/']; // Add more routes as needed

    // Check if the current pathname is in the allowed routes
    return allowedRoutes.includes(pathname);
  };

  return (
    // Render the Autocomplete component only if shouldShowAutocomplete is true
    <>
      {shouldShowAutocomplete() && <Autocomplete onAutocompleteData={onAutocompleteData} />}
    </>
  );
};

export default AutocompleteContainer;
