import React from "react";
import { Box, Divider, VStack, Text,Heading, Container } from "@chakra-ui/react";
import SearchHeader from "./SearchHeader";
import Breadcrumb from "../Breadcrumb";
import '../search/styles.css';
const SearchComponent = () => {
  return (
    <div className="page-content" >
       <Box >
         <Container maxW='100%'>
              <Box p={2}>
                     <Breadcrumb/>
                     <Divider/>
                     <SearchHeader/>
              </Box>
         </Container>
       </Box>
    </div>
   
  );
};

export default SearchComponent;
