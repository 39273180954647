function Benfits() {
    const benefitsList = [
      "Freedom from expensive local rental homes.",
      "Benefit from plenty of remote rental opportunities instead of struggling with limited local options. Do not miss other growing cities/markets.",
      "It’s better with groups instead of going with independent local rental homes.",
      "Leverage group benefits which are harder to come when you buy independently.",
      "Generate steady monthly rental income with remote rental homes.",
      "Expand your holdings by acquiring additional rental properties in different cities, helping you hedge.",
      "Appreciation from the potential change in property value realized at the end of the hold period.",
      "Fixed assets are physical assets, giving ownership and security.",
      "Own the title instead of depending on others.",
      "Sell when you want it as you own the title."
    ];
  
    return (
      <div className="container mx-auto py-10">
        <h3 className="text-2xl md:text-3xl xl:text-4xl font-semibold text-center text-gray-800 pb-10">
          Benefits
        </h3>
        <div className="w-full font-sans flex justify-center">
          <ul className="flex flex-col space-y-4 text-gray-700 text-left">
            {benefitsList.map((benefit, index) => (
              <li key={index} className="flex items-start space-x-3 max-w-2xl">
                <span className="text-black w-6 h-6 flex justify-center rounded-full px-2">
                  ✓
                </span>
                <span className="text-lg">{benefit}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
  
  export default Benfits;
  