

function Whoweare() {
    return (
      <div className="container 2xl:py-10 xl:py-10 lg:py-10 md:py-10 py-8">
          <div className="px-12 py-12 flex flex-col justify-center items-center rounded-lg ">
            <h6 className="2xl:text-2xl xl:text-xl lg:text-xl md:text-xl text-lg text-textColor pb-5">Best team for best results</h6>
             <h3 className="2xl:text-4xl xl:text-3xl lg:text-2xl md:text-2xl text-2xl font-semibold text-textColor pb-5">Who We Are! </h3>
              <p className="text-paragraphColor pb-2 text-justify text-center w-1/2">
              We're reinventing rental home buying with our unique groups model and new home buying experiences from the inside out.
              Led by an experienced entrepreneur and our team, we are creating unique benefits and values which are hard to come with other companies.
              </p>
              <div>
              </div>
  
          </div>
      </div>
    )
  }
  
  export default Whoweare