import React from 'react';
import { Button } from '@chakra-ui/react';

const CommunityUploadButtonCellRenderer  = ({ data, context }) => {
  const handleUploadClick = () => {
    context.openUploadModal(data.id);  
  };
  return (
    <Button onClick={handleUploadClick} size="sm">
      Photos
    </Button>
  );
};

export default CommunityUploadButtonCellRenderer;
