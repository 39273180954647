import React, { useState, useEffect } from 'react';
import { Grid, GridItem, Input, Button, Flex, FormControl, FormLabel, FormErrorMessage, useToast, Badge } from '@chakra-ui/react'; // Import Chakra UI components
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next'; 

const BuilderInfo = () => {
  const userId = localStorage.getItem('userid');
  const [builderInfo, setBuilderInfo] = useState({
    builderName: '',
    builderHQEmail: '',
    builderHQPhone: '',
    builderAddress: '',
    companyWebsite: ''
  });

  const [errors, setErrors] = useState({});
  const location = useLocation();
  const toast = useToast();
  const { t} = useTranslation();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(()=> {
    getBuilderInfo();
  },[]);
  useEffect(() => {
    if (location.pathname.indexOf("builder_info") > 0) {
      getBuilderInfo();
    }
  }, [location.pathname]);

  const validateFields = () => {
    const newErrors = {};
    if (!builderInfo.builderName) {
      newErrors.builderName = "Builder Name is required.";
    }
    if (!builderInfo.builderHQEmail || !validateEmail(builderInfo.builderHQEmail)) {
      newErrors.builderHQEmail = "Valid HQ Email is required.";
    }
    if (builderInfo.builderHQPhone && !validatePhone(builderInfo.builderHQPhone)) {
      newErrors.builderHQPhone = "Invalid phone format. Please use +[country code][number].";
    }
  
    if (!builderInfo.builderAddress || builderInfo.builderAddress.trim().length < 10) {
      newErrors.builderAddress = "Builder Address must be at least 10 characters long.";
  }

    if (!builderInfo.companyWebsite || !validateWebsite(builderInfo.companyWebsite)) {
      newErrors.companyWebsite = "Invalid company website URL.";
    }
    return newErrors;
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^\+\d{1,3}\d{1,14}$/; // Simple phone validation regex
    return phoneRegex.test(phone);
  };

  const validateWebsite = (website) => {
    const regex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
    return regex.test(website);
  };

  const getBuilderInfo = () => {
    console.log("get builderinfo")
    axios.get(`${process.env.REACT_APP_DYNAMO_API_URL}/users/${userId}/builder-info/`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(response => {
        setBuilderInfo({ ...response.data });
      })
      .catch(error => {
        console.error('Error in loading getBuilderInfo:', error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateFields();
    setSuccessMessage('');
    setErrorMessage('');
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors); // Set the validation errors
      return;
    }

    axios.put(`${process.env.REACT_APP_DYNAMO_API_URL}/users/${userId}/builder-info/`, builderInfo, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(response => {
        setSuccessMessage(t('saved'));
        toast({
          title: t('builderinfo-updatemessage'),
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      })
      .catch(error => {
        console.error('Error updating profile:', error);
        setErrorMessage(t('failed'));
        toast({
          title: 'Error',
          description: t('builderinfo-failedmessage'),
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'bottom-left',
        });
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    // Update the builderInfo state
    setBuilderInfo({ ...builderInfo, [name]: value });
  
    // Clear specific field errors when the input is valid
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
  
      // Validate each field when input changes and remove the error if it's valid
      if (name === 'builderName' && value) {
        delete newErrors.builderName;
      } else if (name === 'builderHQEmail' && validateEmail(value)) {
        delete newErrors.builderHQEmail;
      } else if (name === 'builderHQPhone' && validatePhone(value)) {
        delete newErrors.builderHQPhone;
      } else if (name === 'builderAddress' && value.trim().length >= 10) {
        delete newErrors.builderAddress;
      } else if (name === 'companyWebsite' && validateWebsite(value)) {
        delete newErrors.companyWebsite;
      }
  
      return newErrors;
    });
  };
  

  useEffect(() => {
    if (successMessage || errorMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage('');
        setErrorMessage('');
      }, 3000);
      return () => clearTimeout(timer); // Cleanup the timeout
    }
  }, [successMessage, errorMessage]);

  return (
    <div className="bg-primary  font-linksans min-h-svh">
            <div className="container px-5 py-12">
            <div className="flex-col items-center flex justify-center">
    <GridItem className="top-sec" w='100%' style={{ padding: '0 0 40px 0' }}>
      <Grid templateColumns='repeat(2, 1fr)' gap={10}>
        <GridItem w='100%'>
          <form onSubmit={handleSubmit}>
            <FormControl isInvalid={errors.builderName} mb={4}>
              <FormLabel>Builder Name <span style={{ color: 'red' }}>*</span></FormLabel>
              <Input bg="white" color="gray.500" fontSize="sm" px={3}  py={3} borderRadius="lg" name="builderName" placeholder="Enter Builder Name" value={builderInfo.builderName} onChange={handleInputChange} />
              <FormErrorMessage>{errors.builderName}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.builderHQEmail} mb={4}>
              <FormLabel>Builder HQ Email <span style={{ color: 'red' }}>*</span></FormLabel>
              <Input bg="white" color="gray.500" fontSize="sm" px={3}  py={3} borderRadius="lg" name="builderHQEmail" placeholder="Enter Builder HQ Email" type="email" value={builderInfo.builderHQEmail} onChange={handleInputChange} />
              <FormErrorMessage>{errors.builderHQEmail}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.builderHQPhone} mb={4}>
              <FormLabel>Builder HQ Phone</FormLabel>
              <Input bg="white" color="gray.500" fontSize="sm" px={3}  py={3} borderRadius="lg" name="builderHQPhone" type="tel" placeholder="Enter Builder HQ Phone" value={builderInfo.builderHQPhone} onChange={handleInputChange} />
              <FormErrorMessage>{errors.builderHQPhone}</FormErrorMessage>
            </FormControl>
          </form>
        </GridItem>
        <GridItem w='100%'>
          <FormControl isInvalid={errors.builderAddress} mb={4}>
            <FormLabel>Builder Address <span style={{ color: 'red' }}>*</span></FormLabel>
            <Input bg="white" color="gray.500" fontSize="sm" px={3}  py={3} borderRadius="lg" name="builderAddress" placeholder="Enter Builder Address" value={builderInfo.builderAddress} onChange={handleInputChange} />
            <FormErrorMessage>{errors.builderAddress}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.companyWebsite} mb={4}>
            <FormLabel>Company Website <span style={{ color: 'red' }}>*</span></FormLabel>
            <Input bg="white" color="gray.500" fontSize="sm" px={3}  py={3} borderRadius="lg" name="companyWebsite" placeholder="Enter Website Url" value={builderInfo.companyWebsite} onChange={handleInputChange} />
            <FormErrorMessage>{errors.companyWebsite}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem w="100%" className="flex justify-center pt-6">
          <button
            type="submit"
            className="w-full max-w-xs text-sm 2xl:text-base lg:text-base xl:text-base md:text-sm 2xl:py-4 xl:py-4 lg:py-3.5 md:py-3 py-3 rounded-lg font-semibold text-paragraphColor bg-white border"
            onClick={handleSubmit}
          >
            Update Builder Info
          </button>
        </GridItem>

        {successMessage && (
          <Badge colorScheme="green" display="flex" ml={3} alignItems="center" fontSize="md" textTransform="none">
          <CheckIcon boxSize="12px" color="green.500" mr={1} /> 
          {successMessage.charAt(0).toUpperCase() + successMessage.slice(1).toLowerCase()}
          </Badge>
        )}
        {errorMessage && (

        <Badge colorScheme="red" display="flex" alignItems="center" fontSize="md" textTransform="none">
        <CloseIcon boxSize="12px" color="red.500" mr={1} /> 
        {errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1).toLowerCase()}
        </Badge>

        )}
      </Grid>
    </GridItem>
    </div>
    </div>
    </div>
  );
};

export default BuilderInfo;
