import TestimonialInfo from "./RentalHomeBuyerInfo"
import TestimonialSlider from "./RentalHomeBuyers/TestimonialSlider"


function RentalHomeBuyerMain() {
  return (
    <div>
        <div className="grid container mx-auto items-center font-linksans md:gap-7 2xl:gap-0 xl:gap-0 lg:gap-0 gap-5 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 grid-cols-1 2xl:grid-cols-2  2xl:py-10 xl:py-10 lg:py-10 md:py-10 py-8">
           <TestimonialInfo></TestimonialInfo>
           <TestimonialSlider></TestimonialSlider>
        </div>
    </div>
  )
}

export default RentalHomeBuyerMain