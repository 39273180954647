import React from "react";
import { Box } from "@chakra-ui/react";
import GeomapSearchComponent from "./GeomapSearchComponent";
import SearchHeader from "./SearchHeader";
import './styles.css';
const SearchComponent = () => {
  return (
    <div className="page-content" >
   <Box bg="#f0f3f5" p={2}>
   <SearchHeader/>
        {/* <SearchHeader/>
        <GeomapSearchComponent /> */}
   </Box>
  </div>
   
  );
};

export default SearchComponent;
