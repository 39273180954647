import React, { useState, useMemo, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { Box, Button, useToast } from '@chakra-ui/react';
import Swal from 'sweetalert2';
import axiosInstance, {axiosInstanceES} from '../../utils/axio-instance';
import AgentActionCellRenderer from './AgentActionCellRenderer';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; 


const AssignAgent = () => {
  const [rowData, setRowData] = useState([]);
  const [editRowId, setEditRowId] = useState(null);
  // const [propertyOptions, setPropertyOptions] = useState([]);
  // const [agentOptions, setAgentOptions] = useState([]);
  const localtion = useLocation();
  const toast = useToast();
  const { t} = useTranslation();

    const fetchInitialData = async () => {
      try {
        const response = await axiosInstance.get(`/staff/assignments/staff`);
        console.log("assignagentdata", response);
        if (response.data.result && Array.isArray(response.data.data)) {
          setRowData(response.data.data.map(item => ({
            id: item.internalStaffId,
            buyerEmailId: item.internalStaffId,
            agentEmailId: item.agentId,
            propertyId: item.propertyId,
          })));
        } else {
          setRowData([]);
        }
      } catch (error) {
        toast({
          title: 'Error',
          description: t('failedinitialdata'),
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'bottom-left',
        });
      }
    };

    

    useEffect(() => {
       if(localtion.pathname.indexOf("assign-agent")>0){
       fetchInitialData();
      // fetchPropertyOptions();
      // fetchUserOptions();
       }
    }, [localtion.pathname]);

  const isUserEmailExists = async (email)=> {
    try{
      const resp = await axiosInstance.get(`/users/getUserByEmail?email=${email}`);
      if(resp.data){
        return resp.data;
      }
      return null;
    }catch(e){
      console.log(e);
      return null;
    }
  }

  const validateRow = (data) => {
    console.log("data1",data);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const alphanumericRegex = /^[a-zA-Z0-9]+$/;
  
    // Check if buyerEmailId and agentEmailId are valid emails
    const isBuyerEmailValid = emailRegex.test(data.buyerEmailId);
    const isAgentEmailValid = emailRegex.test(data.agentEmailId);
  
    // Check if propertyId is alphanumeric
    const propertyIds = data.propertyId.split(',').map(id => id.trim());
    const allPropertyIdsValid = propertyIds.every(id => alphanumericRegex.test(id));
    
    // Check if each propertyId exists in propertyOptions
     const allPropertiesExist = true;
     // propertyIds.every(id => 
    //   propertyOptions.some(property => property.id.toString() === id)
    // );
  
    // Collect error messages
    const errors = [];
  
    if (!isBuyerEmailValid) {
      errors.push("Buyer Email is not valid. Please enter a valid email address.");
    } 
  
    if (!isAgentEmailValid) {
      errors.push("Agent Email is not valid. Please enter a valid email address.");
    }
  
    if (!allPropertyIdsValid) {
      errors.push("Property IDs are not valid. Please enter alphanumeric values.");
    } else if (!allPropertiesExist) {
      errors.push("One or more Property IDs do not exist. Please enter valid property IDs.");
    }
  
    // If there are errors, return the errors; otherwise, return true
    if (errors.length > 0) {
      Swal.fire('Validation Error', errors.join("\n"), 'error');
      return false;
    }
    return true;
  };  
  
  

  const onCellValueChanged = (event) => {
    // Handle cell value change if needed
  };

  const onEditClick = (id) => {
    setEditRowId(id);
  };

  const onCancelClick = () => {
    const updatedRows = rowData.filter(row => !row.isNew);
    setRowData(updatedRows);
    setEditRowId(null);
  };

  const onUpdateClick = async (row) => {
    console.log("rowdata231",row);
    if (validateRow(row)) {
      try {
        // Find the old data for comparison
      const oldRow = rowData.find(data => data.id === row.id);
       
          // Check if buyerEmailId exists in userOptions
      const buyerData = await isUserEmailExists(row.buyerEmailId);
      if(buyerData==null || !buyerData.userId){
        toast({
          title: 'Error',
          description: t('Buyer Email does not exist'),
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'bottom-left',
        });
        return;
      }
      // Check if agentEmailId exists in agentOptions
      const agentData = await isUserEmailExists(row.agentEmailId);
      if(agentData==null || !agentData.userId){
        
        toast({
          title: 'Error',
          description: t('Agent Email does not exist'),
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'bottom-left',
        });
        return;
      }
        const buyerId = buyerData.userId;//userOptions.find(option => option.email.includes(row.buyerEmailId))?.userId;
        const agentId = agentData.userId;// userOptions.find(option => option.email.includes(row.agentEmailId))?.userId;
        
        // Prepare the request payload with old and new data
        const requestData = {
          internalStaffId: oldRow.buyerEmailId,
          agentId: oldRow.agentEmailId,
          propertyId: oldRow.propertyId,
          newInternalStaffId: buyerId,
          newAgentId: agentId,
          newPropertyId: row.propertyId
        };
  
        const response = await axiosInstance.post(`/staff/update/agent`, requestData);
  
        if (response.status === 200) {
          const updatedRowData = rowData.map(data => data.id === row.id ? row : data);
          setRowData(updatedRowData);
          toast({
            title: t('assignment-successmessage'),
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });
          setEditRowId(null);
        } else {
            toast({
              title: 'Error',
              description: t('assignment-failuremessage'),
              status: 'error',
              duration: 5000,
              isClosable: true,
              position: 'bottom-left',
            });
        }
      } catch (error) {
        //console.error('Error updating row:', error);
        toast({
          title: 'Error',
          description: t(error.response.data.error),
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'bottom-left',
        });
      }
    } else {
      Swal.fire('Validation Error', 'Please fill in all required fields.', 'error');
    }
  };

  const saveNewRow = async (row) => {
    if (validateRow(row)) {
      const buyerData = await isUserEmailExists(row.buyerEmailId);
      if(buyerData==null || !buyerData.userId){
        toast({
          title: 'Error',
          description: t('Buyer Email does not exist1'),
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'bottom-left',
        });
        return;
      }
      // Check if agentEmailId exists in agentOptions
      const agentData = await isUserEmailExists(row.agentEmailId);
      if(agentData==null || !agentData.userId){
        console.log(agentData);
        toast({
          title: 'Error',
          description: t('Agent Email does not exist2'),
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'bottom-left',
        });
        return;
      }
      const buyerId = buyerData.userId;//userOptions.find(option => option.email.includes(row.buyerEmailId))?.userId;
      const agentId = agentData.userId;// userOptions.find(option => option.email.includes(row.agentEmailId))?.userId;

      if (!buyerId || !agentId) {
        console.log("buyerId,agentId", agentId, buyerId)
        toast({
          title: 'Error',
          description: t('assignment-validatemessage'),
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'bottom-left',
        });
        return;
      }
  
      // Split propertyId into an array of IDs
      const propertyIds = row.propertyId.split(',').map(id => id.trim());
  
      // Prepare to collect all responses
      const responses = [];
      const errors = [];
  
      try {
        // Iterate over each propertyId and make an API request
        for (const propertyId of propertyIds) {
          try {
            const response = await axiosInstance.post(`/staff/assign-agent`,
              {
                internalStaffId: buyerId,
                agentId: agentId,
                propertyId: propertyId,
              });
  
            if (response.status === 200) {
              responses.push(response.data);
            } else {
              errors.push(`Failed to add property ID ${propertyId}`);
            }
          } catch (error) {
            console.error(`Error adding property ID ${propertyId}:`, error);
            errors.push(`Error adding property ID ${propertyId}`);
          }
        }
  
        // Check if there were any errors
        if (errors.length === 0) {
          const updatedRowData = rowData.map(data => data.id === row.id ? row : data);
          setRowData(updatedRowData);
          toast({
            title: t('assignment-successmessage'),
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });
        } else {
          toast({
            title: 'Error',
            description: t('assignment-failuremessage'),
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'bottom-left',
          });
        }
        setEditRowId(null);
      } catch (error) {
        console.error('Error processing rows:', error);
        toast({
          title: 'Error',
          description: `Failed to add new assignments: ${error.join(', ')}`,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'bottom-left',
        });
      }
    }
  };
  
 
  const addNewRow = () => {
    const newId = parseInt(Math.random()*10000);
    const newRowData = {
      id:  String(newId),
      buyerEmailId: '',
      agentEmailId: '',
      propertyId: '',
      isNew: true,
    };

    setRowData([...rowData, newRowData]);
    setEditRowId(newId);
  };

  const onDeleteClick = async (id) => {
    const row = rowData.find(row => row.id === id);
    if (row) {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      });
  
      if (result.isConfirmed) {
        try {
          const response = await axiosInstance.delete(`/staff/delete/agent`, {
            data: {
              internalStaffId: row.buyerEmailId,
              agentId: row.agentEmailId,
              propertyId: row.propertyId
            }
          });
  
          if (response.status === 200) {
            const updatedRowData = rowData.filter(row => row.id !== id);
            setRowData(updatedRowData);
            toast({
              title: t('assignment-deletemessage'),
              status: 'success',
              duration: 3000,
              isClosable: true,
              position: 'bottom-left',
            });
          } else {
            toast({
              title: 'Error',
              description:  t('assignment-failuremessage'),
              status: 'error',
              duration: 5000,
              isClosable: true,
              position: 'bottom-left',
            });
          }
        } catch (error) {
          console.error('Error deleting row:', error);
          toast({
            title: 'Error',
            description:  t('assignment-failuremessage'),
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'bottom-left',
          });
        }
      }
    }
  };
  
  

  const colDefs = useMemo(() => [
    {
      headerName: 'Actions',
      field: 'actions',
      cellRenderer: AgentActionCellRenderer,
      editable: false,
      pinned: 'left',
      resizable: false,
      suppressMovable: true,
    },
    {
      headerName: 'Buyer/Seller Email',
      field: 'buyerEmailId',
      editable: true
    },
    {
      headerName: 'Agent Email',
      field: 'agentEmailId',
      editable: true
    },
    {
      headerName: 'Property',
      field: 'propertyId',
      editable: true,
      // Remove the cellEditor and cellEditorParams to use the default text input
      // valueFormatter: (params) => {
      //   const option = propertyOptions.find(option => option.id.toString() === params.value);
      //   return option ? option.address : params.value;
      // }
    },
  ], [editRowId]);

  const context = {
    editRowId,
    onEditClick,
    onCancelClick,
    onUpdateClick,
    onDeleteClick,
    saveNewRow,
  };

  const defaultColDef = useMemo(() => ({
    flex: 1,
    minWidth: 150,
    editable: true,
    sortable: true,
    filter: true,
    resizable: true,
  }), []);

  return (
    <div className="bg-primary  font-linksans">
            <div className="container px-5 py-12">
    <Box>
      <Button onClick={addNewRow} colorScheme="blue" mb={4}>
        Add New Assignment
      </Button>
      <div className="ag-theme-alpine" style={{ height: '500px', width: '100%' }}>
        <AgGridReact
          rowData={rowData}
          columnDefs={colDefs}
          defaultColDef={defaultColDef}
          context={context}
          animateRows={true}
          pagination={true}
          onCellValueChanged={onCellValueChanged}
          frameworkComponents={{ AgentActionCellRenderer }}
        />
      </div>
    </Box>
    </div>
    </div>
  );
};

export default AssignAgent;
