import { GiIncomingRocket, GiOnTarget, GiRapidshareArrow, GiShakingHands, GiSkills } from "react-icons/gi";
import { AiOutlineWechatWork } from "react-icons/ai";
function Analyze() {
  return (
    <div id="why-choose-us" className="container mx-auto2xl:py-10 xl:py-10 lg:py-10 md:py-10 py-8 px-12">
      <div className="flex flex-col justify-center items-center rounded-lg">
        <div>
        <h3 className="text-textColor text-center font-semibold 2xl:text-4xl xl:text-3xl lg:text-2xl md:text-2xl text-2xl pb-6">
           Why Do Agents Love Us? 
          </h3>
          <p className="text-paragraphColor">
          Agents love us because we focus on targeted, exclusive and trusted new home shoppers and match those shoppers as referrals with the qualified agents. That niche focussed and targeted referrals helps each agent to spend less time on nonsense and focus on keen buyers without wasting both side’s time, in turn get best outcomes for the available time in hand. 
          </p>
        </div>
        <div className="grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 grid-cols-1  mt-5 gap-8">
          {/* item */}
          <div className="w-full flex flex-col">
          <div className="pb-8">
             <GiIncomingRocket className="text-gray-700 w-8 h-8" />
            </div>
            <h4 className="text-textColor font-semibold pb-4">
            Grow your business with our referrals
            </h4>
            <p className="text-paragraphColor pb-4">
            Knock The Door helps you what you do best: closing deals. We match right buyers as referrals so that you can focus on closing deals rather than wasting your time on wrong areas.  Expand Your Real Estate Business with our Referrals.
            </p>
          </div>
          {/* item */}
          <div className="w-full flex flex-col">
          <div className="pb-8">
             <GiSkills className="text-gray-700 w-8 h-8" />
            </div>
            <h4 className="text-textColor font-semibold pb-4">
            Quality experiences
            </h4>
            <p className="text-paragraphColor pb-4">
             You can showcase your skills and experience when you connect to the right buyers with the right criteria in hand. Currently, we allow and work with qualified agents with the minimum level of experience. Our high quality referrals/leads help you to find the legitimate buyers.
            </p>
          </div>
          {/* item */}
          <div className="w-full flex flex-col">
          <div className="pb-8">
             <GiShakingHands className="text-gray-700 w-8 h-8" />
            </div>
            <h4 className="text-textColor font-semibold pb-4">
            Ensuring agents success
            </h4>
            <p className="text-paragraphColor pb-4">
            We know that our success depends on your success. Whether you want to train your team and get the right results, our team can help you achieve your targets and success. Our support team is here to help you.
            </p>
          </div>
          {/* item */}
          <div className="w-full flex flex-col">
          <div className="pb-8">
             <GiRapidshareArrow className="text-gray-700 w-8 h-8" />
            </div>
            <h4 className="text-textColor font-semibold pb-4">
            Reach New Home Buyers Quickly
            </h4>
            <p className="text-paragraphColor pb-4">
            Get quality new home shopper leads for you or your teams across locations and states. You can solve your current problems quickly by reaching new home shoppers who are shopping nearby locations and keen interest in your communities and plans. 
            </p>
          </div>
          {/* item */}
          <div className="w-full flex flex-col">
          <div className="pb-8">
             <GiOnTarget className="text-gray-700 w-8 h-8" />
            </div>
            <h4 className="text-textColor font-semibold pb-4">
            New Construction Homes focus at first
            </h4>
            <p className="text-paragraphColor pb-4">
            Our Niche market ‘New Construction Homes’ focus makes a huge difference on getting best referrals and results. Buyers love this focus and that focus and niche criteria provides all of us quality experiences and transactions at each side of the aisle for best outcomes to all parties involved. 
            </p>
          </div>
          {/* item */}
          <div className="w-full flex flex-col">
          <div className="pb-8">
             <AiOutlineWechatWork className="text-gray-700 w-8 h-8" />
            </div>
            <h4 className="text-textColor font-semibold pb-4">
            How Knock The Door Differs From Other Referral Services
            </h4>
            <p className="text-paragraphColor pb-4">
            There are NO upfront costs or monthly fees as we work via referral agreements and success of each transaction. You will only receive referrals who want to be matched with an agent with the right criteria. We pride ourselves by providing awesome technology and matching agents with the right buyers. 
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Analyze;
