import React ,{useState,useEffect, useRef}from 'react';
import { Box, Grid, Text, VStack, Heading, Divider,Button, MenuItem,
    MenuButton,
    HStack,
    Menu,
    MenuList} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useLocation,useNavigate } from 'react-router-dom'; 
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next'; 
import axiosInstance, {axiosInstanceES} from '../../utils/axio-instance';



const CompareText = () => {

    const [userGroups, setUserGroups]= useState([]);
    const [selectedGroup, setSelectedGroup] = useState('Groups');
    const [userId, setUserId] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const {t} = useTranslation();


      useEffect(() => {
        const storedUserId = localStorage.getItem('userid');
        
        if (storedUserId) {
          setUserId(storedUserId);
    
          // Loading user groups
          const loadingGroups = async () => {
            try {
                const response = await axiosInstance.get(`/usrgroup`);
              if (!response.ok) {
                throw new Error('Failed to fetch groups');
              }
              const responseData = response.data;
              //setGroups(responseData.data || []);
              setUserGroups(responseData || []);
            } catch (error) {
              //setError(error.message);
            }
          };
    
          loadingGroups();
        }
      }, []); 


    useEffect(() => {
        if (!userId) return;
        const params = new URLSearchParams(location.search);
        const ids = params.get('ids');
        
        if (ids) {
          const matchedGroup = userGroups.find(group => group.id === parseInt(ids, 10));
          if (matchedGroup) {
            setSelectedGroup(matchedGroup.name);
          } else {
            setSelectedGroup('Groups'); 
          }
        } else {
          setSelectedGroup('Groups'); 
        }
      }, [location.search, userGroups, userId]);
    
      const groupFilter = (id)=>{
           const path = `/shortlisted?ids=${id}`;
          navigate(path);
      }

    //   if (!userId) {
    //     return null; 
    //   }
    

    return (
            <Box py={2} className='min-h-svh'>
            <HStack>
            <Text fontSize="lg" color={'blue.500'} fontWeight="bold">{t('compare-header-message')}</Text>
            {userId && (
             <Menu>
                    <MenuButton mt={2} size='sm' as={Button} rightIcon={<ChevronDownIcon />}
                    colorScheme={'blue'}
                    hover={{ bg: 'gray.400' }}
                    >
                    {selectedGroup}
                    </MenuButton>
                    <MenuList>
                        {(userGroups||[]).map(group=>(
                        <MenuItem key={group.id} onClick={() => groupFilter(group.id)}>{group.name}</MenuItem>
                        ))}
                    </MenuList>
            </Menu>
              )}
            </HStack>
            <VStack spacing={2} textAlign="center" mt={2}>
                    <Text fontSize="lg" color={'gray.500'}>
                     { t('compare-descrpt-message') }
                    </Text>
                    <Link to="/search"> {/* Link to the search page */}
                    <Button colorScheme="blue" width="full" bg="gray.500" color="black">
                    { t('compare-button-text') }
                    </Button>
                   </Link>
                </VStack> 
            </Box> 
    );
}

export default CompareText;
