import React from 'react';
import { Select } from '@chakra-ui/react';

const StatusCellEditor = ({ value, api, node, column }) => {
  const handleChange = (event) => {
    const newValue = event.target.value;
    api.stopEditing();
    node.setDataValue(column.colId, newValue);
  };

  return (
    <Select value={value} onChange={handleChange}>
      <option value="active">Active</option>
      <option value="inactive">Inactive</option>
    </Select>
  );
};

export default StatusCellEditor;
