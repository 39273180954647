import React, { useContext } from 'react';
import { Button } from '@chakra-ui/react';

const ActionCellRenderer = (props) => {
  //console.log("props12121", props);
  const { editRowId, newRow, onEditClick, onCancelClick, onUpdateClick, onDeleteClick, saveNewRow } = props.context;
  const isEditing = editRowId === props.node.data.id || newRow === props.node.data.id;
  //console.log("isEditing",props);

  const handleSave = () => {
    if (newRow === props.node.data.id) {
      saveNewRow(props.node.data);
    } else {
      onUpdateClick(props.node.data);
    }
  };

  return (
    <div>
      {isEditing ? (
        <>
          <Button onClick={handleSave} size="sm" mr={2}>Save</Button>
          <Button onClick={onCancelClick} size="sm">Cancel</Button>
        </>
      ) : (
        <>
          <Button onClick={() => onEditClick(props.node.data.id)}  size="sm" mr={2}>Edit</Button>
          <Button onClick={() => onDeleteClick(props.node.data.id)}  size="sm">Delete</Button>
        </>
      )}
    </div>
  );
};

export default ActionCellRenderer;
