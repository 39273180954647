import React, { useState, useEffect, useMemo, useCallback, useRef} from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { Container, Heading, Button, useDisclosure, useToast } from '@chakra-ui/react';
import axios from 'axios';
import UserActionCellRenderer from './UserActionCellRenderer';
import StatusCellEditor from './StatusCellEditor';
import Swal from 'sweetalert2';
import { ModuleRegistry } from "@ag-grid-community/core";
import { ServerSideRowModelModule } from "@ag-grid-enterprise/server-side-row-model";
import { useParams,useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; 
import { roles } from "../../constants"; 
import axiosInstance, {axiosInstanceES} from '../../utils/axio-instance';


ModuleRegistry.registerModules([
  ServerSideRowModelModule,
]);

const getServerSideDatasource = (server) => {
    return {
        getRows: (params) => {

          if (!server) {
            params.api.hideOverlay();
            console.warn('Server URL is null or undefined. Returning empty rows.');
            params.success({
              rowData: [],
              rowCount: 0,
            });
            return;
          }

            const { startRow, endRow,filterModel } = params.request;
            const pageSize = endRow - startRow;
            const filterParams = new URLSearchParams();
            Object.keys(filterModel).forEach(key => {
              filterParams.append(`filter[${key}]`, filterModel[key].filter);
            });
            const queryString = `${server}?start=${startRow}&size=${pageSize}&${filterParams.toString()}`;
            
            axiosInstance.get(queryString)
                .then(response => {
                    const data = response.data;
                    if (data && data.data && data.total !== undefined) {
                        params.success({
                            rowData: data.data,
                            rowCount: data.total,
                        });
                    } else {
                        throw new Error('Data format is incorrect');
                    }
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                    params.failCallback();
                });
        }
    };
};

const UserManagement = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [rowData, setRowData] = useState([]);
  const [editRowId, setEditRowId] = useState(null);
  const [newRow, setNewRow] = useState(null);
  const [newRowId, setNewRowId] = useState(null);
  const [propertyId, setPropertyId] = useState(null);
  const [newRows, setNewRows] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [gridOption, setGridOption] = useState(null); 
  const gridApiRef = useRef(null);
  const gridRef1 = useRef(null);
  const [dataToBeDeleted,setDataToBeDeleted] = useState(null);
  const storedUserInfo = localStorage.getItem('userid');
  let location = useLocation();
  const { t} = useTranslation();
  const toast = useToast();
  const segments = location.pathname.split('/').filter(segment => segment);
  const lastSegment = segments[segments.length - 2];

  const handleCellValueChanged = (event) => {
    const updatedRowData = rowData.map(row => {
      if (row.id === event.id) {
        return { ...row, [event.colDef.field]: event.newValue };
      }
      return row;
    });
    setRowData(updatedRowData);
  };

  const onEditClick = (id) => {
    setEditRowId(id);
  };

  const openUploadModal = (id) => {
    setPropertyId(id);
    onOpen();
  };

  const onCancelClick = () => {
   
  //  const newRowId = getNewRow(); // Retrieve the stored new row id
    if (gridRef1.current && gridRef1.current.api) {
    
    if (newRowId) {
        const transaction = {
            remove: [{ id: newRowId }]
        };

        gridRef1.current.api.applyServerSideTransaction(transaction);

        // Clear the stored new row id
        setNewRow(null);
        setNewRowId(null);
    }
  }
    setEditRowId(null);
  //  setNewRow(null);
  };


  const validateNewRow = (newRowId) => {
    const rowNode = gridRef1.current.api.getRowNode(newRowId);
    if (rowNode) {
      const rowData = rowNode.data;
    const requiredFields = ['firstName','lastName','email', 'role', 'state', 'zip'];
    const errors = [];
  
    requiredFields.forEach(field => {
      if (!rowData[field]) {
        errors.push(field);
      }
    });
  
    if (errors.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: `Please fill in the following required fields: ${errors.join(', ')}`
      });
      return false;
    }
    }
    return true;

  };
  
 
  const createRow = async() => {

    const newId = rowData.length > 0 ? Math.max(...rowData.map(row => Number(row.user_id))) + 1 : 1;
    const newUserId = String(newId);

    const emptyRow = {
      user_id: newUserId,
      email: '',
      first_name: '',
      last_name: '',
      roles: [],
      password: 'Next@123',
      legal_acceptance: true,
      profile_status:'inactive'
    };

    setNewRow(emptyRow.user_id);
    setNewRowId(emptyRow.user_id);
    setNewRows(prevNewRows => [...prevNewRows, emptyRow.user_id]);
    return emptyRow;

  };

  const addNewRow =  useCallback(async () => {
    const newRow = await createRow();
     if (gridRef1.current && gridRef1.current.api) {
      const transaction = {
        addIndex: 0,
        add: [newRow],
      };
      const result = gridRef1.current.api.applyServerSideTransaction(transaction);
    } else {
      console.error("Grid reference is not available or not properly assigned.");
    }
   }, [createRow,newRow]);

   


  const onGridReadyUser = useCallback(async (params) => {
    try {
        const apiUrl = `${process.env.REACT_APP_DYNAMO_API_URL}/users/list`;
        const datasource = getServerSideDatasource(apiUrl);
        params.api.setGridOption('serverSideDatasource', datasource);
    } catch (error) {
      console.error('Error setting up the grid data source:', error);
    }
  }, []);


  const onDeleteClick = async (user_id) => {
    const { isConfirmed } = await Swal.fire({
      title: 'Are you sure you want to delete?',
      text: 'You will not be able to recover this user!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    });
  
    if (isConfirmed) {
      try {
        // Check if the row is a new row (not yet saved in the database)
        const isNewRow = newRow === user_id;
  
        if (isNewRow) {
          setRowData(rowData.filter(row => row.user_id !== user_id));
          setNewRow(null);
          toast({
            title: t('user-removemessage'),
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });
        } else {
          const response = await axiosInstance.delete(`/users/${user_id}`, {
            
          });
  
          if (response.status === 200) {
            setRowData((prevRowData) => prevRowData.filter(row => row.user_id !== user_id));
            //gridOptions.api.refreshInfiniteCache();
            toast({
              title:  t('user-deletemessage'),
              status: 'success',
              duration: 3000,
              isClosable: true,
              position: 'bottom-left',
            });
          } else {
            throw new Error('Failed to delete user');
          }
        }
      } catch (error) {
        console.error('Error deleting user:', error);
        toast({
          title: 'Error',
          description: t('user-failed'),
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'bottom-left',
        })
      }
    }
  };
  

  const onUpdateClick = async (updatedUser) => {
    try {
      const { user_id, email, password, user_id_kc,role, legal_acceptance,  ...userWithoutIdEmailAndKC  } = updatedUser;

      const rolesArray = Array.isArray(userWithoutIdEmailAndKC.roles) ? userWithoutIdEmailAndKC.roles : [userWithoutIdEmailAndKC.roles];
    const response = await axiosInstance.put(`/users/${updatedUser.user_id}/updaterole`, {
       ...userWithoutIdEmailAndKC,
      roles: rolesArray,
   });

      if (response.status === 200) {
        setEditRowId(null);
        toast({
          title: t('user-updatedmessage'),
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      } else {
        throw new Error('Failed to update user');
      }
    } catch (error) {
      console.error('Error updating user:', error);
      toast({
        title: 'Error',
        description:  `There was an error adding the user: ${error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom-left',
      });
    }
  };

  const colDefs = useMemo(() => [
    {
      headerName: 'Actions',
      field: 'actions',
      cellRenderer: UserActionCellRenderer,
      editable: false,
      pinned: 'left',
      resizable: true,
      suppressMovable: true,
      sortable: false,
      filter: false,
      suppressHeaderContextMenu: true
    },
    { headerName: 'ID', field: 'user_id', editable: false, sortable: true, filter: true, sort: 'desc', hide: true },
    { headerName: 'First Name', field: 'first_name', editable: editRowId !== null || newRow !== null, sortable: true, filter: true },
    { headerName: 'Last Name', field: 'last_name', editable: editRowId !== null || newRow !== null, sortable: true, filter: true },
    { headerName: 'Email', field: 'email', editable: (params) => newRow === params.data.user_id, sortable: true, filter: true },
    {
      headerName: 'Role',
      field: 'roles',
      editable: editRowId !== null || newRow !== null,
      sortable: false,
      filter: false,
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: roles.map(role => role.value), // Use only values in the dropdown
        formatValue: value => roles.find(r => r.value === value)?.label || '', // Display the label
      },
      cellRenderer: ({ value }) => {
        if (Array.isArray(value)) {
          // Handle multiple values if necessary
          return value.map(val => roles.find(r => r.value === val)?.label).join(', ');
        }
        return roles.find(r => r.value === value)?.label || '';
      },
      valueFormatter: ({ value }) => {
        if (Array.isArray(value)) {
          return value.map(val => roles.find(r => r.value === val)?.label).join(', ');
        }
        return roles.find(r => r.value === value)?.label || '';
      },
    },
    // { headerName: 'Status', field: 'profile_status', editable: true, sortable: true, filter: true, cellEditor: StatusCellEditor, cellEditorParams: { values: ['active', 'inactive'] } },
    {
      headerName: 'Status',
      field: 'profile_status',
      editable: true,
      sortable: true,
      filter: true,
      cellEditor: StatusCellEditor,
      cellEditorParams: {
        values: ['active', 'inactive'], // Values used by the cell editor
      },
      valueFormatter: (params) => {
        return params.value === 'active' ? 'Active' : 'Inactive';
      },
      valueParser: (params) => {
        return params.newValue === 'Active' ? 'active' : 'inactive';
      },
    }
    
  ], [editRowId, newRow, roles]);

  const validateNewUser = (newUser) => {

    if (!newUser.first_name || newUser.first_name.trim() === '') {
      Swal.fire("Error!", "First Name is required", "error");
      throw new Error('First Name is required');
    }
  
    if (!newUser.last_name || newUser.last_name.trim() === '') {
      Swal.fire("Error!", "Last Name is required", "error");
      throw new Error('Last Name is required');
    }
  
    if (!newUser.email || newUser.email.trim() === '') {
      Swal.fire("Error!", "Email is required", "error");
      throw new Error('Email is required');
    }
    
    const emailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailFormat.test(newUser.email.trim())) {
      Swal.fire("Error!", "Please enter a valid email address", "error");
      throw new Error('Invalid email format');
    }

  // if (!Array.isArray(newUser.roles) || newUser.roles.length === 0) {
  //   Swal.fire("Error!", "Please select at least one role", "error");
  //   throw new Error('Please select at least one role');
  // }
};

const saveNewRow = async (newUser) => {
  try {
    validateNewUser(newUser);
    
    
    const userRoles = Array.isArray(newUser.roles) ? newUser.roles : [newUser.roles];

    const userData = {
      ...newUser,
      roles: userRoles,
    };
    delete userData.user_id;
    delete userData.profile_status;

    const response = await axios.post(`${process.env.REACT_APP_DYNAMO_API_URL}/users/create-user`, userData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (response.status === 200) {
      setNewRow(null);
      setRowData([userData, ...rowData]); 
      toast({
        title: t('user-addedmessage'),
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'bottom-left',
      });
    } else {
      throw new Error('Failed to save new user');
    }
  } catch (error) {
    toast({
      title: 'Error',
      description:  `There was an error adding the user: ${error.message}`,
      status: 'error',
      duration: 5000,
      isClosable: true,
      position: 'bottom-left',
    });
  }
};


  const defaultColDef = useMemo(() => ({
    flex: 1,
    minWidth: 150,
    editable: true,
    sortable: true,
    filter: true,
    resizable: true,
  }), []);

  const context = {
    editRowId,
    newRow,
    onEditClick,
    onCancelClick,
    onDeleteClick,
    onUpdateClick,
    saveNewRow,
  };

  const gridOptions = useMemo(() => ({
    domLayout: 'autoHeight',
    enableBrowserTooltips: true,
    menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab', 'csvExport'],
  }), []);

  const sideBar = useMemo(() => ({
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
        }
      },
      // {
      //   id: 'filters',
      //   labelDefault: 'Filters',
      //   labelKey: 'filters',
      //   iconKey: 'filter',
      //   toolPanel: 'agFiltersToolPanel',
      // }
    ],
  }), []);

  //const getRowId = useCallback((params) => `${params.data.user_id}`, []);
  const getRowId = useCallback((params) => {
    return params.data.user_id ? `${params.data.user_id}` : `${params.data.user_id_kc}`;
  }, []);

  return (
    <>
       <div className="bg-primary  font-linksans">
       <div className="container px-5 py-12">
        <Button onClick={addNewRow} colorScheme="grey" mb={4}>
          Add New User
        </Button>
        <div className="ag-theme-alpine" style={{ height: 'auto' }}>
          <AgGridReact
            ref={gridRef1}
            rowData={rowData}
            columnDefs={colDefs}
            defaultColDef={defaultColDef}
            animateRows={true}
            domLayout="autoHeight"
            enableSorting={true}
            enableFilter={true}
            enableCellEditing={true}
            pagination={true}
            rowModelType={"serverSide"}
            paginationPageSize={20}
            onGridReady={onGridReadyUser}
            serverSideDatasource={gridOption}
            frameworkComponents={{
              UserActionCellRenderer,
              StatusCellEditor,
            }}
            context={context}
            onCellValueChanged={handleCellValueChanged}
            gridOptions={{ sideBar }} 
            getRowId={getRowId}
          />
        </div>
      </div>
      </div>
    </>
  );
};

export default UserManagement;
