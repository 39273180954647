import React, { useState  } from 'react';
import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import axiosInstance, {axiosInstanceES} from '../../utils/axio-instance';


const UserActionCellRenderer = (props) => {
  const { editRowId, newRow, onEditClick, onCancelClick, onUpdateClick, onDeleteClick, saveNewRow } = props.context;
  const isEditing = editRowId === props.node.data.user_id || newRow === props.node.data.user_id;


  const { isOpen, onOpen, onClose } = useDisclosure();
  const [userProfile, setUserProfile] = useState(null);
  const [userPreferences, setUserPreferences] = useState(null);

  const handleSave = () => {
    if (newRow === props.node.data.user_id) {
      saveNewRow(props.node.data);
    } else {
      onUpdateClick(props.node.data);
    }
  };


  const handleInfoClick = async () => {
    try {
      onOpen();
      const userId = props.node.data.user_id?props.node.data.user_id:props.node.data.user_id_kc;
      const response = await axiosInstance.get(`/users/${userId}/profile/`);
      setUserProfile(response.data);

      const preferencesResponse = await axiosInstance.get(`/users/${userId}/preferences/`);
      setUserPreferences(preferencesResponse.data);

     
    } catch (error) {
      console.error('Error fetching user profile:', error);
      //TODO: add toaster
      onClose();
    }
  };

  return (
    <div>
      {isEditing ? (
        <>
          <Button onClick={handleSave} colorScheme="blue" size="sm" mr={2}>Save</Button>
          <Button onClick={onCancelClick} colorScheme="red" size="sm">Cancel</Button>
        </>
      ) : (
        <>
          <Button onClick={() => onEditClick(props.node.data.user_id)} colorScheme="blue" size="sm" mr={2}>Edit</Button>
          <Button onClick={() => onDeleteClick(props.node.data.user_id)} colorScheme="red" size="sm" mr={2}>Delete</Button>
          <Button onClick={handleInfoClick} colorScheme="green" size="sm">Info</Button>
        </>
      )}

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Profile Information</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {userProfile ? (
              <Table variant="simple" mb={2}>
                <Thead>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>Full Name</Td>
                    <Td>{(userProfile.first_name || userProfile.last_name) ? `${userProfile.first_name || ''} ${userProfile.last_name || ''}` : null}</Td>
                  </Tr>
                  <Tr>
                    <Td>Email</Td>
                    <Td>{userProfile.email}</Td>
                  </Tr>
                  <Tr>
                    <Td>Phone Number</Td>
                    <Td>{userProfile.mobile}</Td>
                  </Tr>
                  <Tr>
                    <Td>Alternate Number</Td>
                    <Td>{userProfile.phone_2}</Td>
                  </Tr>
                  <Tr>
                    <Td>Address</Td>
                    <Td>{userProfile.primary_address}</Td>
                  </Tr>
                </Tbody>
              </Table>
            ) : (
              <p>Loading...</p> // Fallback if profile data isn't available yet
            )}

         {userPreferences && (
              <>
                <h3>User Preferences</h3>
                <Table variant="simple" mt={2}>
                  <Tbody>
                    <Tr>
                      <Td>Preferred Location</Td>
                      <Td>{userPreferences.preferredLocation}</Td>
                    </Tr>
                    <Tr>
                      <Td>Preferred Property Types</Td>
                      <Td>{userPreferences.preferredPropertyTypes.join(', ')}</Td>
                    </Tr>
                    <Tr>
                      <Td>Preferred Price Range</Td>
                      <Td>{userPreferences.preferredPriceRange.join(' - ')}</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </>
            )}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </div>
  );
};

export default UserActionCellRenderer;
