import React from 'react';
import ComparisonTable from '../compare/ComparisonTable';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Box, Container, Heading } from "@chakra-ui/react";
import { useTranslation } from 'react-i18next'; 


const ProductsCompTable = () => {
    const { itemIds } = useParams();
    const selectedids = itemIds.split('/'); 
    const location = useLocation();
    const { t} = useTranslation();
    const { state } = location;
    if (!state || !state.data || !state.filters) {
        // Handle the case where state is not available or does not have the expected structure
        return (
          <div>
            <h2>Invalid state</h2>
          </div>
        );
      }
    
      const { data, filters } = state;
    
    return (
        <div className="page-content" >
        <Box >
          <Container maxW='100%'>
          <Heading as="h1" size="xl">
          {t('compare-header-message')}
            </Heading>
         <ComparisonTable data={data} filters={filters} />
         </Container>
         </Box>
      </div>
    );
  };
  
  export default ProductsCompTable;