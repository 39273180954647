import React from 'react';
import {
  Box,
  Heading,
  Text,
  Stack,
  Divider,
  Button,
  List,
  ListItem,
  ListIcon,
  Container
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';

const StillWhy = () => {
  return (
    <div className="bg-primary  font-linksans">
      <div className="container px-10 py-12">
        <div className="flex-col items-center flex justify-center">
      <Heading as="h1" size="lg" mb={6} textAlign="center">
        Why Choose Grouped Properties?
      </Heading>

      {/* Introduction */}
      <Text fontSize="lg" mb={6} textAlign="center">
        Discover the benefits of buying or renting properties within a specific group of listings. 
        Enjoy exclusive deals, detailed insights, and a hassle-free process tailored to meet your real estate needs.
      </Text>

      <Divider mb={6} />

      {/* Key Benefits Section */}
      <Stack spacing={4}>
        <List spacing={3}>
          {/* Benefit 1 */}
          <ListItem>
            <ListIcon as={CheckCircleIcon} color="green.500" />
            <strong>Exclusive Listings:</strong> Access to a curated selection of properties that are not available anywhere else.
          </ListItem>
          {/* Benefit 2 */}
          <ListItem>
            <ListIcon as={CheckCircleIcon} color="green.500" />
            <strong>Group-Specific Pricing:</strong> Enjoy special rates and discounts exclusive to members of the group.
          </ListItem>
          {/* Benefit 3 */}
          <ListItem>
            <ListIcon as={CheckCircleIcon} color="green.500" />
            <strong>Tailored Services:</strong> Receive personalized support and services specific to your needs, whether you're buying, selling, or renting.
          </ListItem>
          {/* Benefit 4 */}
          <ListItem>
            <ListIcon as={CheckCircleIcon} color="green.500" />
            <strong>Verified Properties:</strong> Every property within our group listings is thoroughly vetted to ensure you get the best value.
          </ListItem>
          {/* Benefit 5 */}
          <ListItem>
            <ListIcon as={CheckCircleIcon} color="green.500" />
            <strong>Simplified Transactions:</strong> Enjoy a streamlined, transparent, and stress-free real estate transaction experience.
          </ListItem>
        </List>
      </Stack>

      <Divider my={6} />

      {/* Call to Action */}
      <Button colorScheme="blue" size="lg" width="100%" mb={6}>
        Explore Available Groups List
      </Button>

      {/* Footer Text */}
      <Text fontSize="sm" color="gray.500" textAlign="center">
        Join the growing community of satisfied clients who have found their dream homes through our exclusive property groups.
      </Text>
    </div>
    </div>
    </div>
  );
};

export default StillWhy;
