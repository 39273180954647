import React from 'react';
import {Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useDisclosure, Text, Stack, IconButton, Box, Tabs, TabList, TabPanels, Tab, TabPanel,Flex, Icon, Spacer, Button, HStack, Container, Divider} from '@chakra-ui/react';
import { useState,useEffect } from 'react';
import ImageScroll from '../../components/ImageScroll';


function ShowImages(props) {
  const { images } = props; // Destructure images from props
  
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [size, setSize] = React.useState('md')

  const handleSizeClick = (newSize) => {
    setSize(newSize)
    onOpen()
  }

  
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 700) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="relative grid grid-cols-2 max-h-[500px] overflow-hidden h-[500px] rounded-xl">
      {/* First Image */}
      {images && images.length > 0 && (
        <div className="bg-red-200 w-full h-full">
          <img
            className="w-full h-full object-cover"
            src={images[0].image} // Use the first image from the array
            alt="Property"
          />
        </div>
      )}
      
      {/* Remaining Images */}
      {images.length > 1 && (
        <div className="h-[500px]">
          <div className="bg-purple-200 h-1/2">
            <img
              className="object-cover h-full w-full"
              src={images[1].image} // Use the second image from the array
              alt="Property"
            />
          </div>
          <div className="bg-green-200 h-1/2">
            <img
              className="w-full h-full object-cover"
              src={images[2].image} // Use the third image from the array
              alt="Property"
            />
          </div>
        </div>
      )}
      
      {/* View More Button */}
      <div className="absolute bottom-4 right-4">
        <button
          onClick={() => handleSizeClick("full")} key="full" m={4}
          className="bg-gray-500 text-white font-semibold py-2 px-4 rounded"
        >
          View Photos
        </button>
        <Modal onClose={onClose} size={size} isOpen={isOpen}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Property Images</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
              <ImageScroll images={images} width="100%"  mt="10px" />
              </ModalBody>
              <ModalFooter>
                <Button onClick={onClose}>Close</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
      </div>
    </div>
  );
}

export default ShowImages;
