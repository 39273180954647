import React, { useEffect } from 'react';
import { Button } from '@chakra-ui/react';

const AgentActionCellRenderer = (props) => {
  const { editRowId, onEditClick, onCancelClick, onUpdateClick, onDeleteClick, saveNewRow } = props.context;
  const isEditing = editRowId == props.node.data.id;
  const isNewRow = props.node.data.isNew; // && !props.node.data.agentEmailId && !props.node.data.propertyId;

  const handleSave = () => {
    if (isNewRow) {
      saveNewRow(props.node.data);
    } else {
      onUpdateClick(props.node.data);
    }
  };

  return (
    <div>
      {isEditing ? (
        <>
          <Button onClick={handleSave} colorScheme="blue" size="sm" mr={2}>
            {isNewRow ? 'Save' : 'Update'}
          </Button>
          <Button onClick={onCancelClick} colorScheme="red" size="sm">
            Cancel
          </Button>
        </>
      ) : (
        <>
          <Button onClick={() => onEditClick(props.node.data.id)} colorScheme="blue" size="sm" mr={2}>
            Edit
          </Button>
          <Button onClick={() => onDeleteClick(props.node.data.id)} colorScheme="red" size="sm">
            Delete
          </Button>
        </>
      )}
    </div>
  );
};

export default AgentActionCellRenderer;
