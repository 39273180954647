import React, { useEffect, useState } from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';
const PrivacyPolicyPage = () => {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    // Fetch the HTML file content
    fetch('/src/pages/privacy.html')
      .then((response) => response.text())
      .then((data) => setHtmlContent(data))
      .catch((error) => console.error('Error loading HTML:', error));
  }, []);

  return (
    <div className="bg-primary font-linksans">
      <div className="container px-12 py-3">
        <div className="flex-col items-center flex justify-center">
          <Box mx="auto" p={5} className="contentdiv">
            <Heading as="h2" size="lg" mb={3}>
              Privacy Policy
            </Heading>
            <p><strong>Last updated 09 October, 2024</strong>&nbsp;&nbsp;</p>
            <p>This privacy notice for Knock The Door Inc (&quot;we,&quot; &quot;us,&quot; or &quot;our&quot;), describes how and why we might collect, store, use, and/or share (&quot;process&quot;) your information when you use our services (&quot;Services&quot;), such as when you:&nbsp;Questions or concerns?&nbsp;Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services.&nbsp;&nbsp;</p>
            <p><strong>SUMMARY OF KEY POINTS</strong>&nbsp;</p>
            <p>This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our&nbsp;table of contents&nbsp;below to find the section you are looking for.&nbsp;</p>
            <p><strong>What personal information do we process?&nbsp;</strong>When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use. Learn more about personal information you disclose to us.&nbsp;&nbsp;</p>
            <p><strong>Do we process any sensitive personal information?&nbsp;</strong>We do not process sensitive personal information.&nbsp;</p>
            <p><strong>Do we collect any information from third parties?&nbsp;</strong>We may collect information from public databases, marketing partners, social media platforms, and other outside sources. Learn more about information collected from other sources.</p>
            <p><strong>How do we process your information?&nbsp;</strong>We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about how we process your information.&nbsp;</p>
            <p><strong>In what situations and with which parties do we share personal information?&nbsp;</strong>We may share information in specific situations and with specific third parties. Learn more about when and with whom we share your personal information.&nbsp;</p>
            <p><strong>What are your rights?&nbsp;</strong>Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about your privacy rights.</p>
            <p><strong>How do you exercise your rights?&nbsp;</strong>The easiest way to exercise your rights is by submitting a data subject access request, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.&nbsp;</p>
            <p>Want to learn more about what we do with any information we collect? Review the privacy notice in full.&nbsp;</p>
            <p><strong>TABLE OF CONTENTS</strong>&nbsp;</p>
            <ol start={1}>
              <li>WHAT INFORMATION DO WE COLLECT?</li>
              <li>HOW DO WE PROCESS YOUR INFORMATION?</li>
              <li>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</li>
              <li>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</li>
              <li>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</li>
              <li>IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</li>
              <li>HOW LONG DO WE KEEP YOUR INFORMATION?</li>
              <li>DO WE COLLECT INFORMATION FROM MINORS</li>
              <li>WHAT ARE YOUR PRIVACY RIGHTS?</li>
              <li>CONTROLS FOR DO-NOT-TRACK FEATURES</li>
              <li>DO WE MAKE UPDATES TO THIS NOTICE?</li>
              <li>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
              <li>HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</li>
            </ol>
            <p><strong>1. WHAT INFORMATION DO WE COLLECT? Personal information you disclose to us</strong>&nbsp;</p>
            <p><strong>In Short:&nbsp;</strong> We collect personal information that you provide to us and other sources to make a property transaction happen via an individual or group referral model. A property transaction needs various personal and business information, therefore, we focus on collecting information that helps us to refer to the right agent and make a house transaction happen as our business model depends on it.&nbsp;</p>
            <p>We collect&nbsp;</p>
            <ol>
                <li>Personal information (first name, last name, email, phone number, username, password, company profile info, preferences such as location, occupation, title, performance evaluations, and prior employment history)</li>
                <li>Financial information (such as credit scores, mortgage input data if required, bank, credit or debit card number, account type, routing number, bank institution name, account balances, credit history, investment activity, income or source of funds, and payment/transaction history though we do not store in our systems and rely on external third party processors and payment processing vendors)</li>
                <li>Communication information (chat, support portal, emails, text, online meetings), recorded conversations, marketing, sales, and outreach communications</li>
                <li>User contributions (preferences, properties data, files, documents, videos, images, data, or information you choose to upload or transmit through your communications)</li>
                <li>User-shared activity (reviews, comments, compares, invites, groups, posts, shared group activities)</li>
                <li>Commercial information (employees especially sales teams or agents, ownership information, primary &amp;secondary business address, properties or services purchased, obtained, or considered, or other purchasing histories or tendencies, home purchase information and preferences, such as the location, type of home, and price range of the home you are interested in buying or selling, records of personal property)</li>
            </ol>
            <p><strong>Information from third parties;&nbsp;</strong></p>
            <ol>
                <li>Our Partners or Affiliates: We may receive personal and company information from our partners or affiliates, and online sources for information such as license, preferences, phone numbers, and emails.</li>
                <li>Social Media: Depending on your social media settings, we may receive personal information such as email, and friends lists, likes, follows, your profile information, profile picture, gender, username, user ID associated with your social media account, age range, language, country, and any other information you permit the social network to share with third parties from social media single sign-on plug-ins. We may use single sign-on (&ldquo;SSO&rdquo;) to authenticate a user via social media plug-ins such as Google, Facebook, Twitter, Apple. We will have access to certain information from those third parties in accordance with the authorization procedures determined by those third parties and your settings and acceptance.&nbsp;</li>
                <li>Third-party service providers, data providers, business partners and other sources: We may receive agents, builders, brokers, and property data from external providers, external providers (such as escrow companies) and websites.&nbsp;</li>
                <li>Analytics, Advertisements, Software, Form Partners: We may receive information from external partners such as Analytics, Advertisements, Software and Form partners to provide service and improve those services</li>
            </ol>
            <p>based on the role (Buyer, Seller, public users, Agents, Brokers, and real estate professionals)&nbsp;that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.&nbsp;</p>
            <p><strong>Sensitive Information.&nbsp;</strong>We limit or do not process sensitive (SSN, TIN, location, personal financial information as described above, citizenship status) information unless it is required for referrals or real estate transactions. All personal information that you provide to us must be true, complete, and accurate, and you must notify&nbsp;us of any changes to such personal information.&nbsp;</p>
            <p><strong>Information automatically collected</strong>&nbsp;</p>
            <p><strong>In Short:&nbsp;</strong>Some information such as your Internet Protocol (IP) address and/or browser and device characteristics is collected automatically when you visit our Services.&nbsp;&nbsp;</p>
            <p>We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.&nbsp;</p>
            <p><strong>2. HOW DO WE PROCESS YOUR INFORMATION?</strong></p>
            <p><strong>In Short:&nbsp;</strong>We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.&nbsp;</p>
            <p>Like many businesses, we also collect information through cookies, analytics, advertisements, and similar technologies.&nbsp;</p>
            <p><strong>We process your personal information for a variety of reasons, depending on how you interact with our Services,&nbsp;</strong>We process the personal information that you (public website users, prospective buyers, sellers, agents, brokers, builders, and other real estate professionals ) provide to us and other sources to make a property transaction happen via an individual or group referral model. A property transaction needs various personal and business information, therefore, we focus on collecting information that helps us to refer to the right agent and make a house transaction happen as our business model depends on it,&nbsp;including:&nbsp;</p>
            <p><strong>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</strong>&nbsp;</p>
            <p><strong>In Short:&nbsp;</strong>We may share information in specific situations described in this section and/or with the following third parties and other partners in order to make property transaction happen.</p>
            <p>We may need to share your personal information in the following situations:&nbsp;</p>
            <p><strong>Real estate professionals.&nbsp;</strong>We share or transfer your information to various real estate professionals to make house transaction happen, initially with prospective real estate agents, brokers or builders and inturn they may share your information with escrow companies, title companies, home inspectors,&nbsp;appraisal companies, homeowners insurance companies, mortgage companies based on the stage of each transaction.&nbsp;</p>
            <p><strong>Business Transfers.&nbsp;</strong>We may share or transfer your information in connection with or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.&nbsp;</p>
            <p><strong>Affiliates.&nbsp;</strong>We may share your information with our affiliates, in which case we will require those affiliates to honor this privacy notice. Affiliates include our parent company and any subsidiaries, joint venture partners, or other companies that we control or that are under common control with us.&nbsp;&nbsp;</p>
            <p><strong>Business, Service, Advertising, and other Partners.&nbsp;</strong>We may share your information with our business partners, service providers, ad partners, your employer, and legal obligation-related parties to offer you certain products, services, or promotions.&nbsp;</p>
            <p><strong>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</strong>&nbsp;</p>
            <p><strong>In Short:&nbsp;</strong>We may use cookies and other tracking technologies to collect and store your information.&nbsp;</p>
            <p>We may use cookies and similar tracking technologies (like web beacons and pixels) to gather information when you interact with our Services. Some online tracking technologies help us maintain the security of our Services, prevent crashes, fix bugs, save your preferences, and assist with basic site functions.&nbsp;</p>
            <p>We also permit third parties and service providers to use online tracking technologies on our Services for analytics and advertising, including to help manage and display advertisements, to tailor advertisements to your interests, or to send abandoned certain activity reminders (depending on your communication preferences). The third parties and service providers use their technology to provide advertising about products and services tailored to your interests which may appear either on our Services or on other websites.&nbsp;&nbsp;</p>
            <p>Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.&nbsp;</p>
            <p>Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners in order to make their websites work or to work more efficiently, as well as to provide reporting information. Cookies set by this website are called &quot;first-party cookies.&quot; Cookies set by parties other than the website owner are called &quot;third-party cookies.&quot; Third-party cookies enable third-party features or functionality to be provided on or through the website (e.g., advertising, interactive content, and analytics). The parties that set these third-party cookies can recognize your computer both when it visits the website in question and also when it visits certain other websites.</p>
            <p>We use first- and third-party cookies for several reasons. Some cookies are required for technical reasons in order for our Website to operate, and we refer to these as &quot;essential&quot; or &quot;strictly necessary&quot; cookies. Other cookies also enable us to track and target the interests of our users to enhance the experience on our Online Properties. Third parties serve cookies through our Website for advertising, analytics, and other purposes. This is described in more detail below.</p>
            <p>You can control third-party cookies via your browser functions or plugins. However, Third parties may serve cookies on your computer or mobile device to serve advertising through our Website. These companies may use information about your visits to this and other websites in order to provide relevant advertisements about goods and services that you may be interested in. They may also employ technology that is used to measure the effectiveness of advertisements. They can accomplish this by using cookies or web beacons to collect information about your visits to this and other sites in order to provide relevant advertisements about goods and services of potential interest to you. The information collected through this process does not enable us or them to identify your name, contact details, or other details that directly identify you unless you choose to provide these.&nbsp;</p>
            <p>Third parties are responsible for managing their privacy policy and we don&rsquo;t have any control over such policies or their technologies.&nbsp;&nbsp;</p>
            <p><strong>5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</strong></p>
            <p><strong>In Short:&nbsp;</strong>If you choose to register or log in to our Services using a social media account, we may have access to certain information about you.&nbsp;</p>
            <p>Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook or X logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, and profile picture, as well as other information you choose to make public on such a social media platform.&nbsp;</p>
            <p>We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use, and share your personal information, and how you can set your privacy preferences on their sites and apps.&nbsp;</p>
            <p><strong>6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</strong>&nbsp;</p>
            <p><strong>In Short:&nbsp;</strong>We may transfer, store, and process your information in countries other than your own.&nbsp;&nbsp;</p>
            <p>Our servers are located in. If you are accessing our Services from outside, please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information (see &quot;WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?&quot; above), in and other countries.</p>
            <p>If you are a resident in the European Economic Area (EEA), United Kingdom (UK), or Switzerland, then these countries may not necessarily have data protection laws or other similar laws as comprehensive as those in your country. However, we will take all necessary measures to protect your personal information in accordance with this privacy notice and applicable law.</p>
            <p><strong>7. HOW LONG DO WE KEEP YOUR INFORMATION?</strong>&nbsp;</p>
            <p><strong>In Short:&nbsp;</strong>We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.</p>
            <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).</p>
            <p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>
            <p><strong>8. DO WE COLLECT INFORMATION FROM MINORS?</strong>&nbsp;</p>
            <p><strong>In Short:&nbsp;</strong>We do not knowingly collect data from or market to children under 18 years of age.</p>
            <p>We do not knowingly collect, solicit data from, or market to children under 18 years of age, nor do we knowingly sell such personal information. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent&rsquo;s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at</p>
            <p><strong>9. WHAT ARE YOUR PRIVACY RIGHTS?</strong>&nbsp;</p>
            <p><strong>In Short:&nbsp;</strong>You may review, change, or terminate your account at any time, depending on your country, province, or state of residence.&nbsp;&nbsp;</p>
            <p><strong>Withdrawing your consent:&nbsp;</strong>If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section &quot;HOW CAN YOU CONTACT US ABOUT THIS NOTICE?&quot; below.&nbsp;</p>
            <p>However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.&nbsp;&nbsp;</p>
            <p><strong>Account Information</strong>&nbsp;&nbsp;</p>
            <p>If you would at any time like to review or change the information in your account or terminate your account, you can:<br />&nbsp;Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.</p>
            <p><strong>10. CONTROLS FOR DO-NOT-TRACK FEATURES</strong></p>
            <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (&quot;DNT&quot;) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage, no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</p>
            <p><strong>11. DO WE MAKE UPDATES TO THIS NOTICE?</strong>&nbsp;</p>
            <p><strong>In Short:&nbsp;</strong>Yes, we will update this notice as necessary to stay compliant with relevant laws.&nbsp;</p>
            <p>We may update this privacy notice from time to time. The updated version will be indicated by an updated &quot;Revised&quot; date at the top of this privacy notice. If we make material changes to this privacy notice, we may relying on your regular reviews of this privacy policy with updated date without explicit notifications to you, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.&nbsp;</p>
            <p><strong>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</strong>&nbsp;&nbsp;</p>
            <p>If you have questions or comments about this notice, you may contact us by post at:&nbsp;</p>
            <p><strong>13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</strong>&nbsp;</p>
            <p>Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, details about how we have processed it, correct inaccuracies, or delete your personal information. You may also have the right to withdraw your consent to our processing of your personal information. These rights may be limited in some circumstances by applicable law. To request to review, update, or delete your personal information, please fill out and submit a data subject access request.&nbsp;</p>
            <p><strong>CONTACT US</strong></p>
            <p>In order to resolve a complaint regarding the Services or to receive further information regarding use of the Services, please contact us at &nbsp;
             <a className="underline" target="_blank" href="https://support.knockthedoor.com">Support</a></p>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;