import { Link } from "react-router-dom"


function ReadyToBuy() {
  return (
    <div className="container mx-auto 2xl:py-10 xl:py-10 lg:py-10 md:py-10 py-8">
        <div className="bg-primary px-12 py-12 flex flex-col justify-center items-center rounded-lg ">
            <h3 className="2xl:text-4xl xl:text-3xl lg:text-2xl md:text-2xl text-2xl font-semibold text-textColor pb-5">
            Ready to get started?</h3>
            <p className="text-paragraphColor pb-8 text-center">
            Learn & Start FREE to explore options! 
            Answer a few quick questions to find the perfect match based on your purchasing criteria. 
            You will be on a waitlist to find a perfect match based on your purchasing criteria.
            </p>
            <div>
              <Link to="/groupbuyers/get-started/" className="text-[#060605] bg-white hover:bg-btnbg  transition-all duration-300 btnfontpage text-base px-6 py-3 rounded-lg border-borderColor border ">
              Get Started for FREE & Find Vetted Properties
              </Link>
              
            </div>
            <div class="py-5">
            {process.env.REACT_APP_NEW_HOME_BUYER === 'true' && (  
              <>
              <Link to="/buyer/home/" className="hover:underline">
                  I’m a Individual Buyer 
                </Link> &nbsp;|&nbsp; 
                </>
             )}
              <Link to="/builder/home/" className="hover:underline">
                I’m a Builder
              </Link>&nbsp;|&nbsp; 
              <Link to="/agent/home/" className="hover:underline">
                I’m a Realtor/Agent
              </Link>
            </div>

        </div>
    </div>
  )
}

export default ReadyToBuy