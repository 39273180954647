import React from 'react';
import { 
  Box, 
  Button, 
  Flex, 
  FormControl, 
  FormLabel, 
  Input, 
  Textarea, 
  Heading, 
  Stack, 
  Text ,
  Container
} from '@chakra-ui/react';

const ContactPage = () => {
  return (
    <div className="bg-primary  font-linksans">
      <div className="container px-10 py-12">
        <div className="flex-col items-center flex justify-center">
     
    <Box 
      w="full" 
      minH="100vh" 
      bg="gray.50" 
      py={10} 
      px={6}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box 
        maxW="700px" 
        w="full" 
        bg="white" 
        p={8} 
        borderRadius="lg" 
        boxShadow="lg"
      >
        <Heading as="h1" mb={6} textAlign="center" color="black.500">
          Contact Us
        </Heading>
        <Text fontSize="lg" color="gray.600" mb={8} textAlign="center">
          Got a question about real estate? Reach out to us and we'll get back to you shortly!
        </Text>

        <Stack spacing={4}>
          <FormControl id="name" isRequired>
            <FormLabel>Name</FormLabel>
            <Input placeholder="Your Name" />
          </FormControl>

          <FormControl id="email" isRequired>
            <FormLabel>Email</FormLabel>
            <Input type="email" placeholder="Your Email" />
          </FormControl>

          <FormControl id="message" isRequired>
            <FormLabel>Message</FormLabel>
            <Textarea placeholder="Your Message" />
          </FormControl>

          <Button 
            colorScheme="blue" 
            size="lg" 
            type="submit"
            w="full"
          >
            Send Message
          </Button>
        </Stack>
      </Box>
    </Box>
    </div>
    </div>
    </div>
  );
}

export default ContactPage;
