import { useEffect, useState } from "react";
import heroImg2560x550 from "../../assets/images/buyer-home-1a-2560x550.jpg";
import heroImg2560x1706 from "../../assets/images/buyer-home-1b-2560x1706.jpg";
import heroImg1920x1080 from "../../assets/images/buyer-home-1c-1920x1080.jpg";
import heroImg1685x760 from "../../assets/images/buyer-home-1e-1685x760-2.jpg";
import heroImg800x600 from "../../assets/images/buyer-home-1e-800x600.jpg";

function Hero() {

  const [backgroundImage, setBackgroundImage] = useState(heroImg2560x550);

  useEffect(() => {
    const updateBackgroundImage = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth >= 2560 && screenWidth > 1080) {
        setBackgroundImage(heroImg2560x550);
      } else if (screenWidth >= 1920) {
        setBackgroundImage(heroImg2560x550);
      } else if (screenWidth >= 1685) {
        setBackgroundImage(heroImg2560x550);
      } else if (screenWidth >= 800) {
        setBackgroundImage(heroImg2560x550);
      } else {
        setBackgroundImage(heroImg2560x550); // Default image or the closest fit
      }
    };

    // Initial background image setting
    updateBackgroundImage();

    // Update on window resize
    window.addEventListener("resize", updateBackgroundImage);

    // Clean up listener on component unmount
    return () => window.removeEventListener("resize", updateBackgroundImage);
  }, []);

  return (
    <div>
       <div className="w-full xl:h-[480px] lg:h-[400px] 2xl:h-[550px] md:h-[450px] h-[400px] font-linksans">
        <div
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover', // Ensures the image covers the entire area
            backgroundPosition: 'center', // Centers the image
          }}
          className=" w-full h-full flex justify-center items-center"
        >
          <div className="w-[100%] 2xl:w-[50%] xl:w-[55%] lg:w-[60%] md:w-[80%] flex flex-col justify-center items-center">
            <div className="w-full flex justify-center items-center">
              <h1 className="font-semibold text-center text-white 2xl:text-6xl xl:text-5xl lg:text-4xl md:text-4xl text-3xl  pb-10">
              Buy a beautiful new home
              </h1>
            </div>

           <div className="grid grid-cols-1 gap-6 pt-3 px-12">
              <a
                href="/buyer/get-started/"
                target="_blank"
                className="py-4 px-4 rounded-lg text-sm text-[#000 ]  justify-center bg-transparent border border-blue-500 hover:bg-[rgb(40,116,191)] hover:border-blue-700 hover:text-white transition-colors duration-300"
              >
                <span>Get Started & Find A Beautiful Home</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
