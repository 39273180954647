import { Box, Text} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next'; 

function Step({ children, isDone, isCurrent }) {
  return (
    <Box
      className="step"
      bg={isCurrent ? '#3182ce' : '#f0f3f5'}
      color={isCurrent ? '#fff' : '#666'}
      borderRadius={isCurrent ? '4px' : '0'}
      px={3}
      py={2}
      position="relative"
      cursor="default"
      minWidth="180px"
      margin="0 3px"
      textAlign="center"
      fontWeight={'medium'}
      float="left"
      transition="background-color 0.2s ease"
      _after={{
        content: '""',
        position: 'absolute',
        top: 0,
        right: '-15px',
        width: 0,
        height: 0,
        borderTop: '19px solid transparent',
        borderBottom: '17px solid transparent',
        borderLeft: isCurrent ? '17px solid #3182ce' : '17px solid #f0f3f5',
        zIndex: 2,
        transition: 'border-color 0.2s ease',
      }}
      _before={{
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 'auto',
        width: 0,
        height: 0,
        borderTop: '19px solid transparent',
        borderBottom: '17px solid transparent',
        borderLeft: isCurrent ? '17px solid #fff' : '17px solid #fff',
        zIndex: 0,
        transition: 'border-color 0.2s ease',
      }}
    >
      {children}
      {isDone && <span>✔</span>}
    </Box>
  );
}

function ScrollingTextWithArrow() {
  const [currentStep, setCurrentStep] = useState(0);
  const { t } = useTranslation(); 
  const steps = [t('search'), t('compare'), t('signup')];

  const handleNext = () => {
    setCurrentStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
  };

  const handlePrev = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  return (
    <Box className="container arrow-steps-box" width="100%" >
      <Box className="wrapper" display="table-cell" p={2} verticalAlign="middle">
        <Box className="arrow-steps clearfix">
          {steps.map((step, index) => (
            <Step key={index} isCurrent={index === currentStep} isDone={index < currentStep}>
              <Text>{step}</Text>
            </Step>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default ScrollingTextWithArrow;
