

function TestimonialInfo() {
  return (
    <div className="w-full h-fit">

        <div className="p-14 rounded-l-lg bg-primary flex flex-col ">
            <h3 className="2xl:pb-8 pb-4 xl:pb-6 lg:pb-6 md:pb-6 text-textColor font-semibold 2xl:text-4xl xl:text-3xl lg:text-2xl md:text-2xl text-2xl">Rental Home Buyers</h3>
            <div className="flex items-center pb-2 gap-6">
                <p className="text-paragraphColor text-justify text-center w-full">
                If you are looking to invest or build wealth via fixed real estate assets and generate passive income over the time, we are here to help you. You simply join our groups, be on the waitlist and we match the right group that fits your purchasing criteria to present matched and vetted properties. We offer limited quarterly vetted properties in various categories; focussing on single family homes for rental income/passive income and land & commercial properties to hold and sell later options. Currently, we present vetted properties on a quarterly basis. 
                </p>
            </div>
        </div>
        
    </div>
  )
}

export default TestimonialInfo