import React, { useState } from 'react';
import { Box, Grid, Image, Button, Text, Flex, Stack, Icon, Divider, HStack } from '@chakra-ui/react';
import { MdBathroom, MdCalculate, MdCalendarMonth, MdCropLandscape, MdDiscount, MdHome, MdHouse, MdHouseSiding, MdKingBed, MdLiving, MdLocationOn, MdLocationPin, MdPointOfSale, MdPriceChange, MdRepartition, MdRoom, MdTaxiAlert, MdVerifiedUser } from 'react-icons/md'; // Import the location ico
import {FaExchangeAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useProducts } from './ProductsProvider'; 
import GroupMenu from '../user/GroupMenu';
const ProductCard = (props) => {
    const [isHovered, setIsHovered] = useState(false);
    const isLoggedIn = !!localStorage.getItem('userid');

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    let storedLanguage = localStorage.getItem('i18nextLng');

    if (!storedLanguage || storedLanguage.trim() === '') {
        storedLanguage = 'en';
    }

    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    
    const {products, selectedProducts,addProductToSelection, removeProductFromSelection } = useProducts();
    const isAddedToCompare = selectedProducts && selectedProducts.some(productId => productId === props.data.id);


    const handleCompareClick = () => {
        if (isAddedToCompare) {
            removeProductFromSelection(props.data.id);
        } else {
            addProductToSelection(props.data.id);
        }
    };

    const { data, added } = props;

    return (
        <Box borderWidth="1px" borderRadius="lg" background="#fff" overflow="hidden" position="relative" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <Stack>   
            <Stack spacing={1} p={4}>
            {isLoggedIn ? (
                  <GroupMenu propertyId={props.data.id}/>
                ) : (
                <Button
                size="sm"
                colorScheme={props.added ? 'red' : 'blue'}
                aria-label={props.added ? 'Remove from Compare' : 'Add to Compare'}
                onClick={handleCompareClick}
                leftIcon={props.added ? <FaExchangeAlt /> : <FaExchangeAlt />}
                >
                {props.added ? 'Removethis' : 'Compare This'}
                </Button>
                )}
                {data.price && data.price !== 'N/A' && (
                        <Text fontWeight="bold" fontSize="lg" p={1}>
                        <Icon as={MdPriceChange} color="#8796a1" mr={2} />${data.price}
                        </Text>
                    )}

                    {data.price_per_sqft && data.price_per_sqft !== 'N/A' && (
                        <Text fontWeight="sm" p={1}>
                        <Icon as={MdCropLandscape} color="#8796a1" mr={2} />{data.price_per_sqft} /Sq Ft
                        </Text>
                    )}

                    {data.sqft && data.sqft !== 'N/A' && (
                        <Text fontWeight="sm" p={1}>
                        <Icon as={MdCropLandscape} color="#8796a1" mr={2} />{data.sqft} Sqft
                        </Text>
                    )}

                    {data.bathrooms && data.bathrooms !== 'N/A' && (
                        <Text fontWeight="sm" p={1}>
                        <Icon as={MdBathroom} color="#8796a1" mr={2} />{data.bathrooms} Baths
                        </Text>
                    )}

                    {data.bedrooms && data.bedrooms !== 'N/A' && (
                        <Text fontWeight="sm" p={1}>
                        <Icon as={MdKingBed} color="#8796a1" mr={2} />{data.bedrooms} Beds
                        </Text>
                    )}

                    {data.address && data.address !== 'N/A' && (
                        <Text fontWeight="medium" maxH="20px" overflow="hidden">
                        <Icon as={MdLocationOn} color="#8796a1" mr={2} />{data.address}
                        </Text>
                    )}

               <Divider/>    
               <HStack spacing={2} align="center" justifyContent="space-between">
                {data.daysonthemarket && data.daysonthemarket !== 'N/A' && (
                <Text fontWeight="medium">
                    <Icon as={MdCalculate} color="#8796a1" mr={2} />{data.daysonthemarket} days
                </Text>
                )}

                {data.property_type && data.property_type !== 'N/A' && (
                <Text fontWeight="medium" p={1}>
                    <Icon as={MdHome} color="#8796a1" mr={2} />{data.property_type}
                </Text>
                )}
            </HStack>
            <Button size="sm" colorScheme="blue" style={{ width: 'auto' }}>
                <Link to={`/home/${storedLanguage}/${data.state}/${data.city}/${props.data.id}/${encodeURIComponent(props.data.address)}/${props.data.community}/${props.data.property_type}/`} rel="noopener noreferrer"> View
              </Link>
                </Button>
            </Stack>
            </Stack>
              </Box>
    );
}

export default ProductCard;
