import React from "react";
import GoogleMapReact from 'google-map-react';
import Marker from './Marker';


const AnyReactComponent = ({ text }) => <div>{text}</div>;

export default function MapContainer(props){
  
  const { MapInfo, data } = props;
  const { geodata, address } = MapInfo || {};
  const [latitude, longitude] = geodata ? geodata.split(',').map(Number) : [0, 0]; // Convert to numbers

  const defaultProps = {
    center: {
      lat: latitude || 0,
      lng: longitude || 0,
    },
    zoom: 11,
  };

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '100vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_MAP_KEY,
          libraries: ["places", "geometry"] // Include libraries for additional functionality
        }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        options={{ // Configure map options
          streetViewControl: true, // Enable street view control
          mapTypeControl: true, // Enable map type control (e.g., satellite view)
          fullscreenControl: true, // Enable fullscreen control
          zoomControl: true, // Enable zoom control
        }}
        onGoogleApiLoaded={({ map, maps }) => console.log('Map loaded', map, maps)}
        yesIWantToUseGoogleMapApiInternals
      >
        <AnyReactComponent
          lat={defaultProps.center.lat}
          lng={defaultProps.center.lng}
        /> 

        <Marker
          lat={defaultProps.center.lat}
          lng={defaultProps.center.lng}
          text={address}
          address={address}
        />
      </GoogleMapReact>
    </div>
  );
}
