export const filters = [
    "id",
    "Address",
    "Price",
    "Sqft",
    "Discount Price",
    "Tax",
    "Living Area",
    "Lot Area",
    "Sale Status",
    "Property Type",
    "Room Information",
    "Garages",
    "Parking",
    "DaysOnTheMarket",
    "Built Year",
    "FloorPlan",
    "CommunityName",
    "BuilderName",
    "Totalrooms",
    "Bedrooms",
    "Bathrooms",
 ]