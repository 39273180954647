import React, { useState } from 'react';
import axios from 'axios';
import {
  Button,
  FormControl,
  Flex,
  Heading,
  Input,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

export default function ForgotPasswordForm() {
  const [email, setEmail] = useState('');
  const [apiResponse, setApiResponse] = useState('');
  const [emailError, setEmailError] = useState('');

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleResetRequest = () => {
    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address.');
      return;
    }
    axios.post(`${process.env.REACT_APP_DYNAMO_API_URL}/users/forgot-password`, { email })
      .then((response) => {
        setApiResponse(response.data);
      })
      .catch((error) => {
        setApiResponse(`Error: ${error.message}`);
      });
  };

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}>
      <Stack
        spacing={4}
        w={'full'}
        maxW={'md'}
        bg={useColorModeValue('white', 'gray.700')}
        rounded={'xl'}
        boxShadow={'lg'}
        p={6}
        my={12}>
        <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
          Forgot your password?
        </Heading>
        <Text
          fontSize={{ base: 'sm', sm: 'md' }}
          color={useColorModeValue('gray.800', 'gray.400')}>
          You'll get an email with a reset link
        </Text>
        <FormControl id="email">
          <Input
            placeholder="your-email@example.com"
            _placeholder={{ color: 'gray.500' }}
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError('');
            }}
            isInvalid={emailError !== ''}
          />
          {emailError && <Text color="red.500" fontSize="sm">{emailError}</Text>}
        </FormControl>
        <Stack spacing={6}>
          <Button
            bg={'blue.400'}
            color={'white'}
            _hover={{
              bg: 'blue.500',
            }}
            onClick={handleResetRequest}
          >
            Request Reset
          </Button>
          {apiResponse && <Text>{apiResponse}</Text>}
        </Stack>
      </Stack>
    </Flex>
  );
}
