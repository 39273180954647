import AboutHero from "../../components/home/Aboutus/AboutHero"
import TestimonialMain from "../../components/home/Aboutus/TestimonialMain"
import InnovativeGroupModelMain from "../../components/home/Aboutus/InnovativeGroupModelMain"
import LetsTalk from "../../components/home/LetsTalk"
import Whoweare from "../../components/home/Whoweare"
import OurMission from "../../components/home/OurMission"
import NewHomeBuyerMain from "../../components/home/Aboutus/NewHomeBuyerMain"
import RentalHomeBuyerMain from "../../components/home/Aboutus/RentalHomeBuyerMain"
import ComparePlatforms from "../../components/home/Aboutus/ComparePlatforms"




function AboutUsPage() {
  return (
    <div className="font-linksans">
        <AboutHero></AboutHero>
        <div className=" ">
          <Whoweare></Whoweare>
          <OurMission></OurMission>
           <RentalHomeBuyerMain></RentalHomeBuyerMain>
           {process.env.REACT_APP_NEW_HOME_BUYER === 'true' && (
              <NewHomeBuyerMain></NewHomeBuyerMain>
            )}
            <ComparePlatforms></ComparePlatforms>
          <LetsTalk></LetsTalk>
        </div>
    </div>
  )
}

export default AboutUsPage