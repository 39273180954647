import React from 'react';
import {
  Box,
  Heading,
  Text,
  Stack,
  Divider,
  Button,
  List,
  ListItem,
  ListIcon,
  SimpleGrid,
  Container
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';

const WhyChooseUs = () => {
  return (
    <div className="bg-primary  font-linksans">
      <div className="container px-10 py-12">
        <div className="flex-col items-center flex justify-center">
      {/* Page Title */}
      <Heading as="h1" size="md" mb={6} textAlign="center">
        Why Choose Us?
      </Heading>

      {/* Introduction */}
      <Text fontSize="lg" mb={6} textAlign="center">
        We are committed to providing the best real estate experience for buyers, sellers, and renters. 
        Here's why choosing us makes all the difference.
      </Text>

      <Divider mb={6} />

      {/* Content Sections */}
      <Stack spacing={6}>
        <Box>
          <Heading as="h2" size="md" mb={4}>
            Expert Knowledge
          </Heading>
          <Text>
            Our team of experts have years of experience in the real estate market. We provide you with the best advice on current market trends, pricing strategies, and investment opportunities.
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="md" mb={4}>
            Personalized Service
          </Heading>
          <Text>
            We understand that each client is unique. That’s why we tailor our services to meet your individual needs, whether you're buying your first home or selling a luxury property.
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="md" mb={4}>
            Extensive Property Listings
          </Heading>
          <Text>
            With a wide range of properties available, we offer a variety of options to suit different preferences and budgets. From budget-friendly homes to luxury properties, we have something for everyone.
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="md" mb={4}>
            Our Commitment to You
          </Heading>
          <Text>
            We prioritize our clients by providing exceptional service at every step of the way. From property search to closing, we ensure you have a seamless and stress-free experience.
          </Text>
        </Box>
        
        {/* Grid of Benefits */}
        <Box>
          <Heading as="h2" size="md" mb={4}>
            Our Benefits at a Glance
          </Heading>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
            <Box p={4} borderWidth="1px" borderRadius="lg" textAlign="center">
              <CheckCircleIcon color="green.500" mb={2} />
              <Heading as="h3" size="md" mb={2}>Fast Transactions</Heading>
              <Text>
                We expedite the buying and selling process to help you close deals faster.
              </Text>
            </Box>
            <Box p={4} borderWidth="1px" borderRadius="lg" textAlign="center">
              <CheckCircleIcon color="green.500" mb={2} />
              <Heading as="h3" size="md" mb={2}>Trusted Agents</Heading>
              <Text>
                Our agents are highly skilled and certified to guide you through the process.
              </Text>
            </Box>
            <Box p={4} borderWidth="1px" borderRadius="lg" textAlign="center">
              <CheckCircleIcon color="green.500" mb={2} />
              <Heading as="h3" size="md" mb={2}>Comprehensive Support</Heading>
              <Text>
                We provide support through every phase of the buying or selling journey.
              </Text>
            </Box>
            <Box p={4} borderWidth="1px" borderRadius="lg" textAlign="center">
              <CheckCircleIcon color="green.500" mb={2} />
              <Heading as="h3" size="md" mb={2}>Negotiation Expertise</Heading>
              <Text>
                We help you get the best deal with strong negotiation tactics.
              </Text>
            </Box>
          </SimpleGrid>
        </Box>
      </Stack>

      <Divider my={6} />

      {/* CTA Button */}
      <Button colorScheme="blue" size="lg" width="100%" mb={6}>
        Get Started Today
      </Button>

      {/* Footer Text */}
      <Text fontSize="sm" color="gray.500" textAlign="center">
        Ready to make your next real estate move? Contact us today and let's get started on your journey.
      </Text>
    </div>
    </div>
    </div>
  );
};

export default WhyChooseUs;
