import React, { useState, useEffect } from 'react';
import { Box, Input, Tag, TagLabel, Stack } from '@chakra-ui/react';
import axiosInstance, {axiosInstanceES} from '../../utils/axio-instance';
import { useNavigate } from 'react-router-dom';

const CustomAutocomplete = ({ refine}) => {
  const [fractions, setFractions] = useState({});
  const [isAddressSelected, setIsAddressSelected] = useState(false);
  const [term, setTerm] = useState('');
  const navigate = useNavigate();

  useEffect(()=>{

  }, []);

  const keyPress = (e)=> {
    if(e.currentTarget.value && e.currentTarget.value.length>2) {
      axiosInstanceES.get(`/autosuggest?term=${e.currentTarget.value}`).then(res=>{
        setIsAddressSelected(false);
        setFractions(res.data);
      });
    }
    setTerm(e.currentTarget.value); 
  }

  const gotoCommunity = (city,state, cmmdId)=>{
    navigate(`/community/en/${state}/${city}/${cmmdId}/`)
 }
 const gotoHouse = (city,state, publicId)=>{
  navigate(`/home/en/${state}/${city}/${publicId}/`)
}

  const handleAddressSelect = async (locObj, title) => {
    //refine(address);
    setIsAddressSelected(true);
    console.log("click", locObj, title);
    try {
      if(title === 'Places'){
        refine(locObj);
      }else if(title ==='Communities'){
        gotoCommunity(locObj.city, locObj.state, locObj.communityId)
      }else if(title =='Models'){
        gotoHouse(locObj.city, locObj.state, locObj.publicId)
      }
      //const response = await axiosInstanceES.get(`/autosuggest?term=Roseville`);
      // Handle nearby data and update the UI as needed
    } catch (error) {
      console.error('Error fetching nearby data:', error);
    }
  };
  const DisplayBlock = (title, items)=>{
    const itemsConst = items?(items.slice(0, items.length>5?5:items.length)):[];
    return (<>
      <Tag
          key={'block_'+title}
          bg="white"
          color="black"
          pt={5} pb={5}
          style={{borderTop:"1px solid #d9e2e8"}}
        ><TagLabel fontWeight={'800'}>{title}</TagLabel>
        </Tag>
        {(itemsConst||[]).map((loc, inx) => (
          <Tag
            key={inx+loc.name}
            onClick={() => handleAddressSelect(loc, title)}
            cursor="pointer"
            bg="white"
            color="black"
            pt={5} pb={5} pl={10}
            style={{borderTop:"1px solid #d9e2e8"}}
            _hover={{ backgroundColor: '#e6ebef' }}
          >
            <TagLabel>{loc.name}</TagLabel>
          </Tag> ))
        }
      </>
    )}

  return (
    <Box position={'relative'}>
      <Input
        type="search"
        value={term}
        onChange={keyPress}
        placeholder="Search by City, State, Community and Model..."
        className="filter-autocomplete-address-search"
      />
      {/* Display autocomplete suggestions only when current refinement value is not empty and no address is selected */}
      {!isAddressSelected && term && fractions.locations && (
        <Stack spacing={0} mt={0} position={'absolute'} width={"100%"} zIndex={2} boxShadow={"md"}>
          {fractions?.locations&& DisplayBlock("Places",fractions?.locations)}
          {fractions?.communities&& DisplayBlock("Communities",fractions?.communities)}
          {fractions?.modelnames&& DisplayBlock("Models",fractions?.modelnames)} 
        </Stack>
      )}
    </Box>
  );
};

export default CustomAutocomplete;
