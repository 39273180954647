
function BuyerGetStarted() {
  return (
    <div className="font-linksans">
         <iframe
      id="JotFormIFrame-242835516346157"
      title="Prod-Individual-Buyers-1"
      onload="window.parent.scrollTo(0,0)"
      allowtransparency="true"
      allow="geolocation; microphone; camera; fullscreen"
      src="https://form.jotform.com/242835516346157"
      frameborder="0"
      style={{"minWidth":"100%", maxWidth: "100%", "height":"800px", "border":"none"}}
      scrolling="no"
    >
    </iframe>

    </div>
  )
}

export default BuyerGetStarted