import React, { createContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import { useProducts } from '../components/compare/ProductsProvider';

const PageViewsContext = createContext();

export const usePageViews = () => React.useContext(PageViewsContext);

export const PageViewsProvider = ({ children }) => {
  const [pageViews, setPageViews] = useState(() => {
    const storedPageViews = Cookies.get('pageViews');
    return storedPageViews ? JSON.parse(storedPageViews) : [];
  });
  const [redirectedToSignIn, setRedirectedToSignIn] = useState(false);
  const [pageViewsSaved, setPageViewsSaved] = useState(false);
  const [searchCount, setSearchCount] = useState(() => {
    const storedSearchCount = Cookies.get('searchCount');
    return storedSearchCount ? parseInt(storedSearchCount) : 0;
  });
  const [searchState, setSearchState] = useState();
  const [searchStateData, setSearchStateData] = useState('');
  const [isSignInOverlayVisible, setIsSignInOverlayVisible] = useState(false);
  const [promptShown, setPromptShown] = useState(false);
  const [ipAddress, setIpAddress] = useState('');
  const [geoLocation, setGeoLocation] = useState('');
  const [sessionId, setSessionId] = useState('');
  const [firstTimeSaved, setFirstTimeSaved] = useState(localStorage.getItem('firstTimeSaved') ? localStorage.getItem('firstTimeSaved') : false);
  const location = useLocation();
  const navigate = useNavigate();
  const searchViewCounter = parseInt(process.env.REACT_APP_SEARCH_FILTER_COUNTER);
  const pageViewCounter = parseInt(process.env.REACT_APP_PAGE_VIEW_COUNTER);
  const batchViewCounter = parseInt(process.env.REACT_APP_BATCH_COUNTER);
  const [selectedProducts, setSelectedProducts] = useState(() => {
    const storedCompareProducts = Cookies.get('selectedProducts');
    
    return storedCompareProducts ? JSON.parse(storedCompareProducts) : [];
  });

  const [selectedLovedProducts, setSelectedLovedProducts] = useState(() => {
    const storedLovedProducts = Cookies.get('lovedProducts');
    return storedLovedProducts ? JSON.parse(storedLovedProducts) : [];
  });

  const checkLoggedIn = () => {
    return !!localStorage.getItem('userid');
  };

  const convertDataToObject = (data) => {
    const refinedData = {};
    if (data && data.refinementList) {
      for (const key in data.refinementList) {
        refinedData[key.split('.').pop()] = data.refinementList[key];
      }
    }
    return refinedData;
  };



  const savePageViewsToDB = async () => {
    try {

      // Fetch existing search criteria from the database
      const userId = localStorage.getItem('userid');
      const searchCriteriaResponse = await axios.get(`${process.env.REACT_APP_DYNAMO_API_URL}/users/${userId}/user_actions`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      // Extract the existing search criteria from the response
      const existingSearchCriteria = searchCriteriaResponse.data?.search_criteria;

      // Combine existing search criteria with new data
      const updatedSearchCriteria = {

        page_views: parseInt(pageViews.length) + searchCount,
        ip: ipAddress,
        session_id: sessionId,
        geo_location: geoLocation,
        search_criteria: existingSearchCriteria + JSON.stringify(convertDataToObject(searchStateData))
      };

      // Insert the combined data into the database
      const response = await axios.post(`${process.env.REACT_APP_DYNAMO_API_URL}/users/${userId}/user_actions`, updatedSearchCriteria, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });

      setPageViews([]);
      setSearchCount(0);
      setSearchStateData("");
      setPageViewsSaved(true);
    } catch (error) {
      console.error('Error saving page views:', error);
    }
  };

  const savePageViewsToDBAfterFIrstLogin = async (pageViewsData, searchData) => {
    try {
      // Fetch existing search criteria from the database
      const userId = localStorage.getItem('userid');
      const searchCriteriaResponse = await axios.get(`${process.env.REACT_APP_DYNAMO_API_URL}/users/${userId}/user_actions`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      // Extract the existing search criteria from the response
      const existingSearchCriteria = searchCriteriaResponse.data?.search_criteria;

      // Combine existing search criteria with new data
      const updatedSearchCriteria = {

        page_views: parseInt(pageViewsData.length) + searchCount,
        ip: ipAddress,
        session_id: sessionId,
        geo_location: geoLocation,
        search_criteria: existingSearchCriteria + searchData
      };


      // Insert the combined data into the database
      const response = await axios.post(`${process.env.REACT_APP_DYNAMO_API_URL}/users/${userId}user_actions`, updatedSearchCriteria, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });

      setPageViewsSaved(true);
    } catch (error) {
      console.error('Error saving page views:', error);
    }
  };


  const handlePageVisit = async () => {
    try {
      const userId = localStorage.getItem('userid');
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      const ipAddress = data.ip;
      setIpAddress(ipAddress);
      let geoLocation = null;
      try {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });
        const { latitude, longitude } = position.coords;
        geoLocation = `${longitude},${latitude}`;
        setGeoLocation(geoLocation)
      } catch (error) {
        console.error('Error getting geolocation:', error);
      }

      const randomString = Math.random().toString(36).slice(2, 14);
      const sessionId = `${randomString.substring(0, 8)}-${randomString.substring(8)}`;
      setSessionId(sessionId);
      const newVisit = {
        page: location.pathname,
        userId,
        ipAddress,
        sessionId,
        geoLocation,
        searchCount,
        searchState: searchStateData,
        timestamp: new Date().toISOString()
      };



      setPageViews((prevPageViews) => [...prevPageViews, newVisit]);
    } catch (error) {
      console.error('Error retrieving IP address:', error);
    }
  };

  useEffect(() => {
    handlePageVisit();
  }, [location]);

  useEffect(() => {
    if (!localStorage.getItem('firstTimeSaved') && checkLoggedIn()) {
      handleUserLogin();
    }
  }, [checkLoggedIn(), firstTimeSaved])


  const savePageViewsToDBInBatches = async () => {
    const maxpageviews = pageViewCounter;
    if (checkLoggedIn()) {
      await savePageViewsToDB();
      // const batches = batchViewCounter;

      // for (let i = 0; i < batches; i++) {
      //   const startIndex = Math.max(0, pageViews.length - (i + 1) * (maxpageviews / batches));
      //   const endIndex = Math.max(0, pageViews.length - i * (maxpageviews / batches));

      //   const batch = pageViews.slice(startIndex, endIndex);
      //   await savePageViewsToDB(batch);
      // }
      setPageViewsSaved(true);
    }
  }

  useEffect(() => {
    if (((pageViews.length >= pageViewCounter || searchCount >= searchViewCounter) && !promptShown) && !checkLoggedIn()) {
      //setIsSignInOverlayVisible(true);
      //navigate('/login');
     // setPromptShown(true);
     console.log("page viewer reached limit");
    }

    if (checkLoggedIn() && (((pageViews.length + searchCount) === batchViewCounter))) {
      savePageViewsToDBInBatches();
    }

    if (!checkLoggedIn()) {
      Cookies.set('pageViews', JSON.stringify(pageViews), { expires: 365, sameSite: 'None', secure: true });
      Cookies.set('searchCount', searchCount, { expires: 365, sameSite: 'None', secure: true });
      Cookies.set('searchStateData', JSON.stringify(convertDataToObject(searchStateData)), { expires: 365, sameSite: 'None', secure: true })
      // Cookies.set('selectedProducts', selectedCompareProducts, { expires: 365, sameSite: 'None', secure: true });
    }
  }, [pageViews, redirectedToSignIn, checkLoggedIn, promptShown, navigate, searchCount, searchStateData, selectedProducts]);

  const handleUserLogin = async () => {
    const storedPageViews = Cookies.get('pageViews');
    const storedSearchCount = Cookies.get('searchCount');
    const storedSearchData = Cookies.get('searchStateData');
    const storedCompareProducts = Cookies.get('selectedProducts');
    const storedLovedProducts = Cookies.get('lovedProducts');
    setPageViews([]);
    setSearchCount(0);
    setSearchStateData("");
    if (checkLoggedIn() && (storedPageViews || storedSearchCount)) {
      await savePageViewsToDBAfterFIrstLogin(JSON.parse(storedPageViews), storedSearchData);
      Cookies.remove('pageViews');
      Cookies.remove('searchCount');
      Cookies.remove('searchStateData')
    }
    if (checkLoggedIn() && (storedCompareProducts || storedLovedProducts)) {
    }
    localStorage.setItem('firstTimeSaved', true)
    setFirstTimeSaved(true);
    setPromptShown(false);
  };
  const trackSearchEvent = () => {
    setSearchCount((prevCount) => prevCount + 1);
  };

  // useEffect(() => {
  //   Cookies.set('searchCount', searchCount, { expires: 365 });
  // }, [searchCount]);

  return (
    <PageViewsContext.Provider value={{ pageViews, searchCount, trackSearchEvent, setSearchStateData, searchStateData }}>
      {children}
      {!checkLoggedIn() && isSignInOverlayVisible && <div className="overlaysignin" />}
    </PageViewsContext.Provider>
  );
};

export default PageViewsProvider;
