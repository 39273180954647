import React from 'react';
import { GoogleMapsLoader, GeoSearch, Marker } from 'react-instantsearch-dom-maps';
import { InstantSearch, Hits, SearchBox, RefinementList, NumericMenu,Configure, Pagination, RangeInput, MenuSelect, Menu, SortBy } from 'react-instantsearch-dom';
import Client from '@searchkit/instantsearch-client';
import Searchkit from 'searchkit';
import { Box, Flex, Text } from '@chakra-ui/react';
import SquareBoxRatingMenu from './SquareBoxRatingMenu';


const apiKey = '';//'AIzaSyAPSJE7wwwvLb4BkLPx3d5xPMof1wo4xrM';
const endpoint = '';// 'https://maps.googleapis.com/maps/api/js?v=weekly';

const searchkitClient = new Searchkit({
  connection: {
    host: 'http://localhost:9200'
  },
  search_settings: {
    result_attributes: ["data.house_name", "data.mlsListingPrice", "data.house_type", "data.address", "data.floatprice", "data.propertyType","data.bedrooms","data.bathrooms"],
    search_attributes: ["data.house_name","data.address"],
    "filter_attributes": [
      // { attribute: 'bathrooms', field: 'bathrooms', type: 'numeric' },
    ],
    facet_attributes: [
      { field: 'data.house_type.keyword', attribute: 'data.house_type', type: 'string' },
      { field: 'data.propertyType.keyword', attribute: 'data.propertyType', type: 'string' },
      { field: 'data.mlsListingPrice', attribute: 'data.mlsListingPrice', type: 'numeric' },
      { field: 'data.bedrooms', attribute: 'data.bedrooms', type: 'string', qualitative: true }, // Add bedrooms attribute
      { field: 'data.bathrooms', attribute: 'data.bathrooms', type: 'numeric' }, 
      { field: 'data.communityname.keyword', attribute: 'data.communityname', type: 'string' },
      { field: 'data.buildername.keyword', attribute: 'data.buildername', type: 'string' },
    ],
    "sortBy": [
      { "label": "Price (Low to High)", "value": "localdb11_price_asc", "field": "data.mlsListingPrice", "order": "asc" },
      { "label": "Price (High to Low)", "value": "localdb11_price_desc", "field": "data.mlsListingPrice", "order": "desc" }
    ],
    geo_attribute: "location",
    pagination: {
      size: 10
    }
  }
});

const searchClient = Client(searchkitClient);

export default function Web() {

  const itemsData = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 }
    // Add more items as needed
  ];

  return (
    <div className="ais-InstantSearch bg-gray-100 h-screen p-4">
    <InstantSearch indexName="localdb11" searchClient={searchClient}>
      <Configure hitsPerPage={10} />
      <Flex className="search-3block-sec" wrap={'wrap'}>
      <Box className='filter-sec' width={{base:"100%",md:"25%"}} p={2}>
        <Box mt={4}>
          <Text fontSize="lg">Search By Address</Text>
          <SearchBox />
          </Box>
          <Box mt={4} width="100%">
          <SortBy
            defaultRefinement="localdb11_price_asc" // Default sort order
            items={[
              { label: 'Relevance', value: '' },
              { label: 'Price (Low to High)', value: 'localdb11_price_asc' },
              { label: 'Price (High to Low)', value: 'localdb11_price_desc' }
            ]}
          />
          </Box>
          <Box mt={4}>
            <Text fontSize="lg">Filter by House Type</Text>
            <RefinementList attribute="data.house_type" searchable={true} showMore={true}  limit={5}  />
          </Box>
          <Box mt={4}>
            <Text fontSize="lg">Filter by Property Type</Text>
            <RefinementList attribute="data.propertyType" showMore={true}  limit={5} />
          </Box>
          <Box mt={4}>
            <Text fontSize="lg">Filter by Price</Text>
            <RangeInput attribute="data.mlsListingPrice" header="Range Input" />
          </Box>
          <Box mt={4}>
            <Text fontSize="lg">Filter by Community</Text>
            <MenuSelect attribute="data.communityname" searchable={true}/>
          </Box>
          <Box mt={4}>
            <Text fontSize="lg">Filter by Builder</Text>
            <RefinementList attribute="data.buildername" searchable={true} showMore={true}  limit={5}/>
          </Box>
          <Box mt={4}>
            <Text fontSize="lg">Filter by Bedrooms</Text>
            <SquareBoxRatingMenu attribute="data.bedrooms" items={itemsData}/> 
          </Box>
          <Box mt={4}>
            <Text fontSize="lg">Filter by Bathrooms</Text>
            <SquareBoxRatingMenu attribute="data.bathrooms" items={itemsData}/>
          </Box>
       </Box> 

       <Box className='search-list-sec' width={{base:"100%",md:"35%"}} pr={'5'} pl={'5'} >
       <Hits hitComponent={HitItem} />
            <Pagination />
       </Box>
        <Box  width={{base:"100%",md:"40%"}} style={{ position: 'relative', overflow: 'hidden' }}>
          <GoogleMapsLoader apiKey={apiKey} endpoint={endpoint}>
            {(google) => (
              <GeoSearch google={google} zoom={10} fullscreenControl={true} streetViewControl={true} zoomControl={true} mapTypeControl={true} mapTypeControlOptions={{ mapTypeIds: ['roadmap', 'satellite'] }}>
                {({ hits }) => (
                  <>
                    {hits.map((hit) => (
                      <Marker key={hit.objectID} hit={hit} />
                    ))}
                  </>
                )}
              </GeoSearch>
            )}
          </GoogleMapsLoader>
        </Box>
      </Flex>
    </InstantSearch>
    </div>
  );
}

function HitItem({ hit }) {
  const {
    data: {
      house_name,
      address,
      price,
      bedrooms,
      bathrooms,
      floatprice,
      mlsListingPrice,
      house_type,
      propertyType,
      property_type,
      buildername,
      communityname,
    },
  } = hit;

  return (
    <Box p={2} border="1px solid #ccc" borderRadius={4} mb={2}>
      <Text fontSize="lg">{house_name}</Text>
      <br />
      <Text fontSize="md">{price}</Text>
      <br />
      <Text fontSize="md">{house_type}</Text>
      <br />
      <Text fontSize="md">{propertyType}</Text>
      <br />
      <Text fontSize="lg">{address}</Text>
      <br />
      <Text fontSize="lg">{bedrooms} Beds</Text>
      <br />
      <Text fontSize="lg">{bathrooms} Baths</Text>
    </Box>
  );
}