import React, { useState } from 'react';
import { Box, Heading, Text, Divider, Switch, Stack, Container } from '@chakra-ui/react';

const CookieSettingsPage = () => {
  const [essentialCookies, setEssentialCookies] = useState(true);  // Essential cookies can't be turned off
  const [performanceCookies, setPerformanceCookies] = useState(false);

  return (
    <div className="bg-primary  font-linksans">
      <div className="container px-10 py-12">
        <div className="flex-col items-center flex justify-center">
      <Heading as="h1" size="xl" mb={5}>
        Cookie Settings
      </Heading>

      <Text fontSize="sm" mb={5}>
        Last Updated on September 30, 2024
      </Text>

      <Text mb={5}>
        We use cookies to enhance your experience and ensure the proper functioning of our website. You can choose which cookies you allow us to use, except for the essential cookies necessary for the operation of the site. Learn more about our cookie policy in our{' '}
        <Text as="span" color="blue.500" cursor="pointer">
          Privacy Policy
        </Text>.
      </Text>

      <Divider my={5} />

      <Heading as="h2" size="lg" mb={3}>
        Manage Cookie Preferences
      </Heading>

      <Stack spacing={4} mb={5}>
        {/* Essential Cookies */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Text fontWeight="bold">Essential Cookies</Text>
          <Switch isChecked={essentialCookies} isDisabled />
        </Box>
        <Text mb={2}>
          These cookies are necessary for the website to function and cannot be disabled in our system. They are usually only set in response to actions made by you, such as logging in or filling out forms.
        </Text>

        <Divider />

        {/* Performance Cookies */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Text fontWeight="bold">Performance Cookies</Text>
          <Switch isChecked={performanceCookies} onChange={() => setPerformanceCookies(!performanceCookies)} />
        </Box>
        <Text mb={2}> These cookies allow us to set the performancecokkies</Text>
        </Stack>
        </div>
     </div>
     </div>
  ); 
};

export default CookieSettingsPage;
