import React, { useState } from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import axios from 'axios';

const Autocomplete = ({ refine }) => {
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const handleInputChange = async (event) => {
    const inputValue = event.currentTarget.value;
    setQuery(inputValue);

    
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/autocomplete?term=${inputValue}`);
      setSuggestions(response.data);
    } catch (error) {
      console.error('Error fetching autocomplete suggestions:', error);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    refine(suggestion); 
    setQuery(suggestion); 
    setSuggestions([]); 
  };

  return (
    <div>
      <input
        type="text"
        value={query}
        onChange={handleInputChange}
        placeholder="Search by address..."
      />
      {suggestions.length > 0 && (
        <ul>
          {suggestions.map((suggestion, index) => (
            <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
              {suggestion}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};


const ConnectedAutocomplete = connectSearchBox(Autocomplete);

export default ConnectedAutocomplete;
