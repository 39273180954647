import React, { useState, useEffect, useMemo, useCallback,useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './style.css';
import { Container, Heading, Button, useDisclosure, Autocomplete, useToast } from '@chakra-ui/react';
import ImageUploadModal from './ImageUploadModal';
import ActionCellRenderer from './ActionCellRenderer';
import UploadButtonCellRenderer from './UploadButtonCellRenderer';
import AutocompleteCellEditor from './AutocompleteCellEditor';
import { propertyTypeOptions, mlsStatusOptions } from '../../constants';
import debounce from 'lodash/debounce';
import Swal from 'sweetalert2'; 
import HorizontalScrollbar from './HorizontalScrollbar';
import { ModuleRegistry } from "@ag-grid-community/core";
import { useParams,useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; 
import axiosInstance, {axiosInstanceES} from '../../utils/axio-instance';
import axios from 'axios';

const getServerSideDatasource = (server) => {
    return {
        getRows: (params) => {
          if (!server) {
            params.api.hideOverlay();
            console.warn('Server URL is null or undefined. Returning empty rows.');
            params.success({
              rowData: [],
              rowCount: 0,
            });
            return;
          }
            const { startRow, endRow,filterModel } = params.request;
            const pageSize = endRow - startRow;
            const filterParams = new URLSearchParams();
            Object.keys(filterModel).forEach(key => {
              const filterValue = filterModel[key].filter;
              
              // Check if the key matches a label in propertyTypeOptions
              const propertyTypeOption = propertyTypeOptions.find(option => option.label.includes(filterValue));
              if (propertyTypeOption) {
                  filterParams.append(`filter[${key}]`, propertyTypeOption.value);
              } else {
                  // If it doesn't match, use the key as it is
                  filterParams.append(`filter[${key}]`, filterValue);
              }
          });
            const queryString = `${server}?start=${startRow}&size=${pageSize}&${filterParams.toString()}`;

            axiosInstanceES.get(queryString)
                  .then(response => {
                      const data = response.data;
                      if (data && data.rows && data.totalCount !== undefined) {
                          params.success({
                              rowData: data.rows,
                              rowCount: data.totalCount,
                          });
                      } else {
                          throw new Error('Data format is incorrect');
                      }
                  })
                .catch(error => {
                    console.error('Error fetching data:', error);
                    params.failCallback();
                });
        }
    };
  
};


const PropertyListing = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  //console.log("profileInfo",(profileInfo.role && profileInfo.role.length > 0) ? profileInfo.role.join(', ') : null);
  const [rowData, setRowData] = useState([]);
  const [editRowId, setEditRowId] = useState(null);
  const [newRow, setNewRow] = useState(null);
  const [newRowId, setNewRowId] = useState(null);
  const [propertyId, setPropertyId] = useState(null);
  const [communityOptions, setCommunityOptions] = useState([]);
  const [builderOptions, setBuilderOptions] = useState([]);
  const [communityMapping, setCommunityMapping] = useState({});
  const gridRef = useRef(null);
  const gridRef1 = useRef(null);
  const [newRows, setNewRows] = useState([]); 
  const gridApiRef = useRef(null);
  const [gridOption, setGridOption] = useState(null); 
  const storedUserInfo = localStorage.getItem('userid');
  const roleInfo = localStorage.getItem('roles');

  let location = useLocation();
  const { t} = useTranslation();
  const toast = useToast();
  const segments = location.pathname.split('/').filter(segment => segment);
  const lastSegment = segments[segments.length - 2];
  const [addressData, setAddressData] = useState({
    street: '',
    city: '',
    state: '',
    zip: '',
    fullAddress: '',
    latitude: 0,
    longitude: 0,
  });
 const [lan,setLan] = useState();
 const [lon,setLon] = useState();
  useEffect(() => {
    const fetchCoordinates = async () => {
      const address = `${addressData.street || ''},${addressData.city || ''}, ${addressData.state || ''} ${addressData.zip || ''}`.trim();
      if (address !== ", , ") {
        const { lat, lng } = await fetchLatLong(address);
        setAddressData((prev) => ({
          ...prev,
          fullAddress: address,
          latitude: lat || 0,
          longitude: lng || 0,
        }));
      }
    };
  
    if (addressData.street && addressData.city && addressData.state && addressData.zip) {
      fetchCoordinates();
    }
  }, [addressData.street, addressData.city, addressData.state, addressData.zip]);
  

  const handleAddressChange = (field, value) => {
    setAddressData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const onCellValueChanged = (params) => {
    const field = params.colDef.field;
    const value = params.newValue;

    handleAddressChange(field, value);
  };

  const fetchLatLong = async (address) => {
    try {
      const apiKey = process.env.REACT_APP_GOOGLE_MAP_KEY;
      const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`);
      const data = response.data;

      if (data.status === "OK" && data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry.location;
        console.log("enterif",  data.results[0].geometry.location);
        return { lat: Number(lat), lng: Number(lng) };
      } else {
        console.warn("No results found for the address:", address);
        return { lat: null, lng: null };  // Return null if no results are found
      }
    } catch (error) {
      console.error('Error fetching latitude and longitude:', error);
      return { lat: null, lng: null };
    }
  };

  useEffect(() => {
    const pathsToCheck = ["properties", "allproperties"];
    if (pathsToCheck.some(path => location.pathname.includes(path))) {
    fetchProperties();
    fetchCommunities();
    }
  }, [roleInfo, storedUserInfo, location.pathname]);

  const fetchProperties = async () => {
    try {
      const isAdmin =roleInfo && (roleInfo.includes('admin') || roleInfo.includes('staff'));
      let propertiesData;

      if (isAdmin) {
        const response = await axiosInstanceES.get(`/listproperties`);
        propertiesData = response.data || [];
      } else {
        const userAuthorization = storedUserInfo; // Adjust if you need to handle `userAuthorization` differently
        const response = await axiosInstance.get(`/users/${userAuthorization}/properties`);
        const userProperties = response.data || [];
        //console.log("userPropertiesaa",userProperties);

        if (userProperties.length > 0) {
          const ids = userProperties.map(p => p).join(',');
          //console.log("ids2222", ids);
          const esResponse = await axiosInstanceES.get(`/userpropertiesdata?ids=${ids}`);
          propertiesData = esResponse.data || [];
        } else {
          propertiesData = [];
        }
      }

      setRowData(propertiesData);
    } catch (error) {
      console.error('Error fetching properties data:', error);
    }
  };

  const fetchCommunities = async () => {
    try {
      const response = await axiosInstanceES.get(`/listcommunities`);
      const communities = response.data || [];
      const uniqueCommunityNames = [...new Set(communities.map(c => c.communityName))];
      const uniqueBuilderNames = [...new Set(communities.map(c => c.builderName))];
      setCommunityOptions(uniqueCommunityNames);
      setBuilderOptions(uniqueBuilderNames);

      const mapping = {};
      communities.forEach(community => {
        mapping[community.communityName] = {
          id: community.communityId,
          publicId: community.communityPublicID
        };
      });
      setCommunityMapping(mapping);
    } catch (error) {
      console.error('Error fetching community data:', error);
    }
  };
  

  const handleCellValueChanged = (event) => {
    const updatedRowData = rowData.map(row => {
      if (row.id === event.data.id) {
        const updatedRow = { ...row, [event.colDef.field]: event.newValue };

        if (event.colDef.field === 'communityName') {
          const communityData = communityMapping[event.newValue];
          if (communityData) {
            updatedRow.communityId = communityData.id;
            updatedRow.communityPublicID = communityData.publicId;
          }
        }

        return updatedRow;
      }
      return row;
    });
    setRowData(updatedRowData);
  };

  const handleUpload = (files) => {
    console.log('Uploaded files:', files);
  };

  const openUploadModal = (id) => {
    setPropertyId(id);
    onOpen();
  };

  const onEditClick = (id) => {
    setEditRowId(id);
  };

  const onCancelClick = () => {
   
    //  const newRowId = getNewRow(); // Retrieve the stored new row id
      if (gridRef1.current && gridRef1.current.api) {
      
      if (newRowId) {
          const transaction = {
              remove: [{ id: newRowId }]
          };
  
          gridRef1.current.api.applyServerSideTransaction(transaction);
  
          // Clear the stored new row id
          setNewRow(null);
          setNewRowId(null);
      }
    }
      setEditRowId(null);
    //  setNewRow(null);
    };

  const transformData = async (frontendData) => {
    let lat = null;
    let lng = null;
    const fullAddress = `${frontendData.street|| ''}, ${frontendData.city || ''}, ${frontendData.state || ''}, ${frontendData.zip || ''}`;
    if (fullAddress !== ", , , ") {
      try {
        // Wait for the fetchLatLong function to resolve
        const locationData = await fetchLatLong(fullAddress);
        lat = locationData?.lat;
        lng = locationData?.lng;
      } catch (error) {
        console.error("Error fetching lat/lng:", error);
      }
   
    }
    return {
      location:lat && lng 
      ? `${lat},${lng}` 
      : null, 
      main: {
        sourceId: frontendData.builderName || '',
        publicId: frontendData.id,
        communityPublicID: frontendData.communityPublicID,
        propertyStatus: "active",
        recordCreatedDate: new Date().toISOString(),
        createdRole: roleInfo,
        createdByUser: storedUserInfo,
        recordType: 'property',
      },
      data: {
        propertyInfo: {
          address: {
            label: frontendData.address,
            street: frontendData.street,
            city: frontendData.city,
            state: frontendData.state,
            zip: frontendData.zip
          },
          bedrooms: parseInt(frontendData.bedrooms) || null,
          bathrooms: parseInt(frontendData.bathrooms) || null,
          buildingSquareFeet: parseInt(frontendData.sqft) || null,
          roomsCount: parseInt(frontendData.totalrooms) || null,
          livingSquareFeet: frontendData.sqft || '', 
          stories: parseInt(frontendData.stories) || null,
          partialBathrooms: parseInt(frontendData.partial_bathrooms) || null,
        },
        mlsListingPrice: parseInt(frontendData.price) || null,
        lotInfo: {
          lotSquareFeet: parseInt(frontendData.lot_area) || null,
          legalDescription: frontendData.property_description || null
        },
        propertyType: frontendData.property_type,
      
      },
       extraData: {
        modelName: frontendData.modelName || null,
        modelConstructionStatus: "Ready to Build",
        numberOfGarageSpaces: 1
      },
      builder : {
        builderName: frontendData.builderName || ''
      },
      community: {
        communityId:  frontendData.communityId || null,
        communityName: frontendData.communityName || ''
      }
    };
  
  };

  const onUpdateClick = async (updatedRow) => {
    //console.log(updatedRow,"updatedRow");
    try {
      const transformedData = transformData(updatedRow);
      if(transformData!=null){
      await axiosInstanceES.put(`/updatepropertie/${updatedRow.id}`, transformedData);
      setEditRowId(null);
      toast({
        title: t('property-updatedmessage'),
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'bottom-left',
      });
    }
    } catch (error) {
      console.error('Error updating row:', error);
      toast({
        title: 'Error',
        description: t('updatefailed'),
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom-left',
      });
    }
  };


  // const onDeleteClick = async (id) => {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "Once deleted, you will not be able to recover this property!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonText: "Yes, delete it!",
  //     cancelButtonText: "No, keep it",
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       try {
  //         const isNewRow = newRows.includes(id); // Assuming newRow stores the ID of the new row being edited
  
  //         if (isNewRow) {
  //           setRowData(rowData.filter(row => row.id !== id));
  //           setNewRows(newRows.filter(rowId => rowId !== id));
  //           toast({
  //             title: t('property-removemessage'),
  //             status: 'success',
  //             duration: 3000,
  //             isClosable: true,
  //             position: 'bottom-left',
  //           });
  //         } else {
  //           await axiosInstanceES.delete(`/deleteproperty/${id}`);
  //           setRowData(rowData.filter(row => row.id !== id));
  //           toast({
  //             title: t('property-deletemessage'),
  //             status: 'success',
  //             duration: 3000,
  //             isClosable: true,
  //             position: 'bottom-left',
  //           });
  //         }
  //       } catch (error) {
  //         console.error('Error deleting row:', error);
  //       }
  //     }
  //   });
  // };
  

  const onDeleteClick = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this property!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const isNewRow = newRows.includes(id);
  
          // For new rows (not yet saved in the backend), remove them directly
          if (isNewRow) {
            const updatedRowData = rowData.filter(row => row.id !== id);
            setRowData(updatedRowData);
            setNewRows(newRows.filter(rowId => rowId !== id));
  
            if (gridApi) {
              const transaction = {
                remove: [{ id }],
              };
              gridRef1?.current?.api?.applyServerSideTransaction(transaction);
            }
  
            toast({
              title: t('property-removemessage'),
              status: 'success',
              duration: 3000,
              isClosable: true,
              position: 'bottom-left',
            });
  
          } else {
            // For existing rows, delete via API call
            await axiosInstanceES.delete(`/deleteproperty/${id}`);

            await axiosInstanceES.delete(`/properties/photos/${id}`);
  
            // Remove the row from the local rowData state after successful deletion
            const updatedRowData = rowData.filter(row => row.id !== id);
            setRowData(updatedRowData);
  
            // Apply transaction to remove row from AG Grid
            if (gridApi) {
              const transaction = {
                remove: [{ id }],
              };
              gridRef1?.current?.api?.applyServerSideTransaction(transaction);
            }
  
            toast({
              title: t('property-deletemessage'),
              status: 'success',
              duration: 3000,
              isClosable: true,
              position: 'bottom-left',
            });
          }
        } catch (error) {
          console.error('Error deleting row:', error);
          toast({
            title: 'Error',
            description: t('updatefailed'),
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'bottom-left',
          });
        }
      }
    });
  };

  const createRow = async() => {
    const response = await axiosInstanceES.get(`/latestpropertyid`);
    const data = response.data;
  const latestId =  data.latestPropertyId || 0; // Handle default case

//         // Generate a new ID
         const newId = latestId + 1;
    const emptyRow = {
        id: newId,
        communityId: '',
        communityPublicID: '',
        modelName : '',
        address: '',
        street: '',
        city: '',
        state: '',
        zip: '',
        mlsStatus: '',
        property_type: '',
        price: '',
        bedrooms: '',
        bathrooms: '',
        partial_bathrooms: '',
        sqft: '',
        no_of_stories: '',
        photos: '',
        property_description: '',
        lot_area: '',
        built_year: '',
        daysonthemarket: '',
        pool: '',
        totalrooms: ''
    };
    setRowData([emptyRow, ...rowData]);
    setNewRow(emptyRow.id);
    setNewRowId(emptyRow.id);
    setNewRows([...newRows, emptyRow.id]);
    return emptyRow;

  };

  const addNewRow =  useCallback(async () => {
   const newRow = await createRow();
    const transaction = {
      addIndex: 0,
      add: [newRow],
    };
    const result = gridRef1.current.api.applyServerSideTransaction(transaction);
  }, [createRow, newRow]);


  const validateNewRow = async (newRow) => {
    const requiredFields = ['communityName', 'builderName','modelName', 'street', 'city', 'state', 'zip', 'property_type', 'price', 'bedrooms', 'bathrooms', 'partial_bathrooms', 'totalrooms', 'sqft', 'stories'];
    const numericFields = ['bedrooms', 'bathrooms', 'partial_bathrooms', 'totalrooms', 'stories', 'price', 'zip'];
    const errors = [];
    const numericErrors = [];
  
    // Fetch existing addresses from the backend
    let existingAddresses = [];
    try {
      const response = await axiosInstanceES.get(`/addresslist`);
      existingAddresses = response.data;
    } catch (error) {
      console.error('Error fetching existing addresses:', error);
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'Unable to validate addresses. Please try again later.'
      });
      return false;
    }
  
    const isDuplicateAddress = (newRow) => {
      // Check if the new row's street1 matches any existing address's street1
      return existingAddresses.some(
        (address) =>
          address.street=== newRow.street
      );
    };
  
    requiredFields.forEach(field => {
      if (!newRow[field]) {
        errors.push(field);
      }
    });
  
    numericFields.forEach(field => {
      if (newRow[field] !== undefined && newRow[field] !== null && isNaN(newRow[field])) {
        numericErrors.push(field);
      }
    });
  
    // Check for duplicate addresses
    if (isDuplicateAddress(newRow)) {
      errors.push('street already exists');
    }
  
    if (errors.length > 0 || numericErrors.length > 0) {
      let errorMessage = '';
  
      if (errors.length > 0) {
        // Check for combined address fields
        if (errors.includes('street already exists')) {
          errorMessage += `The address with street '${newRow.street}' already exists. `;
          errors.splice(errors.indexOf('street'), 1);
        }
        errorMessage += `Please fill in the following required fields: ${errors.join(', ')}. `;
      }
  
      if (numericErrors.length > 0) {
        errorMessage += `The following fields should only contain numeric values: ${numericErrors.join(', ')}.`;
      }
  
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: errorMessage.trim()
      });
  
      return false;
    }
  
    return true;
  };

  const saveNewRow = async (newRow) => {

    const isValid = await validateNewRow(newRow);
    if (!isValid) {
      return; // Stop the save operation if validation fails
    }

    try {
      const transformedData =   await transformData(newRow);
      
      const storedUserInfo = localStorage.getItem('userid');
  
      // Prepare payload with user information and properties
      const payload = {
        role : roleInfo,
        properties: [transformedData.main.publicId] 
      };

      //console.log("payload data",payload);

      const dynamoApiUrl = `/users/${storedUserInfo}/properties`;
      const elasticsearchApiUrl = `/addproperty`;
  
      // Save to DynamoDB
      const dynamoResponse = await axiosInstance.post(dynamoApiUrl, payload);
  
      // Save to Elasticsearch
      const elasticsearchResponse = await axiosInstanceES.post(elasticsearchApiUrl, transformedData);
  
      // Check if both requests were successful
      if (dynamoResponse.status === 200 && elasticsearchResponse.status === 200) {
        setNewRow(null);
        toast({
          title: t('property-addedmessage'),
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      } else {
        throw new Error('One of the requests failed');
      }
    } catch (error) {
      console.error('Error saving new row:', error);
      toast({
        title: 'Error',
        description : "There was an error adding the property.",
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'bottom-left',
      });
    }
  };

  const [gridApi, setGridApi] = useState(null);

  const onGridReady = useCallback(async (params) => {
    try {
      setGridApi(params.api);
      let apiUrl;
      const isAdminOrStaff = roleInfo && (roleInfo.includes('admin') || roleInfo.includes('staff'));
  
      if (isAdminOrStaff) {
        apiUrl = `/listpropertieswithpagination`;
      } else {
        const userAuthorization = storedUserInfo; // Adjust if you need to handle `userAuthorization` differently
        const response = await axiosInstance.get(`/users/${userAuthorization}/properties`);
  
        const userProperties = response.data || [];
        if (userProperties.length > 0) {
          const ids = userProperties.map(p => p).join(',');
          apiUrl = `/userpropertiesdatapagination?ids=${ids}`;
        } else {
          apiUrl = null; // Handle this case if no properties are found
        }
      }
      
        const datasource = getServerSideDatasource(apiUrl);
        params.api.setGridOption('serverSideDatasource', datasource);
    } catch (error) {
      console.error('Error setting up the grid data source:', error);
    }
  }, [roleInfo, storedUserInfo]);
  
  const colDefs = useMemo(() => [
    {
      headerName: 'Actions',
      field: 'actions',
      cellRenderer: ActionCellRenderer,
      editable: false,
      colId: 'actions',
      pinned: 'left',
      resizable: false,
      suppressMovable: true,
    },
    { headerName: 'Property Id', field: 'id', editable: false, sortable: true, filter: 'agTextColumnFilter', sort: 'desc', hide: true},
    { headerName: 'Community Public ID', field: 'communityPublicID', editable: false, sortable: true, filter: 'agTextColumnFilter', hide: true },
    { headerName: 'Community ID', field: 'communityId', editable: false, sortable: true, filter: 'agTextColumnFilter', hide: true},
    {
      field: 'communityName',
      headerName: 'Community Name',
      editable: editRowId !== null || newRow !== null,
      sortable: true,
      filter: 'agTextColumnFilter',
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: { 
        values: communityOptions,
        allowTyping: true,
      filterList: true,
      highlightMatch: true,
      valueListMaxHeight: 220
      },
      valueSetter: function (params) {
        const selectedCommunityName = params.newValue;
        const communityData = communityMapping[selectedCommunityName];
  
        if (communityData) {
          params.data.communityName=selectedCommunityName;
          params.data.communityId = communityData.id;
          params.data.communityPublicID = communityData.publicId;
          return true; 
        }
  
        return false; 
      }
    },
    { headerName: 'Builder Name', field: 'builderName',  editable: editRowId !== null || newRow !== null, sortable: true, filter: 'agTextColumnFilter',
     cellEditor: 'agRichSelectCellEditor', 
     cellEditorParams: { 
      values: builderOptions,
      allowTyping: true,
      filterList: true,
      highlightMatch: true,
      valueListMaxHeight: 220 } },
    { headerName: 'Model Name', field: 'modelName', editable: editRowId !== null || newRow !== null, sortable: true,filter: 'agTextColumnFilter'},
    
    {
      headerName: 'Property Type',
      field: 'property_type',
      editable: editRowId !== null || newRow !== null,
      sortable: true,
      filter: 'agTextColumnFilter',
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: propertyTypeOptions.map(option => option.label), 
      },
      valueFormatter: (params) => {
        const selectedOption = propertyTypeOptions.find(option => option.value === params.value);
        return selectedOption ? selectedOption.label : params.value;
      },
      onCellValueChanged: (params) => {
        const selectedOption = propertyTypeOptions.find(option => option.label === params.newValue);
        if (selectedOption) {
          handleCellValueChanged({ ...params, newValue: selectedOption.value });
        }
      },
    },
    { headerName: 'Price', field: 'price', editable: editRowId !== null || newRow !== null, sortable: true, filter: 'agTextColumnFilter' },
    { headerName: 'No of Bedrooms', field: 'bedrooms', editable: editRowId !== null || newRow !== null, sortable: true,filter: 'agTextColumnFilter'},
    { headerName: 'No of Bathrooms', field: 'bathrooms', editable: editRowId !== null || newRow !== null, sortable: true,filter: 'agTextColumnFilter'},
    { headerName: 'Partial Bathrooms', field: 'partial_bathrooms', editable: editRowId !== null || newRow !== null, sortable: true,filter: 'agTextColumnFilter' },
    { headerName: 'Total Rooms', field: 'totalrooms', editable: editRowId !== null || newRow !== null, sortable: true, filter: 'agTextColumnFilter' },
    { headerName: 'Living SQFT', field: 'sqft', editable: editRowId !== null || newRow !== null, sortable: true, filter: 'agTextColumnFilter'},
    { headerName: 'No of Stories', field: 'stories', editable: editRowId !== null || newRow !== null, sortable: true, filter: 'agTextColumnFilter'},
    { headerName: 'Photos', field: 'photos', cellRenderer: UploadButtonCellRenderer },
    { headerName: 'Description', field: 'property_description', editable: editRowId !== null || newRow !== null, sortable: true,filter: 'agTextColumnFilter'},
    { headerName: 'Street', field: 'street', editable: editRowId !== null || newRow !== null, sortable: true, filter: 'agTextColumnFilter' },
    { headerName: 'City', field: 'city', editable: editRowId !== null || newRow !== null, sortable: true, filter: 'agTextColumnFilter' },
    { headerName: 'State', field: 'state', editable: editRowId !== null || newRow !== null, sortable: true, filter: 'agTextColumnFilter' },
    { headerName: 'Zip', field: 'zip', editable: editRowId !== null || newRow !== null, sortable: true, filter: 'agTextColumnFilter' },
    {
      headerName: 'Full Address',
      field: 'address',
      valueGetter: (params) => {
        const { street, city, state, zip } = params.data;
    
        const parts = [
          street && street !== 'N/A' ? street : '',
          city && city !== 'N/A' ? city : '',
          state && state !== 'N/A' ? state : '',
          zip && zip !== 'N/A' ? zip : ''
        ];
    
        return parts.filter(part => part).join(', ');
      },
      editable: false,
      sortable: true,
      filter: true
    },
 ], [editRowId, newRow, builderOptions,communityOptions,mlsStatusOptions,propertyTypeOptions]);

  const onCellEditingStopped = useCallback(debounce(async (event) => {
    if (!event.node || !event.data) return;

    const { data } = event.node;
    const fullAddress = `${data.street|| ''}, ${data.city || ''}, ${data.state || ''}, ${data.zip || ''}`;
   
    if (fullAddress !== ", , , ") {
    const { lat, lng } = await fetchLatLong(fullAddress);
    setLan(lat);
    setLon(lng);
    console.log("fullAddress:", fullAddress);
    console.log("Latitude/Longitude:", lat, lng);
    const updatedData  = rowData.map(row =>
      row.id === data.id ? { ...row, address: fullAddress, latitude: lat, longitude: lng } : row
    );
    setRowData(updatedData);

    //const transformedData = transformData(updatedData.find(row => row.id === data.id));
      // console.log("Transformed Data:", transformedData);
  } else {
    console.warn("Address is incomplete, skipping latitude/longitude fetch.");
  }
  }, 300), [rowData]);

  const defaultColDef = useMemo(() => ({
    flex: 1,
    minWidth: 150,
    editable: true,
    sortable: true,
    filter: true,
    resizable: true,
  }), []);

  const context = {
    editRowId,
    newRow,
    onEditClick,
    onCancelClick,
    onUpdateClick,
    onDeleteClick,
    openUploadModal,
    saveNewRow,
  };

  const getRowId = useCallback((params) => `${params.data.id}`, []);

  return (
    <div>
      <Container maxW="100%">
        <Button onClick={addNewRow} bg="white" mb={4}>
          Add New Property
        </Button>
        {/* <Button colorScheme="teal" onClick={onBtExport} mb={4} ml={4}>Export to CSV</Button> */}
        <HorizontalScrollbar gridRef={gridRef1} />
        <div className="ag-theme-alpine" style={{ height: 'auto', width: '100%' }}>
          <AgGridReact
            ref={gridRef1}
            rowData={rowData}
            columnDefs={colDefs}
            defaultColDef={defaultColDef}
            animateRows={true}
            domLayout="autoHeight"
            enableSorting={true}
            enableFilter={true}
            pagination={true}
            paginationPageSize={100}
            enableCellEditing={true}
            rowSelection="multiple"
            rowModelType={"serverSide"}
            onGridReady={onGridReady}
            serverSideDatasource={gridOption}
            frameworkComponents={{
              ActionCellRenderer,
              UploadButtonCellRenderer,
              AutocompleteCellEditor
            }}
            context={context}
            onCellValueChanged={handleCellValueChanged}
            onCellEditingStopped={onCellEditingStopped}
            getRowId={getRowId}
          />
        </div>
        <ImageUploadModal isOpen={isOpen} onClose={onClose} propertyId={propertyId} />
      </Container>
    </div>
  );
};

export default PropertyListing;
