import React, { useState, useMemo, useEffect } from 'react';
import { AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { Box, Button, useToast } from '@chakra-ui/react';
import Swal from 'sweetalert2';
import AgentActionCellRenderer from './AgentActionCellRenderer';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; 
import axiosInstance, {axiosInstanceES} from '../../utils/axio-instance';




const MyAssignments = () => {
  const [rowData, setRowData] = useState([]);
  const [editRowId, setEditRowId] = useState(null);
  const [propertyOptions, setPropertyOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [agentOptions, setAgentOptions] = useState([]);
  const localtion = useLocation();
  const { t} = useTranslation();
  const toast = useToast();

  // Retrieve token from local storage
  const token = localStorage.getItem('refreshToken');

  useEffect(() => {
    if(localtion.pathname.indexOf("referrals")>0){
    fetchInitialData();
    }
  }, [token, localtion.pathname]);

  const fetchInitialData = async () => {
    try {
      const response = await axiosInstance.get(`/staff/assignments/staff`);
      if (response.data.result && Array.isArray(response.data.data)) {
        setRowData(response.data.data.map(item => ({
          id: item.internalStaffId,
          buyerEmailId: item.internalStaffId,
          agentEmailId: item.agentId,
          propertyId: item.propertyId,
        })));
      } else {
        console.error('Unexpected response format:', response.data);
        setRowData([]);
      }
    } catch (error) {
      console.error('Error fetching initial data:', error);
      toast({
        title: 'Error',
        description: t('failedinitialdata'),
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom-left',
      });
    }
  };

  const validateRow = (data) => {
    return data.buyerEmailId && data.agentEmailId && data.propertyId;
  };

  const onCellValueChanged = (event) => {
    // Handle cell value change if needed
  };

  const onEditClick = (id) => {
    setEditRowId(id);
  };

  const onCancelClick = () => {
    setEditRowId(null);
  };

  const onUpdateClick = async (row) => {
    if (validateRow(row)) {
      try {
        // Find the old data for comparison
        const oldRow = rowData.find(data => data.id === row.id);
  
        // Prepare the request payload with old and new data
        const requestData = {
          internalStaffId: oldRow.buyerEmailId,
          agentId: oldRow.agentEmailId,
          propertyId: oldRow.propertyId,
          newInternalStaffId: row.buyerEmailId,
          newAgentId: row.agentEmailId,
          newPropertyId: row.propertyId
        };
  
        const response = await axiosInstance.post(`/staff/update/agent`, requestData);
  
        if (response.status === 200) {
          const updatedRowData = rowData.map(data => data.id === row.id ? row : data);
          setRowData(updatedRowData);
          toast({
            title: t('assignment-successmessage'),
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });
          setEditRowId(null);
        } else {
          toast({
            title: 'Error',
            description: t('assignment-failuremessage'),
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'bottom-left',
          });
        }
      } catch (error) {
        console.error('Error updating row:', error);
        toast({
          title: 'Error',
          description: t('assignment-failuremessage'),
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'bottom-left',
        });
      }
    } else {
      Swal.fire('Validation Error', 'Please fill in all required fields.', 'error');
    }
  };
  

  const saveNewRow = async (row) => {
  };

  const addNewRow = () => {
    const newId = rowData.length > 0 ? Math.max(...rowData.map(row => Number(row.id))) + 1 : 1;
    const newRowData = {
      id: newId,
      buyerEmailId: '',
      agentEmailId: '',
      propertyId: '',
    };

    setRowData([...rowData, newRowData]);
    setEditRowId(newId);
  };

  const onDeleteClick = async (id) => {
  };
  
  const colDefs = useMemo(() => [
    // {
    //   headerName: 'Actions',
    //   field: 'actions',
    //   cellRenderer: AgentActionCellRenderer,
    //   editable: false,
    //   pinned: 'left',
    //   resizable: false,
    //   suppressMovable: true,
    // },
    {
      headerName: 'Buyer/Seller Email',
      field: 'buyerEmailId',
      editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: userOptions.map(option => option.value.toString())
      },
      valueFormatter: (params) => {
        const option = userOptions.find(option => option.value.toString() === params.value);
        return option ? option.label : params.value;
      }
    },
    {
      headerName: 'Agent Email',
      field: 'agentEmailId',
      editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: agentOptions.map(option => option.value.toString())
      },
      valueFormatter: (params) => {
        const option = agentOptions.find(option => option.value.toString() === params.value);
        return option ? option.label : params.value;
      }
    },
    {
      headerName: 'Property',
      field: 'propertyId',
      editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: propertyOptions.map(option => option.id.toString())
      },
      // valueFormatter: (params) => {
      //   const option = propertyOptions.find(option => option.id.toString() === params.value);
      //   return option ? option.address : params.value;
      // }
    },
    {
      headerName: 'Status',
      field: 'status', // This field will always be empty
      editable: false, // Make it non-editable
      valueFormatter: () => '', // Ensure it stays empty by always returning an empty string
    }
  ], [propertyOptions, userOptions, agentOptions,editRowId]);

  const context = {
    editRowId,
    onEditClick,
    onCancelClick,
    onUpdateClick,
    onDeleteClick,
    saveNewRow,
  };

  const defaultColDef = useMemo(() => ({
    flex: 1,
    minWidth: 150,
    editable: true,
    sortable: true,
    filter: true,
    resizable: true,
  }), []);

  return (
    <div className="bg-primary  font-linksans">
            <div className="container px-5 py-12">
    <Box>
      {/* <Button onClick={addNewRow} colorScheme="blue" mb={4}>
        Add New Assignment
      </Button> */}
      <div className="ag-theme-alpine" style={{ height: '500px', width: '100%' }}>
        <AgGridReact
          rowData={rowData}
          columnDefs={colDefs}
          defaultColDef={defaultColDef}
          context={context}
          animateRows={true}
          rowSelection="multiple"
          suppressRowClickSelection={true}
          pagination={true}
          onCellValueChanged={onCellValueChanged}
          frameworkComponents={{ AgentActionCellRenderer }}
        />
      </div>
    </Box>
    </div>
    </div>
  );
};

export default MyAssignments;
