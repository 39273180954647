import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
// import "instantsearch.css/themes/algolia.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <I18nextProvider i18n={i18n}>
    <App />
    </I18nextProvider>
    </Router>
);