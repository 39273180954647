import React, { useState, useEffect } from 'react';
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box, Container,ChakraProvider } from '@chakra-ui/react';
import PropertyListing from './PropertyListing';
import CommunityListing from './CommunityListing';

const MainListing = () => {

  return (
            <div className="bg-primary font-linksans min-h-svh">
            <div className="container px-5 py-12">
           <Box>
              <Tabs isFitted variant="enclosed">
                <TabList>
                <Tab _selected={{ color: 'black', bg: 'white', fontWeight: 'normal', fontsize:'lg' }}>Communities</Tab>
                <Tab _selected={{ color: 'black', bg: 'white', fontWeight: 'normal', fontsize:'lg' }}>Properties</Tab>
                  {/* <Tab>Communities</Tab>
                  <Tab>Properties</Tab> */}
                </TabList>
                <TabPanels>
                  <TabPanel>
                   {/* <PropertyListingPagination/>  */}
                    <CommunityListing/>
                  </TabPanel>
                  <TabPanel>
                    <PropertyListing/>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
            </div>
            </div>
  );
};

export default MainListing;
