import { Link } from "react-router-dom"

function ErrorPage() {
    return (
      <div className="container mx-auto 2xl:py-14 py-10 md:py-12 min-h-svh">
        <h2 className="text-textColor font-bold 2xl:text-4xl xl:text-4xl lg:text-3xl md:Text-2xl text-2xl">Oops!</h2>
        <h3 className="2xl:text-2xl xl:text-2xl lg:text-2xl md:text-lg text-lg  font-semibold pb-6">We cannot seems found the page you are looking for</h3>
        <h4>Return back to <Link className="hover:underline" to="/">Home</Link> | <Link className="hover:underline" to="/login">Login</Link></h4>
      </div>
    )
  }
  
  export default ErrorPage