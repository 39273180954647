import React, { useState, useEffect, useRef} from 'react';
import { InstantSearch, SearchBox, Hits,RefinementList, Pagination, Stats, connectSearchBox, NumericMenu, RangeInput, CurrentRefinements, QueryRuleCustomData, Snippet, SortBy, Configure, HierarchicalMenu,MultiSelect } from 'react-instantsearch-dom';
import {Box, Button,useColorModeValue, Text, Icon, Flex, Divider, Select,Container, CloseButton, Heading,Stack,HStack,Tooltip,Spacer,useDisclosure,Badge} from "@chakra-ui/react";
import Client from '@searchkit/instantsearch-client';
import Searchkit from "searchkit";
import { mode } from '@chakra-ui/theme-tools';
import { FiHeart, FiImage, FiMapPin, FiEye, FiShare } from 'react-icons/fi'; 
import { FaExchangeAlt, FaHome, FaCalendar, FaUsers,FaObjectGroup  } from 'react-icons/fa';
import SquareBoxRatingMenu from '../../components/search/SquareBoxRatingMenu';
import { Link, useNavigate } from 'react-router-dom';
import { useProducts } from '../compare/ProductsProvider';
import qs from 'qs';
import mapping from './mapping';
import CustomAutocomplete from './CustomAutocomplete';
import GPlacesAutoComplete from './GPlacesAutoComplete';
import {
  GoogleMapsLoader,
  GeoSearch,
  Marker,
} from 'react-instantsearch-dom-maps';

import { usePageViews } from '../../tracking/PageViewProvider';

import Popoverlogin from '../../components/Popover';
import ModalComponent from '../ModalComponent';
import ShareWithFriendsModal from '../user/ShareWithFriendsModal';
import ShareGroupNames from '../user/ShareGroupNames';
import PhotoModal from '../user/PhotoModal';
import { propTypeLabels} from '../../constants';
import GroupMenu from '../user/GroupMenu';
import CommonButton from "../../components/compare/CommonButton";
import axiosInstance, {axiosInstanceES} from '../../utils/axio-instance';
import PriceDisplay from "../../pages/DetailsPage/PriceDisplay";



const CustomStats = () => (
  <Stats
  translations={{
    stats: (n, ms, nSortedHits, areHitsSorted) =>
      n >= 10000
        ? `10,000+ results found in ${ms} ms`
        : `${n} results found in ${ms} ms`
  }}
/>
);

const searchkitClient = new Searchkit({
  connection: {
    host: process.env.REACT_APP_ELASTIC_PROXY_URL,
  },
  search_settings: {
    highlight_attributes: [],
    search_attributes: [],
    result_attributes: ['data.id', 'data.mlsListingPrice', 'data.propertyInfo.address.label', 
      'data.mlsHistory.type', 'data.propertyInfo.bedrooms', 'data.propertyInfo.bathrooms', 
      'data.propertyInfo.buildingSquareFeet', 'data.propertyInfo.latitude', 
      'data.propertyInfo.longitude', ' data.lotInfo.lotSquareFeet', 'data.propertyType', 
      'data.propertyInfo.yearBuilt', 'data.mlsStatus', 'data.propertyInfo.address.county', 
      'data.propertyInfo.address.city', 'data.lastSale.sellerNames', 'data.propertyInfo.buildername', 
      'data.mlsActive', 'data.mlsCancelled', 'data.mlsFailed', 'main.publicId', 'data.propertyInfo.address.state', 
      'community.communityName', 'builder.builderName', 'main.communityPublicID', 'extraData.modelName'],
    facet_attributes: [
      { field: 'data.propertyType', attribute: 'data.propertyType', type: 'string'},
      { field: 'data.mlsListingPrice', attribute: 'data.mlsListingPrice', type: 'numeric' },
      { field: 'data.propertyInfo.bedrooms', attribute: 'data.propertyInfo.bedrooms', type: 'numeric', qualitative: true }, // Add bedrooms attribute
      { field: 'data.propertyInfo.bathrooms', attribute: 'data.propertyInfo.bathrooms', type: 'numeric' }, 
      { field: 'data.propertyInfo.address.county', attribute: 'data.propertyInfo.address.county', type: 'string'},
      { field: 'data.propertyInfo.address.city', attribute: 'data.propertyInfo.address.city', type: 'string'},
      { field: 'data.lastSale.sellerNames', attribute: 'data.lastSale.sellerNames', type: 'string'},
      { field: 'builder.builderName', attribute: 'builder.builderName', type: 'string'},
      { field: 'data.mlsActive', attribute: 'data.mlsActive', type: 'string'},
      { field: 'data.mlsCancelled', attribute: 'data.mlsCancelled', type: 'string'},
      { field: 'data.mlsFailed', attribute: 'data.mlsFailed', type: 'string'},
      { attribute: 'data.mlsStatus', field: 'data.mlsStatus', type: 'string' },
      { field: 'community.communityName', attribute: 'community.communityName', type: 'string'},
      
      
      
    ],
    sorting: {
      default: {
        field: '_score',
        order: 'asc'
      },
      price_asc: {
        field: 'data.mlsListingPrice',
        order: 'asc'
      },
      price_desc: {
        field: 'data.mlsListingPrice',
        order: 'desc'
      },
      sqft_asc: {
        field: 'data.propertyInfo.buildingSquareFeet',
        order: 'asc'
      },
      sqft_desc: {
        field: 'data.propertyInfo.buildingSquareFeet',
        order: 'desc'
      }
    },
    geo_attribute: 'location',
    debug: true
  }
})


const searchClient = Client(searchkitClient);

const handleMarkerClick = (props) => {  
  if (props.hit.data.propertyInfo.address.label) {
    window.open(`https://www.google.com/maps/place/${encodeURIComponent(props.hit.data.propertyInfo.address.label)}`, '_blank');
  }
};

  
const searchStateToUrl = (searchState) => {
  const { query, page, boundingBox, refinementList } = searchState;
  let queryString = `?query=${query}&page=${page}&boundingBox=${boundingBox || ''}`;
  if (refinementList) {
    // Iterate over each refinement list
    Object.keys(refinementList).forEach((attribute) => {
      const refinementValue = refinementList[attribute];
      if (Array.isArray(refinementValue) && refinementValue.length > 0) {
        // If the refinement value is an array and not empty
        const mappedAttribute = mapping.result_attributes.find(item => item.field === attribute);
        if (mappedAttribute) {
          const fieldName = mappedAttribute.name;
          const refinedValues = refinementValue.map(value => mapping.result_attributes.find(item => item.field === fieldName && item.name === value)?.field || value);
          queryString += `&${fieldName}=${encodeURIComponent(refinedValues.join(','))}`;
        }
      } else if (typeof refinementValue === 'string' && refinementValue.trim() !== '') {
        // If the refinement value is a string and not empty
        const mappedAttribute = mapping.result_attributes.find(item => item.attribute === attribute);
        if (mappedAttribute) {
          const fieldName = mappedAttribute.name;
          const mappedValue = mapping.result_attributes.find(item => item.field === fieldName && item.name === refinementValue)?.field || refinementValue;
          queryString += `&${attribute}=${encodeURIComponent(mappedValue)}`;
        }
      }
    });
  }

  return queryString;
}



const HitView = (props) => {

  const { data, main = {}, community = {}, builder = {}, extraData={}} = props.hit;
  const {communityName} = community;
  const {
    propertyInfo = {},
    lotInfo = {},
    mlsListingPrice = 'N/A',
    mlsStatus = 'N/A',
    propertyType = 'N/A',
  } = data;

  const {
    address = {},
    bedrooms = 'N/A',
    bathrooms = 'N/A',
    buildingSquareFeet = 'N/A',
    yearBuilt = 'N/A',
  } = propertyInfo;

  
  const { lotSquareFeet = 'N/A' } = lotInfo;
  const publicId = main.publicId || 'N/A'; 
  const communityId = main.communityPublicID;
  const city = propertyInfo.address.city;
  const state = propertyInfo.address.state;

  const [isPhotoOpen, setIsPhotoOpen] = useState(false);
 

  const { selectedProducts,addProductToSelection, removeProductFromSelection, lovedProducts, addProductToLovedlist,removeProductFromLovedlist,viewProducts,addViewProductToSelection} = useProducts();
  const isProductSelected = selectedProducts.includes(publicId);

  const isLoveitSelected = lovedProducts.includes(publicId);
  const isviewSelected = viewProducts &&  viewProducts.includes(publicId);

  const navigate = useNavigate();

  const {isOpen, onOpen, onClose,onToggle } = useDisclosure();
  const [modalHeader, setModalHeader] = useState('');
  const [modalBody, setModalBody] = useState(null);

  const [userId, setUserId] = useState(null);

  useEffect(() => {
    // Check if user ID exists in local storage
    const storedUserId = localStorage.getItem('userid');
    if (storedUserId) {
      setUserId(storedUserId);
    }
  }, []);

  
  let storedLanguage = localStorage.getItem('i18nextLng');

  if (!storedLanguage || storedLanguage.trim() === '') {
      storedLanguage = 'en';
  }
  
  const handleCompare = productId =>{
    if (isProductSelected) {
      removeProductFromSelection(productId);
    } else {
      addProductToSelection(productId);
    }
   }

   const handleLoveList = productId =>{
    if (isLoveitSelected) {
      removeProductFromLovedlist(productId);
    } else {
      addProductToLovedlist(productId);
    }
   }

   const handleViewList = productId =>{
    if(isviewSelected){
     addViewProductToSelection(productId);
    } else{
     addViewProductToSelection(productId);
     }

     let url;
      if (productId && productId !== 'N/A') {
         url = `/home/${storedLanguage}/${state}/${city}/${productId}/${encodeURIComponent(address.label)}/${props.hit.community?.communityName ? encodeURIComponent(props.hit.community.communityName) : ''}/${props.hit.data.propertyType ? encodeURIComponent(props.hit.data.propertyType) : ''}/`;
      } else {
         url =`/community/${storedLanguage}/${state}/${city}/${communityId}`;
      }
      window.open(url, '_blank');
  }

  const handleOpenModal = () => {
    setModalHeader('Invite & Share with Friends');
    setModalBody(<ShareWithFriendsModal isOpen={true} onClose={onClose}/>);
    onOpen();
  };

  const handleShareWithFriend = () => {
    setModalBody(<div><Popoverlogin onClose={handleClose}/></div>);
    onOpen();
  };

  const handleClose = () => {
    onClose();
  };

  const handleCloseModal = () => {
    setModalHeader('');
    setModalBody(null);
    onClose();
  };

  const handleOpenPhotoModal = () => {
    setModalHeader('Property Images');
    setModalBody(<PhotoModal isOpen={true} onClose={onClose} propertyId={publicId}/>);
    onOpen();
  };

  return (
  <>
 <Stack direction="row" justifyContent="space-between" spacing={5} width="full"> 
    <Box pt={4} position="relative">
      <Box className="2xl:col-span-1 xl:col-span-1 2xl:order-1 xl:order-1 lg:order-1 order-2 lg:col-span-1 md:col-span-2 col-span-2">
        {/* Price and details */}
        <Box className="flex items-center gap-2 2xl:pb-3 xl:pb-3 lg:pb-3 md:pb-3 pb-2">
        {mlsListingPrice && mlsListingPrice !== 'N/A' ? (
         <>
          <Text className="2xl:text-3xl xl:text-3xl lg:text-2xl: md:text-2xl text-2xl font-bold text-paragraphColor">
             <PriceDisplay price={mlsListingPrice} />
          </Text>
          {extraData.modelName && <Text fontWeight="medium" fontSize="14px" padding={'10px 0px'}>
              {extraData.modelName}
            </Text>}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8 0C3.5813 0 0 3.5813 0 8C0 12.4187 3.5813 16 8 16C12.4187 16 16 12.4187 16 8C16 3.5813 12.4187 0 8 0ZM8 14.5455C4.3908 14.5455 1.4546 11.6092 1.4546 8C1.4546 4.3908 4.3908 1.4545 8 1.4545C11.6092 1.4545 14.5454 4.3909 14.5454 8C14.5454 11.6091 11.6092 14.5455 8 14.5455ZM7.102 7.3171H8.898V12.859H7.102V7.3171ZM7.102 3.7906H8.898V5.5866H7.102V3.7906Z"
              fill="#C1C4CC"
            />
          </svg> </> )
           : communityName && <Text fontWeight="medium" fontSize="14px" padding={'10px 0px'}>
                  {communityName}
                </Text>
                }
        </Box>
        {propertyType && propertyType !== 'N/A' &&
        <Text className="text-paragraphColor 2xl:text-base xl:text-base lg:text-base md:text-base text-sm pb-3 border-b border-dashed border-borderColor">
          {propTypeLabels[propertyType]}
        </Text>
         }

       {address.label && address.label !== 'N/A' &&
        <Text className="text-paragraphColor 2xl:text-base xl:text-base lg:text-base md:text-base text-sm pb-3 border-b border-dashed border-borderColor">
          {address.label}
        </Text>
         }

        {/* Property details */}
        {publicId && publicId !== 'N/A' &&
        <Box className='py-3 flex items-center flex-wrap gap-1.5 border-b border-dashed'>
        {bedrooms && bedrooms !== 'N/A' &&
          <CommonButton icon="bed" value={bedrooms} name="Bedrooms" isLink={false} color="green" />
        }
         {bathrooms && bathrooms !== 'N/A' &&
          <CommonButton icon="bath" value={bathrooms} name="Bathrooms" isLink={false} color="blue" />
         }
          {buildingSquareFeet && buildingSquareFeet !== 'N/A' && 
         <CommonButton icon="length" value={buildingSquareFeet} name="Sq Ft" isLink={false} color="purple" />
          }

         </Box>
         }

        { publicId && publicId !== 'N/A' && communityName && communityName !== 'N/A' && 
        <Box className='py-3 border-b border-dashed'>
          <CommonButton icon="property" value={null} name={communityName} isLink={false} color="pink" />
        </Box>
         }

       {(userId && publicId && publicId != "N/A" ) && 
        <Box className='py-3 border-b border-dashed'>
        <ShareGroupNames propertyId={publicId}/>
        </Box>
        } 
      </Box>
    </Box>
    <Box pt={4} position="relative">
        {/* Action buttons */}
        {publicId && publicId !== 'N/A' &&
        <Box className="flex flex-wrap  gap-1.5 py-3 border-b border-dashed">
        {userId && publicId && publicId !== 'N/A' ? (
          <>
            <GroupMenu propertyId={publicId} />
          </>
        ) : (
          publicId && publicId !== 'N/A' && (
            <CommonButton
              icon="compare"
              name={isProductSelected ? "Remove" : "Shortlist"}
              color={isProductSelected ? "red" : "blue"}
              isLink={false} // if it's a clickable button and not a link
              onClick={() => handleCompare(props.hit.main.publicId)}
            />
          )
        )}

         <CommonButton icon="photo" name="Photos" onClick={handleOpenPhotoModal} color="gray" />
         <CommonButton icon="map" name="View Map" onClick={() => handleMarkerClick(props)} color="gray" />
    
         {isOpen && (
            <ModalComponent isOpen={isOpen} onClose={handleCloseModal} modalHeader={modalHeader} modalBody={modalBody} />
          )} 
         </Box>
        }
            

        {/* View Details Button */}
        <Box mt={4}>
            <Button onClick={() => handleViewList(publicId)} className="text-textColor text-sm md:text-base lg:text-base xl:text-base 2xl:text-base  font-semibold 2xl:py-4 xl:py-4 lg:py-4 md:py-3 py-2.5 bg-primary rounded-xl w-full">
              View Details
            </Button>
        </Box>
      </Box>
   
  </Stack>

  </>
  )
}

const apiKey = process.env.REACT_APP_GOOGLE_MAP_KEY;
const endpoint = process.env.REACT_APP_GOOGLE_MAP_ENDPOINT;

export default function Web() {
  const updateAfter =400;
  const itemsData = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 }
  ];
  const { trackSearchEvent, searchCount } = usePageViews();

  const urlToSearchState = (urlParams) => {
    const { query, page, boundingBox,refinementList, ...rest } = urlParams;
    const searchState = {
      query: query || '',
      page: parseInt(page) || 1,
      boundingBox: boundingBox || null,
      refinementList: {}
    };
  
    Object.keys(rest).forEach((param) => {
    const decodedValue =decodeURIComponent(urlParams[param] || '');

      // Check if the parameter corresponds to a refinement list attribute
      const mappedAttribute = mapping.result_attributes.find(item => item.name === param);
      if (mappedAttribute) {
        const attributeName = mappedAttribute.field;
        const attributeValues = decodedValue.split(',');
        searchState.refinementList[attributeName] = attributeValues;
      } else {
        // If it's not a refinement list attribute, add it to the searchState directly
        searchState[param] = decodedValue;
      }
    });
  
    return searchState;
  }
  const [searchState, setSearchState] = useState(
    urlToSearchState(qs.parse(window.location.search.slice(1)))
  );

  const debouncedSetStateRef = useRef(null);

  useEffect(() => {
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const handlePopState = ({ state: searchState }) => {
    setSearchState(searchState);
  };

  const onSearchStateChange = (searchState) => {
    // update the URL when there is a new search state.
    clearTimeout(debouncedSetStateRef.current);
    debouncedSetStateRef.current = setTimeout(() => {
      window.history.pushState(
        searchState,
        null,
        searchStateToUrl(searchState)
      );
    }, updateAfter);

    setSearchState((previousState) => {
      const hasQueryChanged =
        previousState.query !== searchState.query;

      return {
        ...previousState,
        ...searchState,
        boundingBox: !hasQueryChanged ? searchState.boundingBox : null,
      };
    });
  };

  const parameters = {};
  if (!searchState.boundingBox) {
    parameters.aroundLatLngViaIP = true;
    parameters.aroundRadius = 'all';
  }

  const propertyTypeLabels = (items) => {
    console.log(items);
    return items.map((item) => ({
      ...item,
      label: (propTypeLabels[item.label]?propTypeLabels[item.label]:item.label)
    }));
  };
 
    return (
      <div className="ais-InstantSearch bg-primary font-linksans p-4">
        <Box>
            <text as="h2" fontWeight="bold" fontSize="md" color="#0056b3"></text>
        </Box>
  
        <InstantSearch
        indexName={process.env.REACT_APP_ELASTIC_INDEX_NAME}
        searchClient={searchClient}
        searchState={searchState}
        onSearchStateChange={(searchState) => {
          console.log('searchState',searchState)
          onSearchStateChange(searchState);
          trackSearchEvent(); // Call the function here
        }}
      >

    <Flex alignItems="center" flex={{ base: "none", md: 7}}>
        <Box flex={{ base: "none", md: 6 }}>
        <GPlacesAutoComplete/>
          </Box>

          <SortBy
              defaultRefinement="default"
            items={[
              { value: ``, label: 'Relevance' },
              { value: `price_desc`, label: 'Price (High to Low)' },
              { value: `price_asc`, label: 'Price (Low to High)' },
              { value: `sqft_desc`, label: 'SQ FT (High to Low)' },
              { value: `sqft_asc`, label: 'SQ FT (Low to High)' }
            ]}
          />

    </Flex>    
    <Flex className="search-3block-sec" wrap={'wrap'} width={'100%'}>
    <Box className='filter-sec 2xl:p-3 xl:p-3 lg:p-3 md:p-3 p-12 gap-6 mt-3 rounded-xl bg-white' width={{base:"100%",md:"25%"}} p={4}>
    <div className="left-panel">
        <Text
              fontSize={'16px'}
              color={useColorModeValue('black.500', 'black.300')}
              fontWeight={'600'}
              mb={'1'}>
            Search By Address
            </Text>
          <SearchBox  className="filter-search" />
          <Divider mt={'4'} borderColor={'#d9e2e8'}/>
          {/* <HierarchicalMenu attributes={['categories_lvl1', 'categories_lvl2']} /> */}
          <Text
              fontSize={'16px'}
              color={useColorModeValue('black.500', 'black.300')}
              fontWeight={'600'}
              mb={'1'} mt={'5'}>
            Search By House Type
            </Text>
            <RefinementList 
              className="filter-search search-input" 
              attribute="data.propertyType" 
              searchable={true}
              transformItems={propertyTypeLabels} 
            />
          <Divider mt={'4'} borderColor={'#d9e2e8'}/>
          <Text
              fontSize={'16px'}
              color={useColorModeValue('black.500', 'black.300')}
              fontWeight={'600'}
              mb={'1'} mt={'5'}>
            Search By Country
            </Text>
            <RefinementList 
              className="filter-search search-input" 
              attribute="data.propertyInfo.address.county" 
              searchable={true} 
              limit={5}
              showMore={true} 
            />
            <Divider mt={'4'} borderColor={'#d9e2e8'}/>
          <Text
              fontSize={'16px'}
              color={useColorModeValue('black.500', 'black.300')}
              fontWeight={'600'}
              mb={'1'} mt={'5'}>
            Search By City
            </Text>
            <RefinementList 
              className="filter-search search-input" 
              attribute="data.propertyInfo.address.city" 
              searchable={true} 
              limit={5}
              showMore={true} 
            />
          <Divider mt={'4'} borderColor={'#d9e2e8'}/>
          <Text
              fontSize={'16px'}
              color={useColorModeValue('black.500', 'black.300')}
              fontWeight={'600'}
              mb={'1'} mt={'5'}>
            Search By Community
            </Text>
            <RefinementList 
              className="filter-search search-input" 
              attribute="community.communityName"
              searchable={true}
              isMulti={true}
              />
           <Divider mt={'4'} borderColor={'#d9e2e8'}/>
          <Text
              fontSize={'16px'}
              color={useColorModeValue('black.500', 'black.300')}
              fontWeight={'600'}
              mb={'1'} mt={'5'}>
            Search By Builder
            </Text>
            <RefinementList 
              className="filter-search search-input" 
              attribute="builder.builderName" 
              searchable={true} 
              limit={5}
              showMore={true} 
            />    
          <Divider mt={'4'} borderColor={'#d9e2e8'}/>
          <Text
              fontSize={'16px'}
              color={useColorModeValue('black.500', 'black.300')}
              fontWeight={'600'}
              mb={'1'} mt={'5'}>
            Price
            </Text>
            <RangeInput attribute="data.mlsListingPrice" header="Range Input" />
          <Divider mt={'4'} borderColor={'#d9e2e8'}/>
          <Text
              fontSize={'16px'}
              color={useColorModeValue('black.500', 'black.300')}
              fontWeight={'600'}
              mb={'1'} mt={'5'}>
             Search By Bedrooms
            </Text>
            <SquareBoxRatingMenu attribute="data.propertyInfo.bedrooms" items={itemsData}/> 
          <Divider mt={'4'} borderColor={'#d9e2e8'}/>
          <Text
              fontSize={'16px'}
              color={useColorModeValue('black.500', 'black.300')}
              fontWeight={'600'}
              mb={'1'} mt={'5'}>
             Search By Bathrooms
            </Text>
            <SquareBoxRatingMenu attribute="data.propertyInfo.bathrooms" items={itemsData}/> 
            <Divider mt={'4'} borderColor={'#d9e2e8'}/>
        </div>
    </Box>
    <Box className='search-list-sec' width={{base:"100%",md:"75%"}} pr={'5'} pl={'5'}>
    <Text
      fontSize={'16px'}
      color={useColorModeValue('black.500', 'black.300')}
      fontWeight={'600'}
      mb={'1'} mt={'2'}>
      Properties
      </Text>
      <Divider mt={'2'} borderColor={'#d9e2e8'}/>
      <div className="flex-auto w-full py-2 px-4">
            <CustomStats />
          </div>
          <Divider mt={'2'} borderColor={'#d9e2e8'}/>    
       <Hits hitComponent={HitView}/>
       <Pagination />
    </Box>
    {/* <Box flex={{ base: "none", md: 3 }} style={{ position: 'relative', overflow: 'hidden' }}>
    <Text
      fontSize={'16px'}
      color={useColorModeValue('black.500', 'black.300')}
      fontWeight={'600'}
      mb={'1'} mt={'2'}>
      Map
      </Text>
      <Divider mt={'2'} borderColor={'#d9e2e8'}/> 
    <div className="flex-auto w-full py-2 px-4">
            <Stats />
            <CurrentRefinements />
          </div>
          <div style={{ height: 500, position: 'relative', overflow: 'hidden' }}>
          <GoogleMapsLoader apiKey={apiKey} endpoint={endpoint}>
          {(google) => (
            <GeoSearch google={google}>
              {({ hits }) => (
                <>
                  {hits.map((hit) => (
                    <Marker key={hit.objectID} hit={hit} />
                  ))}
                </>
              )}
            </GeoSearch>
          )}
        </GoogleMapsLoader>
        </div>
    </Box> */}
    </Flex>

      </InstantSearch>
      </div>
    );
}