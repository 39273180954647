import React, { useEffect, useRef, useState } from 'react';

const HorizontalScrollbar = ({ gridRef }) => {
  const scrollbarRef = useRef(null);
  const [scrollWidth, setScrollWidth] = useState(0);

  useEffect(() => {
    const updateScrollWidth = () => {
      const gridElement = gridRef?.current?.eGridDiv;
      if (gridElement) {
        setScrollWidth(gridElement.scrollWidth);
      }
    };

    updateScrollWidth();

    const handleResize = () => {
      updateScrollWidth();
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [gridRef]);

  useEffect(() => {
    const gridElement = gridRef?.current?.eGridDiv;

    if (gridElement && scrollbarRef.current) {
      const syncScroll = () => {
        scrollbarRef.current.scrollLeft = gridElement.scrollLeft;
      };

      gridElement.addEventListener('scroll', syncScroll);
      return () => {
        gridElement.removeEventListener('scroll', syncScroll);
      };
    }
  }, [gridRef]);

  const handleScroll = (e) => {
    const gridElement = gridRef?.current?.eGridDiv;
    if (gridElement) {
      gridElement.scrollLeft = e.target.scrollLeft;
    }
  };

  return (
    <div
      ref={scrollbarRef}
      onScroll={handleScroll}
      className="horizontal-scrollbar"
      style={{ width: `${scrollWidth}px` }}
    >
      <div className="scrollbar-content" style={{ width: '100%' }}></div>
    </div>
  );
};

export default HorizontalScrollbar;
