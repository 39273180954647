import { GiOnTarget, GiShakingHands, GiSmart, GiStrikingArrows, GiTeamIdea, GiTechnoHeart } from "react-icons/gi";

function Analyze() {
  return (
    <div id="why-choose-us" className="container mx-auto 2xl:py-10 xl:py-10 lg:py-10 md:py-10 py-8 px-12">
      <div className="flex flex-col justify-center items-center rounded-lg">
        <div>
        <h3 className="text-textColor font-semibold 2xl:text-4xl xl:text-3xl text-center lg:text-2xl md:text-2xl text-2xl pb-6">
          Why Choose Us? 
          </h3>
        </div>
        <div className="grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8">
          {/* item */}
          <div className="w-full flex flex-col">
          <div className="pb-8">
             <GiTeamIdea className="text-gray-700 w-8 h-8" />
            </div>
            <h4 className="text-textColor font-semibold pb-4">
            Collaborative home shortlists/picks for best decisions
            </h4>
            <p className="text-paragraphColor pb-4">
            Our Technology and model makes a big difference in buying beautiful new construction homes. Imagine that you can sit in your chair, invite your friends, family or colleagues and pick the best home through our innovative collaborative shortlists groups. 
            <br/>
            All can be done collaboratively and remotely within our platform. For the first time in the world, a collective decision is possible with our powerful collaborative shortlists and makes it easy to buy Beautiful New Home.
           </p>
          </div>
          {/* item */}
          <div className="w-full flex flex-col">
          <div className="pb-8">
             <GiOnTarget className="text-gray-700 w-8 h-8" />
            </div>
            <h4 className="text-textColor font-semibold pb-4">
            New Construction Homes focus at first
            </h4>
            <p className="text-paragraphColor pb-4">
            New homes rarely need repairs/renovations, lower maintenance costs, less hassle, better joy in the home and can be customizable as per your interests. Most new-construction homes have warranties, financial incentives, new modern models/floor plans and price holding.
           </p>
          </div>
          {/* item */}
          <div className="w-full flex flex-col">
          <div className="pb-8">
             <GiTechnoHeart className="text-gray-700 w-8 h-8" />
            </div>
            <h4 className="text-textColor font-semibold pb-4">
            Awesome Technology
            </h4>
            <p className="text-paragraphColor pb-4">
            We analyze tons of data and make it super easy for you and our agents to work as a team and make sure that you get the right home.
            </p>
          </div>

          <div className="w-full flex flex-col">
          <div className="pb-8">
             <GiStrikingArrows className="text-gray-700 w-8 h-8" />
            </div>
            <h4 className="text-textColor font-semibold pb-4">
            Outstanding Agents & Delivering Exceptional Results
            </h4>
            <p className="text-paragraphColor pb-4">
            For Individual Buyers, we match and help you find the perfect real estate agent based on local market data to help you achieve your goals. Right agent will tour the right homes, guide you through making an offer, negotiation, inspections, paperwork, and more.
            </p>
          </div>

          <div className="w-full flex flex-col">
          <div className="pb-8">
             <GiSmart className="text-gray-700 w-8 h-8" />
            </div>
            <h4 className="text-textColor font-semibold pb-4">
            Buying a house can be a less pain
            </h4>
            <p className="text-paragraphColor pb-4">
            We're making it less painful by matching with the perfect agents.  Our agents are expert negotiators, and will help you understand your options so you can make an informed decision.
           </p>
          </div>

          <div className="w-full flex flex-col">
          <div className="pb-8">
             <GiShakingHands className="text-gray-700 w-8 h-8" />
            </div>
            <h4 className="text-textColor font-semibold pb-4">
            Local Agents Expertise
            </h4>
            <p className="text-paragraphColor pb-4">
            We connect with local agents who have experience and skill in helping you. Local agents as experts with experience in your neighborhood, able to provide top service at competitive rates, getting you the best deal possible. Our goal is to make things easier for you to go through all options and make the right decision.
           </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Analyze;
