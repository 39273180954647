import Hero from "../../components/home/BuyerRentals/Hero";
import Analyze from "../../components/home/BuyerRentals/Analyze";
import ReadyToBuy from "../../components/home/BuyerRentals/ReadyToBuy";
import HomeV3Accordion3 from "../../components/home/BuyerRentals/HomeV3Accordion3";
import LetsTalk from "../../components/home/BuyerRentals/LetsTalk"
import Companies from '../../components/home/Companies'
import Benfits from "../../components/home/BuyerRentals/Benfits";
import StillWhy from "../../components/home/BuyerRentals/StillWhy";
import Whatweoffer from "../../components/home/BuyerRentals/Whatweoffer";

function BuyRentalsInGroup() {
  return (
    <div className="font-linksans">
        <Hero></Hero>
        <div className=" ">
          <ReadyToBuy></ReadyToBuy>
          <Whatweoffer></Whatweoffer>
          <Benfits></Benfits>
          <HomeV3Accordion3></HomeV3Accordion3>
          <Analyze></Analyze>
          <StillWhy></StillWhy>
          <LetsTalk></LetsTalk>
          <Companies />
        </div>
    </div>
  )
}

export default BuyRentalsInGroup;
