export const roles = [
    { value: "buyer", label: "Buyer" },
    { value: "selleragent", label: "Agent" },
    { value: "builder", label: "Builder" },
     { value: "builderagent", label: "Builder Team" },
     { value: "admin", label: "Admin" },
     { value: "staff", label: "InternalStaff" },
     { value : "buyergrouprental", label: "Buyer & Group Buyers"},
     { value : "grouprental", label: "Group Buyers"}
  ];

 export const userroles = ['buyer', 'seller', 'admin'];

 export const propTypeLabels = {
  "sfr":"Single Family Residence",
  "single-family homes":"Single-Family Residence",
  "townhome":"Town Home",
  "townhomes":"Town Homes",
  "condo":"Condo",
  "Condo":"CondoU",
  "duet":"Duet",
  "community":"Community",
  "land":"Land",
  "Land":"Land",
  "mfr":"Multi-Family Residence",
  'Multi-Family':"Multi-Family Residence",
  "mobile":"Mobile",
  "Co-op":"Co-Op",
  "other":"Other",
  "duplex":"Duplex",
  "villas":"Villas",
  "onelevelliving":"One Level Living",
  "nextgen":"Next Gen"
};


 export const propertyTypeOptions = [
    { value: 'sfr', label: 'Single Family Residency' },
    { value: 'townhome', label: 'Townhome' },
    { value: 'condo', label: 'Condo' },
    { value: 'duet', label: 'Duet' },
    { value: 'land', label: 'Land' },
    { value: 'mfr', label: 'Multi-Family' },
    { value: 'mobile', label: 'Mobile' },
    { value: 'coop', label: 'Co-op' },
    { value: 'other', label: 'Other' },
    { value: 'community', label: 'Community' },

  ];


 export const mlsStatusOptions = [
    { value: 'active', label: 'ACTIVE' },
    { value: 'pending', label: 'PENDING' },
    { value: 'cancelled', label: 'CANCELLED' },
    { value: 'activeundercontract', label: 'ACTIVE UNDER CONTRACT' },
    { value: 'closed', label: 'CLOSED' },
    { value: 'comingsoon', label: 'COMING SOON' },
    { value: 'expired', label: 'EXPIRED' },
    { value: 'temporaryoffmarket', label: 'TEMPORARILY OFF MARKET' },
    { value: 'withdrawn', label: 'WITHDRAWN' },
  ];


  export const languages = [
    { code: 'en', label: 'English' },
    { code: 'es', label: 'Spanish' },
  ];

  // Before Login home links

  export const HomeLinks = [
    { label: 'Rental Home Buyers', path: '/groupbuyers/home/' },
    { label: 'New Home Buyers', path: '/buyer/home/' },
    { label: 'Builders', path: '/builder/home/' },
    { label: 'Agents', path: '/agent/home/' },
    { label: 'Login', path: '/login' },
  ]

  //After login role based get Nav links and header link
  export const Links = {
    buyer: [
      { label: 'Profile', path: '/buyer/profile/' },
      { label: 'My Preferences', path: '/buyer/buyer-preferences/' },
      { label: 'Groups', path: '/groups/' },
    ],
    buyergrouprental: [
      { label: 'Profile', path: '/buyer/profile/' },
      { label: 'My Preferences', path: '/buyer/buyer-preferences/' },
      { label: 'Groups', path: '/groups/' },
  ],
  grouprental: [
      { label: 'Profile', path: '/groupbuyers/profile/' },
  ],
    buyeragent: [
      { label: 'My Dashboard', path: '/dashboard/' },
      { label: 'Profile', path: '/buyeragent/profile/' },
      { label: 'My Preferences', path: '/buyeragent/buyer-preferences/' },
      { label: 'Invite Friend', path: '/buyeragent/invites/' },
      { label: 'My Assignments', path: '/buyeragent/my-assignments/' },
    ],
    seller: [
      { label: 'My Dashboard', path: '/dashboard' },
      { label: 'Profile', path: '/seller/profile' },
      { label: 'My Properties', path: '/seller/properties' },
    ],
   selleragent:[
    { label: 'Profile', path: '/agent/profile/' },
    { label: 'My Properties', path: '/agent/properties/' },
    { label: 'My Preferences', path: '/agent/agent-preferences/' },
    { label: 'Licence Info', path: '/agent/agent-licence-info/' },
    { label: 'My Assignments', path: '/agent/referrals/' },
     ],
    admin: [
      { label: 'Profile', path: '/admin/profile/' },
      { label: 'Users', path: '/admin/users/' },
      { label: 'Properties', path: '/admin/properties/' },
      { label: 'Assignments', path: '/admin/assign-agent/' },
    ],
    staff: [
      { label: 'Profile', path: '/staff/profile/' },
      { label: 'Properties', path: '/staff/properties/' },
      { label: 'Users', path: '/staff/users/' },
      { label: 'Assignments', path: '/staff/assign-agent/' },
  
    ],
    builder: [
      { label: 'Profile', path: '/builder/profile/' },
      { label: 'Builder Info', path: '/builder/builder-info/' },
      { label: 'My Preferences', path: '/builder/builder-preferences/'},
      { label: 'My Properties', path: '/builder/properties/' },
      { label: 'Referrals', path: '/builder/referrals/' },
      ],
      builderagent: [
          { label: 'My Dashboard', path: '/dashboard' },
          { label: 'Profile', path: '/builderagent/profile' },
          { label: 'Builder Info', path: '/builderagent/builder_info' }, 
          { label: 'My Properties', path: '/builderagent/properties' },
          { label: 'My Preferences', path: '/builderagent/agent_preferences' },
          { label: 'My Assignments', path: '/builderagent/my_assignments' },
      ],
  };
  