import React from 'react';
import { useAuth } from '../../components/user/AuthProvider';
import { useNavigate, Link, NavLink } from 'react-router-dom';
import { Links } from "../../constants";

const getUserLinks = (roles) => {
  const uniqueLinks = new Set();
  
  roles.forEach(role => {
    if (Links[role]) {
      Links[role].forEach(link => uniqueLinks.add(JSON.stringify(link)));
    }
  });

  return Array.from(uniqueLinks).map(link => JSON.parse(link));
};

const NavLinks = ({ profileInfo, onClose }) => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => { 
    logout(); 
  };

  const handleLinkClick = (index) => {
    if (onClose) {
      onClose(); // Close the drawer when a link is clicked
    }
  };

  const drawerItems = profileInfo ? getUserLinks(profileInfo.role) : [];

  return (
    <>
              {drawerItems.map((item, index) => (
                 <Link key={index+"_navlink"} to={item.path}  onClick={() => handleLinkClick(index)}><h4 className="text-center hover:bg-primary transition-all duration-300 py-3">{item.label}</h4></Link>
            ))}

            <Link to="#" onClick={handleLogout}>
              <h4 className="text-center hover:bg-primary transition-all duration-300 py-3">
                Logout
              </h4>
            </Link>
            </>
  );
};

export default NavLinks;
