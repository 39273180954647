import React from 'react';
import {
  Box,
  Heading
} from '@chakra-ui/react';

const ReferralAgreementPage = () => {
  return (
    <div className="bg-primary  font-linksans">
      <div className="container px-10 py-12">
        <div className="flex-col items-center flex justify-center">
          <Box mx="auto" p={5} className="contentdiv">
          <Heading as="h1" size="xl" mb={5}>
          Referral Agreement
          </Heading>
                    
          <p><strong>Effective Date: 09/10/2024</strong></p>
          <p>This Referral Agreement (the &quot;Agreement&quot;) along with Terms and Conditions and Privacy Policy is entered into as of the Effective Date by and between Knock The Door Inc (&quot;Company&quot;) and the real estate agent, builder, or seller (&quot;Agent&quot;).</p>
          <p><strong>1. Purpose</strong></p>
          <p>The purpose of this Agreement is to outline the terms under which the Company will provide real estate referrals to the Agent and the associated obligations of the Agent.</p>
          <p><strong>2. Definitions</strong></p>
          <ul>
              <li><strong>Client</strong>: An individual or entity referred by the Company to the Agent for real estate services.</li>
              <li><strong>Referral Fee</strong>: The fee payable by the Agent to the Company for each successful transaction resulting from a referral.</li>
              <li><strong>Transaction</strong>: Any real estate purchase, sale, lease, or other service provided by the Agent to a Client referred by the Company.</li>
              <li><strong>Active Duration</strong>: The duration commencing on the date of the most recent Referral and end with 3 years from that recent Referral.</li>
              <li><strong>Pre-Existing Relationship</strong>: A Client who was working with the Agent prior to the date of the Referral.</li>
          </ul>
          <p><strong>3. Referral Process</strong></p>
          <ul>
              <li><strong>Referrals</strong>: The Company will refer potential Clients to the Agent based on the Client&rsquo;s real estate needs and preferences. Referrals will be made through the Platform or other agreed-upon methods (email, sms, phone calls, chat messages, and other types of communication methods).</li>
              <li><strong>Acceptance of Referrals</strong>: The Agent agrees to promptly review and respond to each referral. If the Agent is unable to accept a referral, they must notify the Company within [specified time period, e.g., 48 hours].</li>
          </ul>
          <p><strong>4. Agent Obligations</strong></p>
          <ul>
              <li><strong>Licensing and Compliance</strong>: The Agent represents and warrants that they hold all necessary licenses and certifications to provide real estate services in their operating jurisdiction and will comply with all applicable laws and regulations.</li>
              <li><strong>Terms and Privacy Policy</strong>: The Agent agrees Company&rsquo;s Terms of Service agreement and Privacy Policy agreements.</li>
              <li><strong>Professional Conduct</strong>: The Agent agrees to provide professional, diligent, and ethical services to all referred Clients.</li>
              <li><strong>Sharing</strong>: The Agent permits the Company to display agent information and certain transaction details on their website, platform, and other places.</li>
              <li><strong>Reporting</strong>: The Agent will provide the Company with regular updates on the status of referred Clients and Transactions, including but not limited to initial contact, ongoing negotiations, and completed as well as follow-up Transactions.</li>
          </ul>
          <p><strong>5. Referral Fees</strong></p>
          <ul>
              <li><strong>Fee Structure</strong>: The Agent agrees to pay the Company a Referral Fee or Commission Fee of [30% percentage or flat amount] of the total commission earned from each Transaction involving a referred Client. The exact fee structure will be detailed in Exhibit A attached hereto. Any multiple transactions with the Client are subject to additional transactions and the total Fee Structure is equivalent to each translation fee multiplied by the number of transactions Fee Structure as long as the transaction was initiated with the <strong>Active Duration</strong>.</li>
              <li><strong>Payment Terms</strong>: Referral Fees are due within [specified time period, e.g., 10 days] after the completion of a Transaction. Payments will be made via [payment method, e.g., bank transfer, check].</li>
              <li><strong>Non-Transferability</strong>: Referrals are Company&rsquo;s non-transferable and confidential information. Agent must not transfer Referrals to other Agents, Brokers, Investors, unknown contacts, or have hidden agendas to avoid commission fees to the Company. Agent must pay the agreed commission fee in the event of an improper transfer of Referral(s) by the Agent during the <strong>Active Duration</strong>.&nbsp;</li>
          </ul>
          <p>Properties that are available in our website or NOT available in our website and other external showings based on Client&rsquo;s interests and Agent&rsquo;s willingness to show external properties to the same Client fall under our Referrals and are Non-Transferrable. Irrespective of properties availability, any external properties showings or closures comes under commission fee structure and is applicable within the Active duration and within Non-Transferrable conditions.</p>
          <ul>
              <li><strong>No Direct Clients Work</strong>: The agent must not work with the Client directly without the Company&rsquo;s commission fee and without transparent engagement with the Client via the Company&rsquo;s platform and process.&nbsp;</li>
              <li><strong>Late Payments</strong>: Late payments will incur a penalty of [specified percentage, e.g., 1.5%] per month on the outstanding balance until paid in full.</li>
              <li><strong>Pre-Existing Relationship:&nbsp;</strong>The Agent must notify the company if the Agent has a Pre-Existing Relationship with the Referred Client within 3 business days. The Agent shall provide supporting documentation of such Pre-Existing Relationship to satisfy the authenticity of the relationship. Agent shall be responsible to pay the Fee Structure if Agent fails to notify and satisfy the Pre-Existing Relationship with the proper evidence.&nbsp;</li>
              <li><strong>Dual-Representation:</strong> The Agent is subject to paying the Referred Client side commission fee in case of Dual Representation.&nbsp;</li>
              <li><strong>Agent Purchases</strong>: The Agent is subjected to pay 3% of the purchase price of the property if the same Agent purchases the property.</li>
          </ul>
          <p><strong>6. Confidentiality</strong></p>
          <ul>
              <li><strong>Confidential Information</strong>: Both parties agree to keep all Confidential Information received from the other party confidential and not to disclose it to any third parties except as required by law or as necessary to perform their obligations under this Agreement.</li>
              <li><strong>Definition of Confidential Information</strong>: Confidential Information includes, but is not limited to, Client lists, business plans, marketing strategies, and any other proprietary information shared by the parties.</li>
          </ul>
          <p><strong>7. Term and Termination</strong></p>
          <ul>
              <li><strong>Term</strong>: This Agreement shall commence on the Effective Date and continue until terminated by either party as provided herein. However, the Referral Fee will survive and will be paid to the Company after the termination duration of the <strong>Active Duration</strong>.</li>
              <li><strong>Termination</strong>: Either party may terminate this Agreement for any reason with [<strong>specified time period, e.g., 30 days</strong>] written notice to the other party. Upon termination, the Agent will remain liable for any Referral Fees due for Referrals or Transactions within the Active Duration.</li>
          </ul>
          <p>8. <strong>Transparency and Audit</strong></p>
          <ul>
              <li><strong>Transparency</strong>: The agent must work with us, inform us of transactions originating from our referrals, and be transparent about such transactions.&nbsp;</li>
              <li><strong>Audit:&nbsp;</strong>The agent agrees and authorizes us to<strong>&nbsp;</strong>complete routine audits to gather all transaction information independently to identify successful transactions and the agent fully supports us with requested documents on such routine audits and submits all the required documents.&nbsp;</li>
              <li><strong>Broker</strong>: The agent agrees and authorizes us to contact the broker for any documents and complete the audit if the agent fails to submit the requested documents.</li>
          </ul>
          <p><strong>9. Indemnification</strong></p>
          <p>The Agent agrees to indemnify, defend, and hold harmless the Company, its affiliates, and their respective officers, directors, employees, and agents from and against any and all claims, liabilities, damages, losses, and expenses, including reasonable attorneys&apos; fees, arising out of or in connection with the Agent&rsquo;s services to referred Clients.</p>
          <p><strong>10. Limitation of Liability</strong></p>
          <p>To the maximum extent permitted by law, the Company shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from the referrals or the Agent&rsquo;s services.</p>
          <p><strong>11. Governing Law</strong></p>
          <p>This Agreement shall be governed by and construed in accordance with the laws of [Your State/Country], without regard to its conflict of law principles.</p>
          <p><strong>12. Dispute Resolution</strong></p>
          <p>Any disputes arising out of or relating to this Agreement shall be resolved through binding arbitration in accordance with the rules of [Arbitration Association], and judgment on the award rendered by the arbitrator(s) may be entered in any court having jurisdiction thereof.</p>
          <p><strong>13. Entire Agreement</strong></p>
          <p>This Agreement, together with Exhibit A and any other documents incorporated herein by reference, constitutes the entire agreement between the parties and supersedes any prior agreements or understandings, whether written or oral.</p>
          <p><strong>14. Amendments</strong></p>
          <p>Any amendments or modifications to this Agreement must be in writing and signed by both parties.</p>
          <p><strong>15. Severability</strong></p>
          <p>If any provision of this Agreement is found to be invalid or unenforceable, the remaining provisions shall continue to be valid and enforceable.</p>
          <p><strong>16. Notices</strong></p>
          <p>All notices required or permitted under this Agreement shall be in writing and shall be deemed delivered when delivered in person, sent by email, or three (3) days after being sent by certified or registered mail, postage prepaid, to the respective addresses of the parties.</p>
          <p><strong>Company<br/></strong>[Platform Name]<br/>[Address]<br/>[Email Address]<br/>[Phone Number]</p>
          <p><strong>Agent<br/></strong>[Agent Name]<br/>[Address]<br/>[Email Address]<br/>[Phone Number]</p>
          <p>IN WITNESS WHEREOF, the parties hereto have executed this Referral Agreement as of the Effective Date.</p>
          <p>&nbsp;</p>
          <p>[Company Representative Name]<br/>[Title]<br/>[Platform Name]</p>
          <p>&nbsp;</p>
          <p>[Agent Name]<br/>[Real Estate Agent]</p>
          <p>&nbsp;</p>
          <p><strong>Exhibit A: Referral Fee Structure</strong> [Detail the specific referral fee structure here.]</p>
          </Box>
              </div>
                </div>
                 </div>
  );
};

export default ReferralAgreementPage;
