import React from 'react';
import { useParams } from 'react-router-dom';
import {Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, 
  useDisclosure, Text, Stack, IconButton, Box, Tabs, TabList, TabPanels, Tab, TabPanel,Flex, Icon, Spacer, 
  Button, HStack, Container, Divider, useColorModeValue} from '@chakra-ui/react';

import { useNavigate} from 'react-router-dom';
import { useState,useEffect } from 'react';
import DynamicBreadcrumb from './Breadcrumb';
import ImageScroll from './ImageScroll';
import axiosInstance, {axiosInstanceES} from '../utils/axio-instance';
import { useTranslation } from 'react-i18next';
import CommunityInfo from './community/CommunityInfo';
import PropertyCard from './community/PropertyCard';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';

const CommunityDetails = () => {

  const { id } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [size, setSize] = useState('md')
  const { t, i18n  } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();
  const [data, setData] = useState(null); //community info
  const [propertyList, setPropertyList] = useState([]); // community houses

  const [visible, setVisible] = useState(false);
  const [images, setImages] = useState([]);
  const [pagination, setPagination] = useState({size:10, from:0, hideNext: false, hidePrev: true });

  const handleSizeClick = (newSize) => {
    setSize(newSize)
    onOpen()
  }



  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 700) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


 
  useEffect(() => {
    try {
      axiosInstanceES.get(`/community/${id}`)
      .then(response => {
        console.log("responsedata", response.data);
        setData(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
      //fetching proprties at the same time
      axiosInstanceES.get(`/community/${id}/list?size=${pagination.size}&from=${pagination.from}`)
      .then(response => {
        setPropertyList(response.data.data);
        if(response.data.total<pagination.size){
          setPagination({...pagination, total:response.data.total,  hideNext: true, hidePrev: true });
        }else{
          setPagination({...pagination, total:response.data.total });
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
      
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [id, pagination.from]);
  
  

    useEffect(() => {
      const fetchImages = async () => {
        try {
          const response = await axiosInstanceES.get(`/properties/${id}/photos`);
          // Assuming the response is an array of objects with 'url' field
          setImages(response.data.map(item => ({ image: item.url }))); 
        } catch (error) {
          console.error('Error fetching images:', error);
        }
      };
  
        fetchImages();

    }, [isOpen, id]); 
  
    const nextPage = function(){
      let from = pagination.from + pagination.size;
      if(pagination.total<= from){
        setPagination({...pagination, from, hideNext:true, hidePrev: false})
      }else if(pagination.total> pagination.size){
        setPagination({...pagination, from, hidePrev: false})
      }
    }

    const prevPage = function(){
      let from = pagination.from - pagination.size;
      if(from<=0){
        setPagination({...pagination, from: 0, hideNext: false, hidePrev: true})
      }else{
        setPagination({...pagination, from, hideNext: false})
      }
    }
return (
  <div className="page-content" >
  <Box >
  <Container maxW='6xl' centerContent>
  <Box width="100%" position="relative">

  {data != null && <DynamicBreadcrumb PropertyInfo={{"communityName": data.community.communityName}} />}
  {data != null && <CommunityInfo PropertyInfo={data} imageInfo={images} id={id} />}

  <Box className='search-list-sec' width={{base:"100%",md:"75%"}}>
    <Text
      fontSize={'16px'}
      color={useColorModeValue('black.500', 'black.300')}
      fontWeight={'600'}
      mb={'1'} mt={'2'}>
      Properties
      </Text>
      <Divider mt={'2'} borderColor={'#d9e2e8'}/>
      {(propertyList||[]).map((item, index) => (
        <Box mt={2}>
        <PropertyCard hit={item} key={index} />
        </Box>
    ))}    
    </Box>
        {/* <Flex mt='5' alignItems="center">
         <Autocomplete />
        </Flex> */}
        <Flex mt='5' mb='5' justifyContent="space-between">
          <Button
            leftIcon={<FiArrowLeft />}
            px="4"
            py="5"
            colorScheme='blue'
            variant='outline'
            onClick={prevPage}
            isDisabled={pagination.hidePrev}
          >Previous</Button>

          <Button
            leftIcon={<FiArrowRight />}
            px="4"
            py="5"
            colorScheme='blue'
            variant='outline'
            onClick={nextPage}
            isDisabled={pagination.hideNext}
          >Next</Button>

        </Flex>
   </Box>
   <Box width="100%" id="talkToAgent">
      <iframe
          id="JotFormIFrame-242837111743050"
          title="Prod-Property-GetMoreDetails-2"
          onload="window.parent.scrollTo(0,0)"
          allowtransparency="true"
          allow="geolocation; microphone; camera; fullscreen"
          src={`https://form.jotform.com/242837111743050?communityPublicID=${id}`}
          frameborder="0"
          style={{"minWidth":"100%", maxWidth: "100%", "height":"1200px", "border":"none"}}
          scrolling="no"
        >
        </iframe>

   </Box>
</Container>
</Box>

</div>
);
};

export default CommunityDetails;
