import React from 'react';
import { 
  Box, 
  Button, 
  FormControl, 
  FormLabel, 
  Input, 
  Textarea, 
  Heading, 
  Stack, 
  Text, 
  VStack, 
  Accordion, 
  AccordionItem, 
  AccordionButton, 
  AccordionPanel, 
  AccordionIcon, 
  Divider ,
  Container
} from '@chakra-ui/react';

const SupportPage = () => {
  return (
    <div className="bg-primary  font-linksans">
      <div className="container px-10 py-12">
        <div className="flex-col items-center flex justify-center">
      {/* Header */}
      <Box textAlign="center" mb={12}>
        <Heading as="h1" size="xl" mb={4} color="black.500">
          Support Center
        </Heading>
        <Text fontSize="lg" color="gray.600">
          We're here to help you with any questions or issues you may have.
        </Text>
      </Box>

      {/* FAQ Section */}
      <Box maxW="800px" w="full" mb={12}>
        <Heading as="h2" size="lg" mb={6}>
          Frequently Asked Questions
        </Heading>
        <Accordion allowToggle>
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                How do I reset my password?
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              To reset your password, go to the login page, click on "Forgot Password," and follow the instructions.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                How can I update my profile information?
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              You can update your profile information by navigating to the "Account Settings" section in your dashboard.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                How do I contact support?
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              You can contact support by filling out the form below or emailing us directly at support@realestate.com.
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>

      <Divider mb={12} />

      {/* Contact Support Form */}
      <Box maxW="600px" w="full" bg="white" p={8} borderRadius="lg" boxShadow="lg">
        <Heading as="h2" size="lg" mb={6} textAlign="center">
          Contact Support
        </Heading>
        <Text fontSize="lg" color="gray.600" mb={6} textAlign="center">
          If you can't find the answer you're looking for, fill out the form below and our team will get back to you.
        </Text>

        <Stack spacing={4}>
          <FormControl id="name" isRequired>
            <FormLabel>Name</FormLabel>
            <Input placeholder="Your Name" />
          </FormControl>

          <FormControl id="email" isRequired>
            <FormLabel>Email</FormLabel>
            <Input type="email" placeholder="Your Email" />
          </FormControl>

          <FormControl id="issue" isRequired>
            <FormLabel>Issue</FormLabel>
            <Input placeholder="Subject" />
          </FormControl>

          <FormControl id="message" isRequired>
            <FormLabel>Message</FormLabel>
            <Textarea placeholder="Describe your issue" />
          </FormControl>

          <Button 
            colorScheme="blue" 
            size="lg" 
            type="submit"
            w="full"
          >
            Submit
          </Button>
        </Stack>
      </Box>
    </div>
    </div>
    </div>
  );
}

export default SupportPage;
