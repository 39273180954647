import React, { useCallback, useMemo, useRef, useState } from "react";
import { AgGridReact } from "@ag-grid-community/react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Button } from '@chakra-ui/react';

const getServerSideDatasource = (server) => {
    return {
        getRows: (params) => {
            const { startRow, endRow, filterModel } = params.request;
            const pageSize = endRow - startRow;
            const filterParams = new URLSearchParams();
            Object.keys(filterModel).forEach(key => {
              filterParams.append(`filter[${key}]`, filterModel[key].filter);
            });
            const queryString = `${server}?start=${startRow}&size=${pageSize}&${filterParams.toString()}`;

            fetch(queryString)
                .then(response => response.json())
                .then(data => {
                    if (data && data.rows && data.totalCount !== undefined) {
                        params.success({
                            rowData: data.rows,
                            rowCount: data.totalCount,
                        });
                    } else {
                        throw new Error('Data format is incorrect');
                    }
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                    params.failCallback();
                });
        }
    };
};

const PropertyListingPagination = () => {
    const gridRef = useRef();
    const [editingRows, setEditingRows] = useState({});

    const columnDefs = useMemo(() => [
        { headerName: 'ID', field: 'id' },
        { headerName: 'Address', field: 'address' },
        { headerName: 'City', field: 'city' },
        { headerName: 'State', field: 'state' },
        { headerName: 'ZIP', field: 'zip' },
        { headerName: 'Community Name', field: 'communityName' },
        {
            headerName: 'Actions',
            field: 'actions',
            cellRendererFramework: (params) => {
              const isEditing = editingRows[params.node.id];
              
              return (
                <>
                  {isEditing ? (
                    <>
                      <Button colorScheme="green" size="sm" onClick={() => saveRow(params.node)}>
                        Save
                      </Button>
                      <Button colorScheme="gray" size="sm" onClick={() => cancelEdit(params.node)}>
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button colorScheme="blue" size="sm" onClick={() => editRow(params.node)}>
                        Edit
                      </Button>
                      <Button colorScheme="red" size="sm" onClick={() => deleteRow(params.node)}>
                        Delete
                      </Button>
                    </>
                  )}
                </>
              );
            }
          }
        // Add more columns as needed
    ], [editingRows]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        editable:true,
        filter: true,
        flex: 1
    }), []);
    

    const onGridReady = useCallback((params) => {
        const apiUrl = `${process.env.REACT_APP_API_URL}/listpropertieswithpagination`;
        const datasource = getServerSideDatasource(apiUrl);
        //params.api.setServerSideDatasource(datasource);
        params.api.setGridOption('serverSideDatasource', datasource);
      //  gridRef.current = params.api;
    }, []);

    
    const createRow = async() => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/latestpropertyid`);
        const data =  await response.json();
      const latestId =  data.latestPropertyId || 0; // Handle default case

    //         // Generate a new ID
             const newId = latestId + 1;
         console.log('new row add called',newId);
        const emptyRow = {
            id: newId,
            communityId: '',
            communityPublicID: '',
            address: '',
            street1: '',
            street2: '',
            city: '',
            state: '',
            zip: '',
            mlsStatus: '',
            property_type: '',
            price: '',
            bedrooms: '',
            bathrooms: '',
            partial_bathrooms: '',
            sqft: '',
            no_of_stories: '',
            photos: '',
            property_description: '',
            lot_area: '',
            built_year: '',
            daysonthemarket: '',
            pool: '',
            totalrooms: ''
        };
        return emptyRow;
      };

      const addNewRow =  useCallback(async () => {
        // const selectedRows = gridRef.current.api.getSelectedNodes();
        // if (selectedRows.length === 0) {
        //   console.warn("[Example] No row selected.");
        //   return;
        // }
       // const rowIndex = selectedRows[0].rowIndex;
       const newRow = await createRow();
        const transaction = {
          addIndex: 0,
          add: [newRow],
        };
        const result = gridRef.current.api.applyServerSideTransaction(transaction);
        console.log('transaction',transaction)
        //logResults(transaction, result);
        console.log('result',result)
      }, [createRow]);

     
      const getRowId = useCallback((params) => `${params.data.id}`, []);

      const editRow = useCallback((node) => {
        setEditingRows(prev => ({ ...prev, [node.id]: true }));
        node.setDataValue('editable', true);
      }, []);
    
      const cancelEdit = useCallback((node) => {
        setEditingRows(prev => ({ ...prev, [node.id]: false }));
        node.setDataValue('editable', false);
      }, []);


      const saveRow = useCallback((node) => {
        setEditingRows(prev => ({ ...prev, [node.id]: false }));
        node.setDataValue('editable', false);
    
        // Implement the save logic here, such as sending the updated data to the server.
        const updatedData = node.data;
        fetch(`${process.env.REACT_APP_API_URL}/updateproperty`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updatedData),
        })
          .then(response => response.json())
          .then(data => {
            console.log('Row saved:', data);
          })
          .catch(error => {
            console.error('Error saving row:', error);
          });
      }, []);
    
      const deleteRow = useCallback((node) => {
        const data = node.data;
    
        fetch(`${process.env.REACT_APP_API_URL}/deleteproperty/${data.id}`, {
          method: 'DELETE',
        })
          .then(response => response.json())
          .then(data => {
            console.log('Row deleted:', data);
            gridRef.current.api.applyServerSideTransaction({ remove: [node.data] });
          })
          .catch(error => {
            console.error('Error deleting row:', error);
          });
      }, []);
    

    return (
        <div className="ag-theme-alpine" style={{ height: 900, width: '100%' }}>
            <Button onClick={addNewRow} colorScheme="blue" mb={4}>
                Add New Property
            </Button>
            <AgGridReact
              ref={gridRef}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                rowModelType={"serverSide"}
                serverSideStoreType={"full"}
                pagination={true}
                paginationPageSize={100}
                enableSorting={true}
                enableFilter={true}
                enableCellEditing={true}
                getRowId={getRowId}
                onGridReady={onGridReady}
            />
        </div>
    );
};

export default PropertyListingPagination;
