import React from 'react';
import { Tooltip } from '@chakra-ui/react';
import { FaMapMarkerAlt } from 'react-icons/fa';

const Marker = ({ text, address }) => {
  const handleMarkerClick = () => {
    if (address) {
      // Open a new tab with the address
      window.open(`https://www.google.com/maps/place/${encodeURIComponent(address)}`, '_blank');
    }
  };

  return (
    <Tooltip label={text} aria-label="Address" fontSize="md">
      <div style={{ color: 'red', fontSize: '24px', width: '300px', cursor: 'pointer' }} onClick={handleMarkerClick}>
        <FaMapMarkerAlt />
      </div>
    </Tooltip>
  );
};

export default Marker;
