import React, { useState } from 'react';
import { connectRefinementList } from 'react-instantsearch-dom';

const SquareBoxRatingMenu = ({ items, currentRefinement, refine }) => {
  const [selectedItems, setSelectedItems] = useState([]);

  const handleClick = (item) => {
    const index = selectedItems.findIndex((selectedItem) => selectedItem.label === item.label);
    let updatedItems;
  
    if (index !== -1) {
      // If item is already selected, remove it
      updatedItems = selectedItems.filter((selectedItem) => selectedItem.label !== item.label);
    } else {
      // If item is not selected, add it
      updatedItems = [...selectedItems, item];
    }
  
    setSelectedItems(updatedItems);

    updatedItems.forEach((selectedItem) => {
      refine(selectedItem.value);
    });
  
    // // Refine based on the values of all selected items
    // refine(updatedItems.map((selectedItem) => selectedItem.value));
  };
  
  const sortedItems = items.sort((a, b) => parseFloat(a.label) - parseFloat(b.label));
  const positiveItems = sortedItems.filter((item) => parseFloat(item.label) > 0);

  return (
    <div>
      {positiveItems.map((item) => (
        <div
          key={item.label}
          onClick={() => handleClick(item)}
          className={selectedItems.some((selectedItem) => selectedItem.label === item.label) ? 'searchRatingbox acitve' : 'searchRatingbox'}
        >
          {item.label}
        </div>
      ))}
    </div>
  );
};

const ConnectedSquareBoxRatingMenu = connectRefinementList(SquareBoxRatingMenu);

export default ConnectedSquareBoxRatingMenu;
