function ComparePlatforms() {
  return (
    <div className="container 2xl:py-10 xl:py-10 lg:py-10 md:py-10 py-8 mx-auto">
      {/* Comparison Table */}
      <div className="overflow-x-auto mt-10">
      <h3 className="2xl:text-4xl xl:text-3xl lg:text-2xl md:text-2xl text-2xl text-center font-semibold text-textColor pb-5">
       Compare with other platforms
        </h3>
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr className="border-b">
              <th className="px-4 py-2 bg-primary text-left font-semibold">
                
              </th>
              <th className="px-4 py-2 bg-primary text-gray-700 font-semibold">
                KnockTheDoor.com
              </th>
              <th className="px-4 py-2 bg-primary text-gray-700 text-left font-semibold">
                Other Platforms (e.g., Fundrise, RealtyMogul, CrowdStreet)
              </th>
            </tr>
          </thead>
          <tbody>
          <tr className="border-b">
              <td className="px-4 py-4 font-semibold">Model</td>
              <td className="px-4 py-4 text-gray-700">
                We are NOT an investment platform. We sit between buyers and
                sellers as a marketplace, let you own the home and operate close
                to the typical real estate transaction model but with groups in
                order to create extra or unique benefits.
              </td>
              <td className="px-4 py-4 text-gray-700">
                They are investment platforms and manage various
                <ul className="list-disc ml-6">
                  <li>Funds/Pooled Fund of Loans</li>
                  <li>REITs</li>
                  <li>Debt/Secured Loans</li>
                  <li>Short-Term or Long-Term Notes</li>
                  <li>Fractional Ownership in Rentals</li>
                  <li>IRA etc</li>
                </ul>
                Some of those models require accredited investors.
              </td>
            </tr>
            <tr className="border-b">
              <td className="px-4 py-4 font-semibold">Investment type</td>
              <td className="px-4 py-4 text-gray-700">
                We do NOT take any investment from you and we are NOT an
                investment platform.
              </td>
              <td className="px-4 py-4 text-gray-700">
                They are real estate crowdfunding platforms, and would take
                your investment and invest in their real estate funds or
                different types of investment vehicles to own a variety of asset
                types and pay you dividends on a periodic basis.
              </td>
            </tr>
            <tr className="border-b">
              <td className="px-4 py-4 font-semibold">Rental Income</td>
              <td className="px-4 py-4 text-gray-700">
              You will get monthly rental income from tenants via Property management firm
              </td>
              <td className="px-4 py-4 text-gray-700">
              They typically pay dividends at an interval duration set by each fund or type of investment vehicle policy. 
              </td>
            </tr>
            <tr className="border-b">
              <td className="px-4 py-4 font-semibold">Ownership of Home</td>
              <td className="px-4 py-4 text-gray-700">
                You will buy the home in your name and own the title of the home.
              </td>
              <td className="px-4 py-4 text-gray-700">
                Their funds or type of investment vehicle own the homes.
              </td>
            </tr>
            <tr className="border-b">
              <td className="px-4 py-4 font-semibold">Property Management</td>
              <td className="px-4 py-4 text-gray-700">
                You are part of groups and manage homes through a property management firm.
              </td>
              <td className="px-4 py-4 text-gray-700">
                Hands-off to their type of investment vehicle or funds to manage homes.
              </td>
            </tr>
            <tr className="border-b">
              <td className="px-4 py-4 font-semibold">Exit/Sell</td>
              <td className="px-4 py-4 text-gray-700">
                You can sell when you want it as you own the home.
              </td>
              <td className="px-4 py-4 text-gray-700">
                Based on their funds’ or type of investment vehicle’s policies; a retention duration and potential penalties if you want to exit.
              </td>
            </tr>
            <tr className="border-b">
              <td className="px-4 py-4 font-semibold">Hold Time w/o Penalty</td>
              <td className="px-4 py-4 text-gray-700">
                You take decisions whenever you want to sell it with more flexibility in hand.
              </td>
              <td className="px-4 py-4 text-gray-700">
                Based on their funds’ or type of investment vehicle’s policies with typical 1yr to 5yrs. Check their policy and come to a conclusion.
              </td>
            </tr>
            <tr className="border-b">
              <td className="px-4 py-4 font-semibold">Min. Investment</td>
              <td className="px-4 py-4 text-gray-700">
                Required Down Payment as per home value.
              </td>
              <td className="px-4 py-4 text-gray-700">
                Based on their funds’ or type of investment vehicle’s requirements.
              </td>
            </tr>
            <tr className="border-b">
              <td className="px-4 py-4 font-semibold">Appreciation</td>
              <td className="px-4 py-4 text-gray-700">
                Appreciation from the potential change in property value realized at the end of the hold period.
              </td>
              <td className="px-4 py-4 text-gray-700">
                Based on their investment vehicle type. Example: Loan/Short-term Note may not fetch appreciation.
              </td>
            </tr>
            <tr className="border-b">
              <td className="px-4 py-4 font-semibold">Choice</td>
              <td className="px-4 py-4 text-gray-700">
                <ul className="list-disc ml-6">
                  <li>Ownership</li>
                  <li>Direct rental income</li>
                  <li>Hold duration is in your hand</li>
                  <li>Direct exit</li>
                  <li>Property mgmt via a firm</li>
                  <li>Real Appreciation opportunity</li>
                </ul>
              </td>
              <td className="px-4 py-4 text-gray-700">
                <ul className="list-disc ml-6">
                  <li>Fund or type of investment vehicle ownership</li>
                  <li>Dividends</li>
                  <li>Hold duration is not in your hand in most types</li>
                  <li>Wait until typical fund exit or their policy-based exit</li>
                  <li>Hands-off Property mgmt</li>
                  <li>Appreciation opportunity based on investment type and other factors</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ComparePlatforms;
