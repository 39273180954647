import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  VStack,
  Avatar,
  Heading,
  Text,
  HStack,
  useToast,
  Container,
  Spinner
} from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import axiosInstance, {axiosInstanceES} from '../../utils/axio-instance';

const Joingroup = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const [userDetails, setUserDetails] = useState(null);
  const [inviteDetails, setInviteDetails] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {

    // Get the token from the URL
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    if (token) {
      // Fetch the invitation details using the token
      axiosInstance.get(`/usrgroup/invitedetails/${token}`)
        .then((response) => {
          setUserDetails(response.data);
          setLoading(false);
        })
        .catch((err) => {

          toast({
            title: 'Error',
            description: "Token Expired",
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });

          setError('Failed to fetch invitation details.');
          setLoading(false);
        });
    } else {
      setError('Invalid token.');
      setLoading(false);
    }
  }, [location, navigate]);


  useEffect(() => {
    if (userDetails?.created_by) {
      const accessToken = localStorage.getItem('accessToken');
      axiosInstance.get(`/users/${userDetails.created_by}/profile/`)
        .then((response) => {
          setInviteDetails(response.data); // Store inviter's profile
        })
        .catch(() => {
          setError('Failed to fetch inviter details.');
        });
    }
  }, [userDetails]);


  const handleAccept = () => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const accessToken = localStorage.getItem('refreshToken'); // Ensure the correct access token is stored here
  
    if (token && accessToken) {
      axiosInstance.put(`/usrgroup/acceptinvite/${token}`, {})
        .then((response) => {
          toast({
            title: 'Request Accepted',
            description: 'You have accepted the group request.',
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });
        })
        .catch((error) => {
          console.error('Error details:', error.response);
  
          const errorMessage = error.response?.data?.message || 'Failed to accept the group request.';
  
          toast({
            title: 'Error',
            description: errorMessage,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });
        });
    } else {
      toast({
        title: 'Invalid Token',
        description: 'The token provided is invalid.',
        status: 'error',
        duration: 3000,
        isClosable: true, 
        position: 'bottom-left',
      });
    }
  };
  
  const handleReject = () => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    
    if (token) {
      axiosInstance.put(`/usrgroup/rejectinvite/${token}`, {}, )
        .then((response) => {
          // Show success toast when the request is successful
          toast({
            title: 'Request Rejected',
            description: 'You have rejected the group request.',
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });
        })
        .catch((error) => {
          // Show error toast in case of failure
          toast({
            title: 'Error',
            description: 'Failed to reject the group request.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'bottom-left',
          });
        });
    } else {
      // Handle case when token is not found
      toast({
        title: 'Invalid Token',
        description: 'The token provided is invalid.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'bottom-left',
      });
    }
  };

  if (loading) {
    return (
      <Container centerContent mt={10}>
        <Spinner size="xl" />
      </Container>
    );
  }

  if (error) {
    return (
      <Container centerContent mt={10}>
        <Text color="red.500">{error}</Text>
      </Container>
    );
  }

  return (
    <div className="page-content min-h-svh">
      <Container maxW="100%" mt={10}>
        <Box
          maxW="lg"
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          p={6}
          m="auto"
          mt={10}
          boxShadow="lg"
          backgroundColor={"#fafbfc"}
        >
          <VStack spacing={4}>
            <Heading>Group Invitation</Heading>
            {inviteDetails!=null? <>
            <Avatar size="xl" name={`${inviteDetails.first_name} ${inviteDetails.last_name}`} bg="gray.500"/>
            
            {/* User details */}
            <Heading as="h3" size="md">{`${inviteDetails.first_name} ${inviteDetails.last_name}`}</Heading>
            <Text fontSize="md">Start collaborating with your group on KnockTheDoor</Text>
            {/*<Text fontSize="md">{userDetails.phone}</Text> */}
            <Text fontSize="md" fontWeight="bold" color="gray.500">Group Name: {userDetails.group_name}</Text>

            {/* Buttons for Accept and Reject */}
            <HStack spacing={2} mt={4} width={"sm"}>
              <button
                className="mt-4 text-textColor hover:text-white hover:bg-[#146d0a] duration-300 transition-all font-semibold 2xl:py-4 xl:py-4 lg:py-4 md:py-3 px-4 py-2.5 border rounded-xl w-full"
                onClick={handleAccept}
              >
                Join Group
              </button>
              <button
                 className="mt-4 text-textColor hover:text-white hover:bg-[#e40606] duration-300 transition-all font-semibold 2xl:py-4 xl:py-4 lg:py-4 md:py-3 px-4 py-2.5 border rounded-xl w-full"
                onClick={handleReject}
              >
                Reject
              </button>
            </HStack>
            </>:""}
          </VStack>
        </Box>
      </Container>
    </div>
  );
};

export default Joingroup;
