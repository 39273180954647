import React, { useState, useEffect, useMemo, useCallback, useRef} from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Container, Heading, Button, useDisclosure, useToast } from '@chakra-ui/react';
import ActionCellRenderer from './ActionCellRenderer';
import CommunityUploadButtonCellRenderer from './CommunityUploadButtonCellRenderer';
import CommunityUploadModal from './CommunityUploadModal';
import Swal from 'sweetalert2';
import { useParams,useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; 
import axiosInstance, {axiosInstanceES} from '../../utils/axio-instance';

const getServerSideDatasource = (server) => {
    return {
        getRows: (params) => {

          if (!server) {
            params.api.hideOverlay();
            console.warn('Server URL is null or undefined. Returning empty rows.');
            params.success({
              rowData: [],
              rowCount: 0,
            });
            return;
          }

            const { startRow, endRow,filterModel } = params.request;
            const pageSize = endRow - startRow;
            const filterParams = new URLSearchParams();
            Object.keys(filterModel).forEach(key => {
              filterParams.append(`filter[${key}]`, filterModel[key].filter);
            });
            const queryString = `${server}?start=${startRow}&size=${pageSize}&${filterParams.toString()}`;
            
            axiosInstanceES.get(queryString)
            .then(response => {
                const data = response.data;
                if (data && data.rows && data.totalCount !== undefined) {
                    params.success({
                        rowData: data.rows,
                        rowCount: data.totalCount,
                    });
                } else {
                    throw new Error('Data format is incorrect');
                }
            })
                .catch(error => {
                    console.error('Error fetching data:', error);
                    params.failCallback();
                });
        }
    };
};

const CommunityListing = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [rowData, setRowData] = useState([]);
  const [editRowId, setEditRowId] = useState(null);
  const [newRow, setNewRow] = useState(null);
  const [newRowId, setNewRowId] = useState(null);
  const [propertyId, setPropertyId] = useState(null);
  const [newRows, setNewRows] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [gridOption, setGridOption] = useState(null); 
  const gridApiRef = useRef(null);
  const gridRef1 = useRef(null);
  const [dataToBeDeleted,setDataToBeDeleted] = useState(null);
  const storedUserInfo = localStorage.getItem('userid');
  const roleInfo = localStorage.getItem('roles');
  let location = useLocation();
  const { t} = useTranslation();
  const toast = useToast();
  const segments = location.pathname.split('/').filter(segment => segment);
  const lastSegment = segments[segments.length - 2];

  const handleCellValueChanged = (event) => {
    const updatedRowData = rowData.map(row => {
      if (row.id === event.id) {
        return { ...row, [event.colDef.field]: event.newValue };
      }
      return row;
    });
    setRowData(updatedRowData);
  };

  const onEditClick = (id) => {
    setEditRowId(id);
  };

  const openUploadModal = (id) => {
    setPropertyId(id);
    onOpen();
  };

  const onCancelClick = () => {
   
  //  const newRowId = getNewRow(); // Retrieve the stored new row id
    if (gridRef1.current && gridRef1.current.api) {
    
    if (newRowId) {
        const transaction = {
            remove: [{ id: newRowId }]
        };

        gridRef1.current.api.applyServerSideTransaction(transaction);

        // Clear the stored new row id
        setNewRow(null);
        setNewRowId(null);
    }
  }
    setEditRowId(null);
  //  setNewRow(null);
  };


  const transformData = (frontendData) => {
    const datetime = new Date().toISOString();
    return {
      //location: `${frontendData.latitude},${frontendData.longitude}`,
      location: null,
      main: {
        sourceId: frontendData.builderName || "tollbrothers",
        communityPublicID: frontendData.communityPublicID,
        propertyStatus: "active",
        recordCreatedDate: new Date().toISOString(),
        createdRole: roleInfo,
        createdByUser: storedUserInfo,
        recordCreatedDate: datetime,
        recordType: 'community',
      },
      data: {
        propertyInfo: {
          address: {
            label: `${frontendData.street},${frontendData.city},${frontendData.state},${frontendData.zip}`,
            street: frontendData.street,
            city: frontendData.city,
            state: frontendData.state,
            zip: frontendData.zip,
            metroName: frontendData.city,
            region: `${frontendData.city.toUpperCase()}`
          },
        },
        lotInfo: {
          lotSquareFeet: frontendData.lotSquareFeet || null,
          legalDescription: frontendData.legalDescription || null
        },
      },
      builder: { 
        builderName: frontendData.builderName || null,
      },
      community: {
        communityId: frontendData.communityId || null,
        communityName: frontendData.communityName || '',
      }
    };
  };

  
  const validateNewRow = (newRow) => {
    const requiredFields = ['communityName', 'builderName', 'street', 'city', 'state', 'zip'];
    const errors = [];
  
    // Validate if the newRow object has the required fields filled
    requiredFields.forEach(field => {
      if (!newRow[field] || newRow[field].trim() === '') {
        errors.push(field);
      }
    });
  
    if (errors.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: `Please fill in the following required fields: ${errors.join(', ')}`,
      });
      return false;
    }
  
    return true;
  };

  const saveNewRow = async (newRow) => {

    if (!validateNewRow(newRow)) {
      return; // Stop the save operation if validation fails
    }

    try {
      const transformedData = transformData(newRow);
    
      const payload = {
        role : roleInfo,
        properties: [transformedData.main.communityPublicID] 
      };

      const dynamoApiUrl = `/users/${storedUserInfo}/properties`;
      const elasticsearchApiUrl = `/addcommunity`;
  
      // Save to DynamoDB
      const dynamoResponse = await axiosInstance.post(dynamoApiUrl, payload);
  
      // Save to Elasticsearch
      const elasticsearchResponse = await axiosInstanceES.post(elasticsearchApiUrl, transformedData);
  
      // Check if both requests were successful
      if (dynamoResponse.status === 200 && elasticsearchResponse.status === 200) {
        setNewRow(null);
        toast({
          title: t('community-addedmessage'),
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      
      } else {
        throw new Error('One of the requests failed');
      }
    } catch (error) {
      console.error('Error saving new row:', error);
      toast({
        title: 'Error',
        description: t('updatefailed'),
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom-left',
      });
    }
  };

  const createRow = async() => {
    const response = await axiosInstanceES.get(`/lastlistcommunities`);
    const data =  response.data;

    let latestId = 0;
    let latestcommunityId = 0;

    if (data.length > 0) {
      const latestCommunityData = data[0];
      latestId = parseInt(latestCommunityData.communityPublicID, 10) || 0; // Handle default case and ensure integer
      latestcommunityId = parseInt(latestCommunityData.communityId, 10) || 0; // Handle default case and ensure integer
    }
  
         const newId = latestId + 1;
         const newCommunityId = latestcommunityId + 1;
    const emptyRow = {
        id:newId,
        communityPublicID: newId,
        communityName: '',
        communityId: newCommunityId,
        builderName: '',
        address: '',
        street: '',
        city: '',
        state: '',
        zip: '',
        photos: [],
    };
    // setRowData([emptyRow, ...rowData]);
    setNewRow(emptyRow.id);
    setNewRowId(emptyRow.id);
    setNewRows(prevNewRows => [...prevNewRows, emptyRow.id]);
    return emptyRow;

  };

  const addNewRow =  useCallback(async () => {
    const newRow = await createRow();
     if (gridRef1.current && gridRef1.current.api) {
      const transaction = {
        addIndex: 0,
        add: [newRow],
      };
      const result = gridRef1.current.api.applyServerSideTransaction(transaction);
    } else {
      console.error("Grid reference is not available or not properly assigned.");
    }
   }, [createRow,newRow]);


  const onGridReadyCommunity = useCallback(async (params) => {
    try {
      setGridApi(params.api);
      let apiUrl;
      const isAdminOrStaff = roleInfo && (roleInfo.includes('admin') || roleInfo.includes('staff'));
  
      if (isAdminOrStaff) {
        apiUrl = `/listcommunitieswithpagination`;
      } else {
        const userAuthorization = storedUserInfo; // Adjust if you need to handle `userAuthorization` differently
        const response = await axiosInstance.get(`${process.env.REACT_APP_DYNAMO_API_URL}/users/${userAuthorization}/properties`);
  
        const userProperties = response.data || [];
        if (userProperties.length > 0) {
          const ids = userProperties.map(p => p).join(',');
          apiUrl = `/usercommunitypropertiesdatapagination?ids=${ids}`;
        } else {
          apiUrl = null; // Handle this case if no properties are found
        }
      }
      
        const datasource = getServerSideDatasource(apiUrl);
        params.api.setGridOption('serverSideDatasource', datasource);
    } catch (error) {
      console.error('Error setting up the grid data source:', error);
    }
  }, [roleInfo, storedUserInfo]);

  const onBtExport = useCallback(() => {
    if (gridApiRef.current) {
      gridApiRef.current.exportDataAsCsv();
    }
  }, []);
  
  const onUpdateClick = async (event) => {
    try {
      const transformedData = transformData(event);
      await axiosInstanceES.put(`/updatecommunity/${event.communityPublicID}`, transformedData);
      setEditRowId(null);
      toast({
        title: t('community-updatedmessage'),
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'bottom-left',
      });
	  
    } catch (error) {
      console.error('Error updating row:', error);
      toast({
        title: 'Error',
        description: t('updatefailed'),
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom-left',
      });
    }
  };

  // const onDeleteClick = async (id) => {

  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "Once deleted, you will not be able to recover this community!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonText: "Yes, delete it!",
  //     cancelButtonText: "No, keep it",
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       try {
  //         const isNewRow = newRows.includes(id);

  //         if (isNewRow) {
  //           setRowData(rowData.filter(row => row.id !== id));
  //           setNewRows(newRows.filter(rowId => rowId !== id));
  //           toast({
  //             title: t('community-removemessage'),
  //             status: 'success',
  //             duration: 3000,
  //             isClosable: true,
  //             position: 'bottom-left',
  //           });
  //         } else {
  //           alert(id);
  //         await axiosInstanceES.delete(`/deletecommunity/${id}`);
  //         setRowData(rowData.filter(row => row.id !== id));
  //         toast({
  //           title: t('community-deletemessage'),
  //           status: 'success',
  //           duration: 3000,
  //           isClosable: true,
  //           position: 'bottom-left',
  //         });
  //         }
  //       } catch (error) {
  //         console.error('Error deleting row:', error);
  //         toast({
  //           title: 'Error',
  //           description: t('updatefailed'),
  //           status: 'error',
  //           duration: 5000,
  //           isClosable: true,
  //           position: 'bottom-left',
  //         });
  //       }
  //     }
  //   });
  // };

  const onDeleteClick = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this community!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const isNewRow = newRows.includes(id);
  
          // For new rows (not yet saved in the backend), remove them directly
          if (isNewRow) {
            const updatedRowData = rowData.filter(row => row.id !== id);
            setRowData(updatedRowData);
            setNewRows(newRows.filter(rowId => rowId !== id));
  
            if (gridApi) {
              const transaction = {
                remove: [{ id }],
              };
              gridRef1?.current?.api?.applyServerSideTransaction(transaction);
            }
  
            toast({
              title: t('community-removemessage'),
              status: 'success',
              duration: 3000,
              isClosable: true,
              position: 'bottom-left',
            });
  
          } else {
            // For existing rows, delete via API call
            await axiosInstanceES.delete(`/deletecommunity/${id}`);

            await axiosInstanceES.delete(`/properties/photos/${id}`);
  
            // Remove the row from the local rowData state after successful deletion
            const updatedRowData = rowData.filter(row => row.id !== id);
            setRowData(updatedRowData);
  
            // Apply transaction to remove row from AG Grid
            if (gridApi) {
              const transaction = {
                remove: [{ id }],
              };
              gridRef1?.current?.api?.applyServerSideTransaction(transaction);
            }
  
            toast({
              title: t('community-deletemessage'),
              status: 'success',
              duration: 3000,
              isClosable: true,
              position: 'bottom-left',
            });
          }
        } catch (error) {
          console.error('Error deleting row:', error);
          toast({
            title: 'Error',
            description: t('updatefailed'),
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'bottom-left',
          });
        }
      }
    });
  };
  
  
  
  const colDefs = useMemo(() => [
    {
      headerName: 'Actions',
      field: 'actions',
      cellRenderer: ActionCellRenderer,
      editable: false,
      colId: 'actions',
      pinned: 'left',
      resizable: false,
      suppressMovable: true,
    },
    { headerName: 'Community Public ID', field: 'communityPublicID', editable: false, sortable: true, filter: 'agTextColumnFilter', hide: true },
    { headerName: 'Community ID', field: 'communityId', editable: false, sortable: true, filter: true, sort: 'desc', hide: true },
    { headerName: 'Community Name', field: 'communityName', editable: editRowId !== null || newRow !== null, sortable: true, filter: 'agTextColumnFilter' },
    { headerName: 'Builder Name', field: 'builderName', editable: editRowId !== null || newRow !== null, sortable: true, filter: true },
    { headerName: 'Photos', field: 'photos', cellRenderer: CommunityUploadButtonCellRenderer },
    { headerName: 'Street', field: 'street', editable: editRowId !== null || newRow !== null, sortable: true, filter: true },
    { headerName: 'City', field: 'city', editable: editRowId !== null || newRow !== null, sortable: true, filter: true },
    { headerName: 'State', field: 'state', editable: editRowId !== null || newRow !== null, sortable: true, filter: true },
    { headerName: 'Zip', field: 'zip', editable: editRowId !== null || newRow !== null, sortable: true, filter: true },
    {
      headerName: 'Full Address',
      field: 'address',
      valueGetter: (params) => {
        const { street, city, state, zip } = params.data;
    
        const parts = [
          street && street !== 'N/A' ? street : '',
          city && city !== 'N/A' ? city : '',
          state && state !== 'N/A' ? state : '',
          zip && zip !== 'N/A' ? zip : ''
        ];
    
        return parts.filter(part => part).join(', ');
      },
      editable: false,
      sortable: true,
      filter: true
    }
    // { headerName: 'Latitude', field: 'latitude',editable: editRowId !== null || newRow !== null, sortable: true, filter: true },
    // { headerName: 'Longitude', field: 'longitude', editable: editRowId !== null || newRow !== null, sortable: true, filter: true }
  ], [editRowId, newRow]);

  const defaultColDef = useMemo(() => ({
    flex: 1,
    minWidth: 150,
    editable: true,
    sortable: true,
    filter: true,
    resizable: true,
  }), []);

  const context = {
    editRowId,
    newRow,
    onEditClick,
    onCancelClick,
    onUpdateClick,
    onDeleteClick,
    openUploadModal,
    saveNewRow,
  };

  // const gridOptions = useMemo(() => ({
  //   domLayout: 'autoHeight',
  //   enableBrowserTooltips: true,
  //   menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab', 'csvExport'],
  // }), []);

  // const sideBar = useMemo(() => ({
  //   toolPanels: [
  //     {
  //       id: 'columns',
  //       labelDefault: 'Columns',
  //       labelKey: 'columns',
  //       iconKey: 'columns',
  //       toolPanel: 'agColumnsToolPanel',
  //       toolPanelParams: {
  //         suppressRowGroups: true,
  //         suppressValues: true,
  //         suppressPivots: true,
  //         suppressPivotMode: true,
  //       }
  //     },
  //     // {
  //     //   id: 'filters',
  //     //   labelDefault: 'Filters',
  //     //   labelKey: 'filters',
  //     //   iconKey: 'filter',
  //     //   toolPanel: 'agFiltersToolPanel',
  //     // }
  //   ],
  // }), []);

  const getRowId = useCallback((params) => `${params.data.id}`, []);

  return (
    <>
      <Container maxW="100%" p={4}>
        <Button bg="white" onClick={addNewRow} mb={4}>
          Add New Community
        </Button>
        <div className="ag-theme-alpine" style={{ height: 'auto' }}>
          <AgGridReact
            ref={gridRef1}
            rowData={rowData}
            columnDefs={colDefs}
            defaultColDef={defaultColDef}
            animateRows={true}
            domLayout="autoHeight"
            enableSorting={true}
            enableFilter={true}
            enableCellEditing={true}
            rowSelection="single"
            pagination={true}
            rowModelType={"serverSide"}
            paginationPageSize={100}
            onGridReady={onGridReadyCommunity}
            serverSideDatasource={gridOption}
            frameworkComponents={{
              ActionCellRenderer,
              CommunityUploadButtonCellRenderer,
            }}
            context={context}
            onCellValueChanged={handleCellValueChanged}
            getRowId={getRowId}
          />
        </div>
      </Container>
      <CommunityUploadModal isOpen={isOpen} onClose={onClose} propertyId={propertyId} />
    </>
  );
};

export default CommunityListing;
