import React from "react";
import { Flex, Select, Tabs, TabList, Tab, Input, IconButton, Box , Icon, InputLeftElement, InputGroup} from "@chakra-ui/react";
import { useState } from 'react';
import ElasticSearchComponent from './ElasticSearchComponent';
import ListSearchComponent from './ListSearchComponent';
const SearchHeader = () => {

const [showMap, setShowMap] = useState(true);
const [selectedTab, setSelectedTab] = useState(0);

const handleTabChange = (index) => {
  setSelectedTab(index);
  setShowMap(index === 0); 
};

  return (
    <>
    <Flex p="1" alignItems="center" justifyContent="space-between">   
      <Box flex={{ base: "none", md: 3}}>
      <Tabs align="end" variant="enclosed" index={selectedTab} onChange={handleTabChange} color={'#000'}>
        <TabList>
          <Tab _selected={{ color: "#000", bg: "#fafbfc" }}>Map</Tab>
          <Tab _selected={{ color: "#000", bg: "#fafbfc" }}>List</Tab>
        </TabList>
      </Tabs>
      </Box>
    </Flex>
    <ElasticSearchComponent mapView={showMap} />
    </>
  );
};

export default SearchHeader;
