import { useNavigate, useLocation } from 'react-router-dom';
import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'; 

import {
  Flex,
  Stack,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  useColorModeValue,
} from '@chakra-ui/react';

export default function ResetPasswordForm() {
  const navigate = useNavigate();
  const location = useLocation();

  // Extract userId and token from URL query parameters
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('userId');
  const token = queryParams.get('token');
  const key = queryParams.get('key');
  const state = queryParams.get('state');

  // State for form inputs
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const handleResetPassword = async () => {
    try {

      if (!newPassword) {
        setError('Please enter a new password');
        return;
      }
  
      // Check if confirmPassword is empty
      if (!confirmPassword) {
        setError('Please confirm your password');
        return;
      }

      // Validate new password and reconfirmed password
      if (newPassword !== confirmPassword) {
        setError('Passwords do not match');
        return;
      }

      // Make API call to reset password
      const response = await axios.post(`${process.env.REACT_APP_DYNAMO_API_URL}/users/reset-password`, {
        user_id: userId,
        reset_token: token,
        new_password: newPassword,
        key : key,
        state : state
      });

      Swal.fire({
        icon: 'success',
        title: 'Success',
        text:  'Password reset successful. Please sign in with your new password.',
      });
      navigate('/login'); // Redirect to sign-in page
    } catch (error) {
      console.error('Error resetting password:', error);
      let errorMessage = 'Failed to reset password. Please try again later.';
    if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
    }
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: errorMessage,
      });
    }
  };

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}>
      <Stack
        spacing={4}
        w={'full'}
        maxW={'md'}
        bg={useColorModeValue('white', 'gray.700')}
        rounded={'xl'}
        boxShadow={'lg'}
        p={6}
        my={12}>
        <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
          Enter new password
        </Heading>
       
        <FormControl id="password" isRequired>
          <FormLabel>New Password</FormLabel>
          <Input type="password"  value={newPassword}
             onChange={(e) => setNewPassword(e.target.value)} />
        </FormControl>

        <FormControl id="cpassword" isRequired>
          <FormLabel>Confirm Password</FormLabel>
          <Input type="password" value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}/>
        </FormControl>
        {error && <div>{error}</div>}
        <Stack spacing={6}>
          <Button
             onClick={handleResetPassword}
            bg={'blue.400'}
            color={'white'}
            _hover={{
              bg: 'blue.500',
            }}>
            Submit
          </Button>
        </Stack>
      </Stack>
    </Flex>
  )
}