
export const formatPrice = (price) => {
    if (price === undefined || price === null || price < 0) {
      return 'Model';
    }
    if (price >= 1000000) {
      const millionPrice = (price / 1000000).toFixed(0);
      return millionPrice.endsWith('.0') ? `${Math.round(price / 1000000)}M` : `${millionPrice}M`;  // Remove .0 for whole numbers
    } else if (price >= 1000) {
      const thousandPrice = (price / 1000).toFixed(0);
      return thousandPrice.endsWith('.0') ? `${Math.round(price / 1000)}K` : `${thousandPrice}K`;  // Remove .0 for whole numbers
    }
    return `${price}`;
  };

  export const formatAddress= (address) => {
    if (address === undefined || address === null) {
      return ' . ';
    }
    if(address.includes("null") || address.includes("null,")){
      address = address.replaceAll("null,", "").replaceAll("null", "").replaceAll(/(,,)+/g, ", ");
    }else{
      address = address.replaceAll(/(,,)+/g, ", ");
    }
    address = address.replaceAll(/(,)+/g, ", ");
    return address;
  };