import React, { useState, useEffect } from 'react';
import { Input, InputGroup, InputRightElement, Button, Box, List, ListItem } from '@chakra-ui/react';
import { FiSearch } from 'react-icons/fi';
import axiosInstance, {axiosInstanceES} from '../../utils/axio-instance';


const Autocomplete = ({ onAutocompleteData }) => {
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false); 
  const fetchNearbyLocations = async (query) => {
    try {
      const response = await axiosInstanceES.get(`/nearbylocations/${query.latitude}/${query.longitude}`);
      onAutocompleteData(response.data);
    } catch (error) {
      console.error('Error fetching nearby locations:', error);
    }
  };
  useEffect(() => {
    if (query !== '') {
      fetchNearbyLocations(query);
    }
  }, [query]);

  const handleChange = async (event) => {
    const userInput = event.target.value;
    setQuery(userInput);
    setShowSuggestions(true); // Show suggestions when typing
    try {
      const response = await axiosInstanceES.get(`/addressautocomplete?input=${userInput}`);
      setSuggestions(response.data);
    } catch (error) {
      console.error('Error fetching autocomplete suggestions:', error);
    }
  };

  const handleSelectSuggestion = (selectedSuggestion) => {
    console.log("selected suggestion" ,selectedSuggestion);
    const selectedLabel = selectedSuggestion.addressLabel;
  setQuery(selectedLabel);
    setQuery(selectedSuggestion);
    setShowSuggestions(false); // Close suggestions after selection
    // Perform search or update product list based on selected suggestion
  };

  const clearSearch = () => {
    setQuery('');
    setShowSuggestions(false); // Close suggestions when clearing search
  };

  return (
    <Box w="100%">
      <InputGroup>
        <Input
          placeholder="Enter address..."
          value={query?query.addressLabel:''}
          onChange={handleChange}
          variant="filled"
          bg="white"
          _hover={{ bg: 'white' }}
          _focus={{ bg: 'white' }}
          borderRadius="md"
          boxShadow="md"
          borderColor="gray.300"
          pr="4.5rem"
          w="100%"
        />
        <InputRightElement>
          {query && (
            <Button
              onClick={clearSearch}
              colorScheme="blue"
              variant="ghost"
              size="sm"
              borderRadius="md"
              mr="-1px"
              borderTopLeftRadius="0"
              borderBottomLeftRadius="0"
            >
              Clear
            </Button>
          )}
          <FiSearch />
        </InputRightElement>
      </InputGroup>
      {showSuggestions && suggestions.length > 0 && ( // Show suggestions only if there are suggestions and showSuggestions is true
        <List bg="white" borderRadius="md" boxShadow="md" borderColor="gray.300" w="100%">
          {suggestions.map((suggestion, index) => (
            <ListItem
              key={index}
              onClick={() => handleSelectSuggestion(suggestion)}
              _hover={{ bg: 'gray.100' }}
              cursor="pointer"
              padding="2"
              borderBottomWidth="1px"
              borderColor="gray.200"
            >
              {suggestion.addressLabel}
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};

export default Autocomplete;
