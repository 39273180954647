import React from 'react';
import { Link } from 'react-router-dom';
import { HomeLinks as Links } from "../../constants";

const HomeLinks = ({onClose}) => {

  const filteredLinks = Links.filter(item => {
    if (item.label === 'New Home Buyers') {
      return process.env.REACT_APP_NEW_HOME_BUYER === 'true';
    }
    return true;
  });

  const handleLinkClick = (index) => {
    if (onClose) {
      onClose(); 
    }
  };

  return (
    <>
      {filteredLinks.map((item, index) => (
        <Link key={index} to={item.path}  onClick={() => handleLinkClick(index)}>
          <h4 className="text-center hover:bg-primary transition-all duration-300 py-3">
            {item.label}
          </h4>
        </Link>
      ))}
    </>
  );
};

export default HomeLinks;
