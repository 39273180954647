import React, { useState, useEffect } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, SimpleGrid, Image, Text } from '@chakra-ui/react';
import ImageScroll from '../ImageScroll';
import axiosInstance, {axiosInstanceES} from '../../utils/axio-instance';


function PhotoModal({ isOpen, onClose, propertyId }) {
  const [images, setImages] = useState([]);
  const [modelSize, setmodelSize] = useState("full");
  const [loadingTxt, setloadingTxt] = useState("loading images...")
  useEffect(() => {
    if (isOpen) {
      const fetchImages = async () => {
        try {
          const response = await axiosInstanceES.get(`/properties/${propertyId}/photos`);
          setImages(response.data.map(item => ({ image: item.url }))); 
          if(response.data.length === 0){
              setloadingTxt("No images found")
              setmodelSize('sm');
          }
              
        } catch (error) {
          console.error('Error fetching images:', error);
        }
      };

      fetchImages();
    }
  }, [isOpen, propertyId]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={modelSize}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Photos</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
        {images.length === 0 ? (
            <Text>{loadingTxt}</Text>
          ) : (
            <ImageScroll images={images} width="100%"  mt="10px" />
          )}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default PhotoModal;
