import { InstantSearch, SearchBox, Hits, Highlight, createConnector, DynamicWidgets, RefinementList, ToggleRefinement, Panel, Pagination, Stats, connectSearchBox, NumericMenu, RangeInput, CurrentRefinements, QueryRuleCustomData, Snippet, SortBy, Configure, HierarchicalMenu } from 'react-instantsearch-dom';
import {Card, CardHeader, CardBody, CardFooter, Box, useColorModeValue, Text, ListItem, List, SimpleGrid, Spacer, Icon, Flex, Divider, Container, CloseButton} from "@chakra-ui/react";
import Client from '@searchkit/instantsearch-client';
import Searchkit from "searchkit";

import {
  GoogleMapsLoader,
  GeoSearch,
  Marker,
} from 'react-instantsearch-dom-maps';


const searchkitClient = new Searchkit({
  connection: {
    host: 'https://commerce-demo.es.us-east4.gcp.elastic-cloud.com:9243',
    apiKey: 'a2Rha1VJTUJMcGU4ajA3Tm9fZ0Y6MjAzX2pLbURTXy1hNm9SUGZGRlhJdw==',
   
  },
  search_settings: {
    highlight_attributes: ['name'],
    search_attributes: ['name'],
    result_attributes: ['name'],
    facet_attributes: [
      { field: 'price', attribute: 'price', type: 'numeric', nestedPath: 'availabilities' },
      { field: 'region.keyword', attribute: 'region', type: 'string'}
    ],
    filter_attributes: [
      { field: 'start', attribute: 'availabilities.start', type: 'date', nestedPath: 'availabilities' },
      { field: 'end', attribute: 'availabilities.end', type: 'date', nestedPath: 'availabilities' }
    ],
    geo_attribute: 'location',
  }
})

const defaultAvailabilityDates = ['2023-06-30', '2024-03-01']
const demo = createConnector({
  displayName: 'AvailabilityDates',
  getProvidedProps: (props, searchState) => {
    return {
      availabilityDates: searchState.availabilityDates || defaultAvailabilityDates
    }
  },
  refine: (props, searchState, nextValue) => {
    return {
      ...searchState,
      availabilityDates: nextValue
    }
  },
  getSearchParameters(searchParameters, props, searchState) {
    const { availabilityDates = defaultAvailabilityDates } = searchState;    
    return searchParameters.addNumericRefinement('availabilities.start', '<=', (new Date(availabilityDates[0])).getTime()).addNumericRefinement('availabilities.end', '>=', (new Date(availabilityDates[1])).getTime());
  },
})

const AvailabilityDates = demo(({ availabilityDates, refine }) => {

  return (
    <div>
      <input type="date"
       value={availabilityDates[0]} onChange={(e) => {
          refine([e.target.value, availabilityDates[1]])
        }}
       ></input>
       <input type="date"
       value={availabilityDates[1]}
       onChange={(e) => {
          refine([availabilityDates[0], e.target.value])
        }}
       ></input>
    </div>
  )
})

const searchClient = Client(searchkitClient);

const hitView = (props) => {
  const availabilities = props.hit.inner_hits?.availabilities || { hits: { hits: [] }}
  return (
    <div>
      <h2><Highlight hit={props.hit} attribute="name" /></h2>
      <br />
      {availabilities.hits.hits.map((availability) => {
        return (
          <div className="mb-3">
            <p>dates: {availability._source.start} - {availability._source.end}</p>
            <p>price: {availability._source.price}</p>
          </div>
        )
      })
      }
    </div>
  )
}

const apiKey = '';//'AIzaSyAPSJE7wwwvLb4BkLPx3d5xPMof1wo4xrM';
const endpoint = '';//'https://maps.googleapis.com/maps/api/js?v=weekly';

export default function Web() {
    return (
      <div className="ais-InstantSearch bg-gray-100 h-screen p-4">
        <Box>
            <title>Camping sites Demo</title>
        </Box>
  
      <InstantSearch
        indexName="search-camping-sites"
        searchClient={searchClient}
      >
        
    <Flex className="search-3block-sec" flexDirection={{ base: "column", md: "row" }}>
    <Box className='filter-sec' flex={{ base: "none", md: 2 }} p={2}>
        <div className="left-panel">
          <Text
              fontSize={'16px'}
              color={useColorModeValue('black.500', 'black.300')}
              fontWeight={'600'}
              mb={'1'}>
            Region
            </Text>
          <SearchBox  className="filter-search" />
          <Divider mt={'4'} borderColor={'#d9e2e8'}/>
          {/* <HierarchicalMenu attributes={['categories_lvl1', 'categories_lvl2']} /> */}
          <Text
              fontSize={'16px'}
              color={useColorModeValue('black.500', 'black.300')}
              fontWeight={'600'}
              mb={'1'} mt={'5'}>
            Region
            </Text>
          <RefinementList className="filter-search search-input" attribute="region" searchable={true} />
          <Divider mt={'4'} borderColor={'#d9e2e8'}/>
          <Text
              fontSize={'16px'}
              color={useColorModeValue('black.500', 'black.300')}
              fontWeight={'600'}
              mb={'1'} mt={'5'}>
            Price
            </Text>
          <RangeInput attribute="price" header="Range Input" />
          <Divider mt={'4'} borderColor={'#d9e2e8'}/>
          <Text
              fontSize={'16px'}
              color={useColorModeValue('black.500', 'black.300')}
              fontWeight={'600'}
              mb={'1'} mt={'5'}>
            Dates
            </Text>
          <div className="datepicker-sec">
          <AvailabilityDates />
          <Divider mt={'4'} borderColor={'#d9e2e8'}/>
          </div>
        </div>
    </Box>
    <Box className='search-list-sec' pr={'5'} pl={'5'} flex={{ base: "none", md: 5 }}>
    
      <Text
      fontSize={'16px'}
      color={useColorModeValue('black.500', 'black.300')}
      fontWeight={'600'}
      mb={'1'} mt={'2'}>
      Properties
      </Text>
      <Divider mt={'2'} borderColor={'#d9e2e8'}/>
          
       <Hits hitComponent={hitView}/>
       <Pagination />
    </Box>
    <Box flex={{ base: "none", md: 3 }} style={{ position: 'relative', overflow: 'hidden' }}>
    <Text
      fontSize={'16px'}
      color={useColorModeValue('black.500', 'black.300')}
      fontWeight={'600'}
      mb={'1'} mt={'2'}>
      Map
      </Text>
      <Divider mt={'2'} borderColor={'#d9e2e8'}/>
    <div className="flex-auto w-full py-2 px-4">
            <Stats />
            <CurrentRefinements />
          </div>
          <div style={{ height: 500, position: 'relative', overflow: 'hidden', borderRadius:'8px', }}>
          <GoogleMapsLoader apiKey={apiKey} endpoint={endpoint} >
          {(google) => (
            <GeoSearch google={google} zoom={10} fullscreenControl={true} streetViewControl={true} zoomControl={true} mapTypeControl={true} mapTypeControlOptions={{ mapTypeIds: ['roadmap', 'satellite'] }}>
              {({ hits }) => (
                <>
                  {hits.map((hit) => (
                    <Marker key={hit.objectID} hit={hit} />
                  ))}
                </>
              )}
            </GeoSearch>
          )}
        </GoogleMapsLoader>
        </div>
    </Box>
    </Flex>

      </InstantSearch>
      </div>
    );
}