import React, { useState } from 'react';
import { connectAutoComplete } from 'react-instantsearch-dom';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { Box, IconButton } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';

const GPlacesAutoComplete = ({refine }) => {
  const [address, setAddress] = useState(null);
  const [addressSelected, setAddressSelected] = useState(false);
  const apiKey = process.env.REACT_APP_GOOGLE_MAP_KEY;

  // Handler to clear the selected address
  const handleClear = () => {
    setAddress(null);
    setAddressSelected(false);
    refine(''); // Clear the refinement
  };
  const mapsCallback = (e)=>{
    console.log(e);
  }
  return (
    <Box position="relative">
      <GooglePlacesAutocomplete
        apiKey={apiKey} callback={mapsCallback}
        selectProps={{
          value: address,
          onChange: (selectedAddress) => {
            setAddress(selectedAddress);
            setAddressSelected(true); // Mark address as selected
            refine(selectedAddress.label); // Refine the Algolia search with the selected address
          },
          placeholder: "Search for places...",
          onBlur: () => {
            if (address === null) {
              refine(''); // Clear refinement if no address is selected
            }
          },
          noOptionsMessage: () => 'Enter Location for Search', // Customize no results message
        }}
        autocompletionRequest={{
          types: ['geocode', 'establishment'], // 'geocode' for locations and 'establishment' for businesses like schools
          componentRestrictions: { country: 'us' } // Optional: restrict results to a specific country
        }}
      />

      {address && (
        <IconButton
          aria-label="Clear"
          icon={<CloseIcon />}
          onClick={handleClear}
          position="absolute"
          right="10px"
          top="10px"
          size="sm"
          variant="outline"
        />
      )}
    </Box>
  );
};

export default connectAutoComplete(GPlacesAutoComplete);
