import React, { useState, useEffect } from 'react';
import { Grid, GridItem, Input, Button, Flex, Select, useToast, Badge, FormLabel, FormControl } from '@chakra-ui/react'; // Import Chakra UI components

import axiosInstance from '../../utils/axio-instance.js';
import {states} from '../../data/us_states.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next'; 

const AgentLicenseInfo = ({profileInfo}) => { 
  const userId = localStorage.getItem('userid');
  const [licenseInfo, setLicenseInfo] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errors, setErrors] = useState({});
  const toast = useToast();
  const { t} = useTranslation();


  const localtion = useLocation();

  // useEffect to set the state when profileInfo changes
  useEffect(() => {
    if(localtion.pathname.indexOf("agent-licence-info")>0){
      getLicenseInfo();
    }
  }, [localtion.pathname]);

  const isValidObject = ()=>{
      let error = {}, ecount = 0;
      if(!licenseInfo.brokerEmail ||  !validateEmail(licenseInfo.brokerEmail)){
        error.brokerEmail = 'Broker Email is required';
        ecount++;
      }
      if(!licenseInfo.brokerName || (licenseInfo.brokerName) === ''){
        error.brokerName = "Broker Name is required"; ecount++;
      }
      if(!licenseInfo.brokerPhone ||  (licenseInfo.brokerPhone) ===''){
        error.brokerPhone = "Broker Phone is required"; ecount++;
      }
      if(!licenseInfo.brokerAddress ||  (licenseInfo.brokerAddress) === ''){
        error.brokerAddress = "Broker Address is required"; ecount++;
      }

      if(!licenseInfo.state){
        error.State = "State is mandatory"; ecount++;
      }
      if(!licenseInfo.licenseNo){
        error.licenseNo = "License No. is mandatory"; ecount++;
      }
      if(!licenseInfo.narNumber){
        error.narNumber = "Realtor NAR Number  is mandatory"; ecount++;
      }
      if(!licenseInfo.address){
        error.address = ("Mailing Address is mandatory"); ecount++;
      }
      if(ecount>0){
        setErrors(error);
        toast({
          title: 'Error',
          description: "Invalid values, Fill all mandatory fields and try again!",
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
        throw new Error("invalid values");
      }
  }
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const getLicenseInfo = ()=>{
    // Send updated profile information to the server using Axios
    axiosInstance.get(`/users/${userId}/agent-license/`)
    .then(response => {
      setLicenseInfo({...response.data});
      })
    .catch(error => {
      console.error('Error in loading getLicenseInfo:', error);
    });
  }
  
  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMessage("");
    setErrors({});
     try{
      isValidObject();
   
    // Send updated profile information to the server using Axios
    axiosInstance.put(`/users/${userId}/agent-license/`, licenseInfo)
    .then(response => {
      setSuccessMessage(t('saved'));
        toast({
          title: t('licenceinfo-updatemessage'),
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'bottom-left',
        });
      })
    .catch(error => {
      console.error('Error updating profile:', error);
      let errorMessage = t('licenceinfo-failedmessage');
      if (error.response && error.response.data && error.response.data.error) {
          errorMessage = error.response.data.error;
      }
      setErrorMessage(t('failed'));
      toast({
        title: 'Error',
        description: errorMessage,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'bottom-left',
      });
    });
    }catch(e){
      console.log(e);
    }
  };
  const handleKeyEvent = (e)=>{
    //console.log(e.target.value, e.target.name);
    setLicenseInfo({...licenseInfo, [e.target.name]: e.target.value});
    e.preventDefault();
  }
  const setState= (e)=>{
    setLicenseInfo({...licenseInfo, state: e.target.value});
  }

  return (
    <div className="bg-primary  font-linksans">
            <div className="container px-5 py-12">
    <GridItem className="top-sec" w='100%' style={{ padding: '0 0 40px 0' }}>
     <form onSubmit={handleSubmit}>
      <Grid templateColumns='repeat(2, 1fr)' gap={10}>
        <GridItem w='100%'>
                <FormControl isInvalid={errors.state} mb={4}>
                  <FormLabel>Licensed State <span style={{ color: 'red' }}>*</span></FormLabel>
                  <Select name="state" value={licenseInfo.state} onChange={setState}>
                      <option value=""> Select State</option>
                      {states.map(st=>(<option key={st.abbreviation} value={st.abbreviation}>{st.name}</option>))
                      }
                    </Select>
                </FormControl>
                <FormControl isInvalid={errors.licenseNo} mb={4}>
                <FormLabel>State License Number<span style={{ color: 'red' }}>*</span></FormLabel>
                <Input bg="white" color="gray.500" fontSize="sm" px={3}  py={3} borderRadius="lg" name="licenseNo" type="number" value={licenseInfo.licenseNo} onChange={handleKeyEvent} placeholder='License Number' />
                </FormControl>
                <FormControl isInvalid={errors.narNumber} mb={4}>
                <FormLabel>Realtor NAR Number<span style={{ color: 'red' }}>*</span></FormLabel>
                <Input bg="white" color="gray.500" fontSize="sm" px={3}  py={3} borderRadius="lg" name="narNumber" type="number" value={licenseInfo.narNumber} placeholder="Realtor NAR Number" onChange={handleKeyEvent} />     
                </FormControl>
                <FormControl isInvalid={errors.address} mb={4}>
                <FormLabel>Mailing Address<span style={{ color: 'red' }}>*</span></FormLabel>
                <Input bg="white" color="gray.500" fontSize="sm" px={3}  py={3} borderRadius="lg" name="address" type="text" value={licenseInfo.address} placeholder="Mailing Address" onChange={handleKeyEvent} />
                </FormControl>
        </GridItem>
        <GridItem w='100%'>
        <FormControl isInvalid={errors.brokerName} mb={4}>
            <FormLabel>Broker Name<span style={{ color: 'red' }}>*</span></FormLabel>
                <Input bg="white" color="gray.500" fontSize="sm" px={3}  py={3} borderRadius="lg" name="brokerName" type="text" value={licenseInfo.brokerName} onChange={handleKeyEvent}  placeholder='Broker Name'/>
                </FormControl>
                <FormControl isInvalid={errors.brokerEmail} mb={4}>
                  <FormLabel>Broker Email<span style={{ color: 'red' }}>*</span></FormLabel>
                <Input bg="white" color="gray.500" fontSize="sm" px={3}  py={3} borderRadius="lg" name="brokerEmail" type="email" value={licenseInfo.brokerEmail} placeholder="Broker Email" onChange={handleKeyEvent} />
                </FormControl>
                <FormControl isInvalid={errors.brokerPhone} mb={4}>
                  <FormLabel>Broker Phone<span style={{ color: 'red' }}>*</span></FormLabel>
                <Input bg="white" color="gray.500" fontSize="sm" px={3}  py={3} borderRadius="lg" name="brokerPhone" type="tel" value={licenseInfo.brokerPhone} placeholder="Broker Phone" onChange={handleKeyEvent} />
                </FormControl>
                <FormControl isInvalid={errors.brokerAddress} mb={4}>
                  <FormLabel>Broker Office address<span style={{ color: 'red' }}>*</span></FormLabel>
                  <Input bg="white" color="gray.500" fontSize="sm" px={3}  py={3} borderRadius="lg" name="brokerAddress" type="tel" value={licenseInfo.brokerAddress} placeholder="Broker Office address" onChange={handleKeyEvent} />
                </FormControl>
        </GridItem>
       
        <Flex display="flex" justifyContent="center" >
          
          <Button type="submit" bg="white" color="gray.500" fontSize="sm" px={3}  py={3} borderRadius="lg" onClick={handleSubmit}>Update AgentLicenseInfo</Button>
          {successMessage && (
            <Badge colorScheme="green" display="flex" ml={3} pl={5} pr={5} alignItems="center" fontSize="md" textTransform="none">
            <CheckIcon boxSize="12px" color="green.500" mr={1} /> 
            {successMessage.charAt(0).toUpperCase() + successMessage.slice(1).toLowerCase()}
            </Badge>
          )}
          {errorMessage && (

          <Badge colorScheme="red" display="flex" alignItems="center" fontSize="md" textTransform="none">
          <CloseIcon boxSize="12px" color="red.500" mr={1} /> 
          {errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1).toLowerCase()}
          </Badge>

          )}
        </Flex>
      </Grid>
      </form>
    </GridItem>
    </div>
    </div>
  );
};

export default AgentLicenseInfo;
