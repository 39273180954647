import MissionImg from "../../assets/images/mission-1a-356x347.jpg";

function OurMission() {
  return (
    <div className="container 2xl:py-10 xl:py-10 lg:py-10 md:py-10 py-8 mx-auto">
      <div className="grid 2xl:grid-cols-2 lg:grid-cols-2 flex-row-reverse xl:grid-cols-2 md:grid-cols-1 items-center gap-6 ">
        <div>
          <h3 className="text-textColor font-semibold 2xl:text-4xl xl:text-3xl lg:text-2xl md:text-2xl text-2xl 2xl:pb-10 xl:pb-8 lg:pb-6 md:pb-4 pb-4">
          Our Mission! 
          </h3>
          <p className="text-paragraphColor pb-6">
          Our mission is to create unique benefits for the individuals who join a group to buy vetted remote rental properties that have the best incentives and for the individuals who want to buy new construction homes.
          </p>
        </div>
        <div>
          <div className="w-full flex justify-center">
            <img
              className="w-1/2 object-contain"
              src={MissionImg}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurMission;
