

function Whatweoffer() {
    return (
      <div className="container 2xl:py-10 xl:py-10 lg:py-10 md:py-10 py-8">
          <div className="flex flex-col justify-center items-center rounded-lg ">
             <h3 className="2xl:text-4xl xl:text-3xl lg:text-2xl md:text-2xl text-2xl font-semibold text-textColor pb-5">What We Offer </h3>
              <p className="text-paragraphColor pb-2 text-center w-1/2">
              Helping you buy the best remote rental property via our Group model for better incentives & discounts and removing expensive local rental home’s challenges.
              </p>
              <div>
              </div>
          </div>
      </div>
    )
  }
  
  export default Whatweoffer