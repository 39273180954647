import { useEffect, useState } from "react";
import heroImg2560x550 from "../../../assets/images/aboutus-1a-2560x550.jpg";
import heroImg1685x760 from "../../../assets/images/aboutus-1a-1685x760.jpg";
import heroImg800x600 from "../../../assets/images/aboutus-1a-800x600.jpg";

function AboutHero() {

  const [backgroundImage, setBackgroundImage] = useState(heroImg2560x550);

  useEffect(() => {
    const updateBackgroundImage = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth >= 2560) {
        setBackgroundImage(heroImg2560x550);
      } else if (screenWidth >= 1920) {
        setBackgroundImage(heroImg2560x550);
      } else if (screenWidth >= 1685) {
        setBackgroundImage(heroImg1685x760);
      } else if (screenWidth >= 800) {
        setBackgroundImage(heroImg800x600);
      } else {
        setBackgroundImage(heroImg2560x550); // Default image or the closest fit
      }
    };

    // Initial background image setting
    updateBackgroundImage();

    // Update on window resize
    window.addEventListener("resize", updateBackgroundImage);

    // Clean up listener on component unmount
    return () => window.removeEventListener("resize", updateBackgroundImage);
  }, []);

  return (
    <div>
      <div className="w-full xl:h-[480px] lg:h-[400px] 2xl:h-[550px] md:h-[450px] h-[400px] font-linksans">
        <div
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover', // Ensures the image covers the entire area
            backgroundPosition: 'center', // Centers the image
          }}
          className=" w-full h-full flex justify-center items-center"
        >
          <div className="w-[100%] 2xl:w-[50%] xl:w-[55%] lg:w-[60%] md:w-[80%] flex flex-col justify-center items-center">
            <div className="w-full flex justify-center items-center">
              <h1 className="font-semibold justify text-center text-white 2xl:text-6xl xl:text-5xl lg:text-4xl md:text-4xl text-3xl  pb-10">
              Building Unique Benefits for Rental Home Buyers and New Home Buyers
              </h1>
            </div>
            {/* <div className="grid grid-cols-1 w-1/2 gap-6 pt-8 px-12">
              <a href="/sign-up/"
                style={{
                  background: `linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), rgba(255, 255, 255, 0.20)`,
                }}
                className="w-full px-8 py-4 rounded-lg text-sm text-[#FAFBFC] flex items-center justify-center"
              >
                <span >Start Now</span>
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutHero;
