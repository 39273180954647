import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Box,
  Image,
  IconButton,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import AWS from 'aws-sdk';
import imageCompression from 'browser-image-compression';
import { FiImage, FiTrash2 } from 'react-icons/fi';
import { DeleteIcon } from '@chakra-ui/icons'; // Import the delete icon

// Configure AWS with your access and secret key.
AWS.config.update({
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

const s3 = new AWS.S3();

const ImageUploadModal = ({ isOpen, onClose, propertyId }) => {
  const [files, setFiles] = useState([]);
  const [uploadedPhotos, setUploadedPhotos] = useState({ full: [], small: [] }); // Initialize as an object with 'full' and 'small' properties

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles);
    },
  });

  useEffect(() => {
    if (propertyId) {
      fetchPhotos();
    }
  }, [propertyId]);

  const fetchPhotos = async () => {
    const params = {
      Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
      Prefix: `${propertyId}/`,
    };

    try {
      const data = await s3.listObjectsV2(params).promise();
      const photoUrls = { full: [], small: [] };
      
      data.Contents.forEach(item => {
        const photoUrl = s3.getSignedUrl('getObject', {
          Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
          Key: item.Key,
          Expires: 60 * 60, // URL expiration time in seconds
        });
        
        if (item.Key.includes('/full/')) {
          photoUrls.full.push({ url: photoUrl, key: item.Key });
        } else if (item.Key.includes('/small/')) {
          photoUrls.small.push({ url: photoUrl, key: item.Key });
        }
      });
      
      setUploadedPhotos(photoUrls);
    } catch (error) {
      console.error('Error fetching photos:', error);
    }
  };

  const handleUpload = async () => {
    if (files.length > 0) {
      const uploadPromises = files.map(async (file) => {
        const fileExtension = file.name.split('.').pop();
        const fullFileKey = `${propertyId}/full/${Date.now()}.${fileExtension}`;
        const smallFileKey = `${propertyId}/small/${Date.now()}.${fileExtension}`;

        // Upload full-size image
        const fullUploadParams = {
          Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
          Key: fullFileKey,
          Body: file,
          ContentType: file.type
        };

        await s3.upload(fullUploadParams).promise();

        // Compress and upload small-size image
        const options = { maxSizeMB: 0.2, maxWidthOrHeight: 200 };
        const compressedFile = await imageCompression(file, options);
        const smallUploadParams = {
          Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
          Key: smallFileKey,
          Body: compressedFile,
          ContentType: file.type
        };

        await s3.upload(smallUploadParams).promise();

        return {
          fullFileKey: `https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${fullFileKey}`,
          smallFileKey: `https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${smallFileKey}`,
        };
      });

      try {
        const results = await Promise.all(uploadPromises);
        console.log('Upload results:', results);
        setFiles([]);
        fetchPhotos(); 
      } catch (error) {
        console.error('Error uploading photos:', error);
      }
    }
  };

  const handleDelete = async (key) => {
    const params = {
      Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
      Key: key,
    };

    try {
      await s3.deleteObject(params).promise();
      setUploadedPhotos(prevPhotos => ({
        ...prevPhotos, 
        full: prevPhotos.full.filter
        (photo => photo.key !== key), 
        small: prevPhotos.small.filter(photo => photo.key !== key) 
      }));
    } catch (error) {
      console.error('Error deleting photo:', error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upload Property Images</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
           <div {...getRootProps()} style={{ marginTop: '20px', border: '1px dashed #ccc', padding: '20px', textAlign: 'center' }}>
            <input {...getInputProps()} />
            <p>Drag & drop images here, or click to select files</p>
          </div>
          {files.length > 0 && (
            <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <h4>Selected files:</h4>
              <ul style={{ listStyleType: 'none', padding: 0 }}>
                {files.map((file, index) => (
                  <li key={index}>{file.name}</li>
                ))}
              </ul>
            </div>
          )}
          

          {/* <h4>Full Size Images:</h4> */}
          <Grid templateColumns="repeat(4, 1fr)" mt={5} gap={6}>
            {uploadedPhotos.full.map((photo, index) => (
              <GridItem key={index}>
                <Box position="relative">
                  <Image src={photo.url} alt={`Uploaded ${index}`} boxSize="100px" />
                  <IconButton
                    icon={<FiTrash2 />}
                    size="sm"
                    colorScheme="red"
                    position="absolute"
                    top="4px"
                    right="7px"
                    transform="translate(50%, -50%)"
                    onClick={() => handleDelete(photo.key)}
                  />
                </Box>
              </GridItem>
            ))}
          </Grid>

          {/* Display small-size images */}
          {/* <h4>Small Size Images:</h4>
          <Grid templateColumns="repeat(4, 1fr)" mt={3} gap={6}>
            {uploadedPhotos.small.map((photo, index) => (
              <GridItem key={index}>
                <Box position="relative">
                  <Image src={photo.url} alt={`Uploaded ${index}`} boxSize="100px" />
                  <IconButton
                    icon={<FiTrash2 />}
                    size="sm"
                    colorScheme="red"
                    position="absolute"
                    top="4px"
                    right="7px"
                    transform="translate(50%, -50%)"
                    onClick={() => handleDelete(photo.key)}
                  />
                </Box>
              </GridItem>
            ))}
          </Grid> */}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button variant="ghost" onClick={handleUpload} isDisabled={files.length === 0}>
            Upload
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ImageUploadModal;
