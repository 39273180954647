import { GiTeamIdea, GiArrowWings, GiBarracksTent,GiIncomingRocket,GiChart, GiCoins} from "react-icons/gi";
import { BsCurrencyDollar } from "react-icons/bs";

function Analyze() {
  return (
    <div id="why-choose-us" className="container mx-auto2xl:py-10 xl:py-10 lg:py-10 md:py-10 py-8 px-12">
      <div className="flex flex-col justify-center items-center rounded-lg">
        <div>
        <h3 className="text-textColor font-semibold text-center 2xl:text-4xl xl:text-3xl lg:text-2xl md:text-2xl text-2xl pb-12">
          Why join our Group Buyers model?
          </h3>
        </div>
        <div className="grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 grid-cols-1  gap-8">
          {/* item */}
          <div className="w-full flex flex-col">
          <div className="pb-8">
             <GiTeamIdea className="text-gray-700 w-8 h-8" />
            </div>
            <h4 className="text-textColor font-semibold pb-4">
            Group model brings better benefits
            </h4>
            <p className="text-paragraphColor pb-4">
            We present vetted properties with better price and incentives to like minded groups for best passive monthly income.  Based on each group’s interests and purchasing criteria, our vetting process focuses on high growth areas, better pricing, better passive income and easier property management with local property managers who have expertise in local markets.
             </p>
          </div>
          {/* item */}
          <div className="w-full flex flex-col">
            <div className="pb-8">
             <GiArrowWings  className="text-gray-700 w-8 h-8" />
            </div>
            <h4 className="text-textColor font-semibold pb-4">
            Resolve to earn real Passive Income
            </h4>
            <p className="text-paragraphColor pb-4">
            Passive income via rental property can be a powerful tool for financial growth and stability. Passive income allows you to create additional revenue streams beyond your regular salary, offering more financial flexibility. Paving passive income creates a financial buffer.
            </p>
          </div>
          {/* item */}
          <div className="w-full flex flex-col">
          <div className="pb-8">
             <GiBarracksTent className="text-gray-700 w-8 h-8" />
            </div>
            <h4 className="text-textColor font-semibold pb-4">
            Fixed Assets
            </h4>
            <p className="text-paragraphColor pb-4">
            Tangible: Fixed assets like real estate are physical, giving investors a sense of ownership and security.
            <br/><br/>
            Control: You have direct control over how the asset is managed or maintained, which can increase its value.
           </p>
          </div>

          <div className="w-full flex flex-col">
          <div className="pb-8">
             <GiIncomingRocket className="text-gray-700 w-8 h-8" />
            </div>
            <h4 className="text-textColor font-semibold pb-4">
            Diversification
            </h4>
            <p className="text-paragraphColor pb-4">
            Diversification: Fixed assets offer an additional layer of diversification as they don’t move in tandem with other markets. Owning both can spread risk across different asset classes.
            <br/><br/>
            Less Volatility: Fixed assets tend to have lower price volatility compared to assets, which can fluctuate rapidly.
            <br/><br/>
            Portfolio Stability: Fixed assets like real estate add stability to a portfolio, balancing out other riskier assets.
            </p>
          </div>

          <div className="w-full flex flex-col">
          <div className="pb-8">
             <GiChart  className="text-gray-700 w-8 h-8" />
            </div>
            <h4 className="text-textColor font-semibold pb-4">
            Cash Flow Generation
            </h4>
            <p className="text-paragraphColor pb-4">
            Consistent Cash Flow: Real estate (rental property) or leasing out can generate steady and predictable monthly cash flow.
            <br/><br/>
            Inflation Hedge: Real estate, in particular, often acts as a hedge against inflation, as property values and rental income tend to rise with inflation.
           </p>
          </div>

          <div className="w-full flex flex-col">
          <div className="pb-8">
             <BsCurrencyDollar  className="text-gray-700 w-8 h-8" />
            </div>
            <h4 className="text-textColor font-semibold pb-4">
            Wealth Building
            </h4>
            <p className="text-paragraphColor pb-4">
            Passive income sources, like real estate properties, appreciate in value, potentially generating even more income in the long run. You have an opportunity to scale and continuously acquire more properties and increase monthly passive income. 
             </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Analyze;
