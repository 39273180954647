import { LicenseManager } from "aws-sdk";
import axios from "axios";
import { useEffect, useState } from "react";
import howitsworksImg from "../../../assets/images/howitworks-rental-buyers-1b-1024x760.png";


function HomeV3Accordion3() {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [review, setReview] = useState([])
  const [activeIndex, setActiveIndex] = useState();

  useEffect(() => {
    axios
      .get("/home-v3-accordion1.json")
      .then((res) => setData(res.data));
  }, []);

  useEffect(() => {
    axios.get('/review.json')
    .then((res) => setReview(res.data))
  }, [])

  const handleAccordion = (value) => {
    setOpen(!open);
    setActiveIndex(value);
  };

  const reviewData = review[activeIndex || 0]

  
  


  return (
    <div className="container mx-auto 2xl:py-10 xl:py-10 lg:py-10 md:py-10 py-10">
      <div>
        <h3 className="2xl:text-4xl xl:text-3xl lg:text-2xl md:text-2xl text-2xl font-semibold text-center text-textColor pb-14">
        How it works!
        </h3>
        <div>
          <div className="w-full xl:h-[780px] lg:h-[740px] 2xl:h-[760px] md:h-[740px] h-[700px] font-linksans">
            <div
              style={{
                background: `url(${howitsworksImg})`, 
                backgroundRepeat : 'no-repeat',
                backgroundPosition: 'center',
              }}
              className="w-full h-full flex justify-center items-center"
            >
        </div>
      </div>
    </div>
    </div>
    </div>
  );
}

export default HomeV3Accordion3;
